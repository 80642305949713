<script>
import apiFinance from "@/api/finance";
import {useDisplay} from "vuetify";
export default {
	setup () {
		// Destructure only the keys we want to use
		const { xlAndUp, mdAndDown } = useDisplay();
		return { xlAndUp, mdAndDown }
	},

	name: "WeekDebtPage",


	data() {
		return {
			pageTitle: 'Week debt',

			selectedMonth: {
				month: new Date().getMonth(),
				year: new Date().getFullYear()
			},
			items: [],
			itemsLoading: false,
			itemsLoaded: false,
		}
	},


	created() {
		this.getWeekDebt();
	},


	watch: {
		selectedMonth() {
			this.getWeekDebt();
		},
	},


	methods: {
		format() {
			const monthName = new Date(this.selectedMonth.year, this.selectedMonth.month, 1).toLocaleString('en', { month: 'long' });
			return `${monthName} ${this.selectedMonth.year}`
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		setMonthDate(date) {
			const year = new Date(date).getFullYear();
			const month = ("0" + (new Date(date).getMonth() + 1)).slice(-2);
			return `${month}.${year}`
		},
		async getWeekDebt() {
			const params = {
				date_after: this.formatingDate(new Date(this.selectedMonth.year, this.selectedMonth.month, 1), 'yyyy-MM-dd'),
				date_before: this.formatingDate(new Date(this.selectedMonth.year, this.selectedMonth.month + 1, 0), 'yyyy-MM-dd'),
			}
			try {
				this.itemsLoading = true;
				this.itemsLoaded = false;
				this.items = await apiFinance.getWeekDebt(params);
				this.itemsLoading = false;
				this.itemsLoaded = true;
			} catch(error) {
				console.log(error)
			}
		},
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>

			<v-row>
				<v-col cols="auto">
					<VueDatePicker
							class="density-compact clearable-false"
							v-model="selectedMonth"
							auto-apply
							:format="format"
							:clearable="false"
							month-picker
							:enable-time-picker="false"
							:min-date="new Date(2024, 7)"
					/>
				</v-col>
			</v-row>

			<div v-if="itemsLoaded" class="overflow-x-auto overflow-y-hidden px-1 pb-2 mt-6 d-flex justify-start">
				<v-row class="flex-nowrap" no-gutters style="min-height: 500px">
					<v-col
							v-for="(item, index) in items"
							:key="index"
							:style="xlAndUp ? '' : 'min-width: 270px; max-width: 270px;'"
							:class="(index < items.length - 1) ? 'mr-4' : ''"
					>
						<v-card v-if="item.month_data === null" class="rounded-lg fill-height d-flex flex-column">
							<div class="text-subtitle-1 font-weight-medium text-center py-2 flex-0-0">{{ item.number }} week {{ new Date(item.parse_date).toLocaleString('en', { month: 'long' }) }}</div>
							<v-divider></v-divider>
							<div class="flex-1-1 d-flex justify-center align-center">
								<div class="d-flex flex-column align-center">
									<v-icon color="grey-darken-3" size="64">mdi-calendar-clock</v-icon>
									<div class="mt-2 text-h5 text-grey-darken-3">{{ item.parse_date }}</div>
								</div>
							</div>
						</v-card>
						<v-card v-else class="rounded-lg">
							<div class="text-subtitle-1 font-weight-medium text-center py-2">{{ item.number }} week {{ new Date(item.parse_date).toLocaleString('en', { month: 'long' }) }}</div>
							<v-divider></v-divider>
							<div class="px-3 py-2">
								<div class="text-caption px-2 py-2 text-right" style="height: 36px"><span v-if="item.number > 1">First week delta</span></div>
								<template v-for="(row, rowIndex) in item.month_data" :key="rowIndex">
									<v-row no-gutters :class="rowIndex % 2 ? 'bg-grey-lighten-4' : ''">
										<v-col cols="4">
											<div class="text-body-2 text-grey-darken-2 pl-2 pr-4 py-2">{{ setMonthDate(row.month_date) }}</div>
										</v-col>
										<v-col cols="4">
											<div class="text-body-2 text-grey-darken-3 text-center px-0 py-2">{{ row.month_debt_amount }}$</div>
										</v-col>
										<v-col cols="4">
											<div v-if="item.number > 1" class="text-body-2 text-right px-2 py-2" :class="(row.delta < 0) ? 'text-green' : (row.delta === 0) ? 'text-grey-darken-3' : 'text-red-accent-2'">{{ row.delta }}$</div>
										</v-col>
									</v-row>
								</template>
								<v-row no-gutters>
									<v-col cols="4">
										<div class="text-body-2 font-weight-medium pl-2 pr-4 py-2">Total:</div>
									</v-col>
									<v-col cols="4">
										<div class="text-body-2 font-weight-medium px-0 py-2 text-center">{{ item.total_debt }}$</div>
									</v-col>
									<v-col cols="4">
										<div v-if="item.number > 1" class="text-body-2 font-weight-medium px-2 py-2 text-right" :class="(item.total_delta < 0) ? 'text-green' : (item.total_delta === 0) ? 'text-grey-darken-3' : 'text-red-accent-2'">{{ item.total_delta }}$</div>
									</v-col>
								</v-row>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</div>

			<template v-else>
				<v-row>
					<v-col v-for="(item, index) in 4" :key="index">
						<v-card class="rounded-lg">
							<div><v-skeleton-loader class="bg-transparent" type="heading"></v-skeleton-loader></div>
							<div v-for="(item, index) in 12" :key="index" class="d-flex">
								<div class="flex-1-1"><v-skeleton-loader class="bg-transparent" type="text"></v-skeleton-loader></div>
								<div class="flex-1-1"><v-skeleton-loader class="bg-transparent" type="text"></v-skeleton-loader></div>
								<div class="flex-1-1"><v-skeleton-loader class="bg-transparent" type="text"></v-skeleton-loader></div>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</template>

		</div>
	</div>
</template>

<style scoped lang="less">
.table-column {

	& > div {

		&:nth-child(2n + 2) {
			background: #f5f5f5;
		}
	}
}
</style>