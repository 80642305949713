<script>
import {useDisplay} from "vuetify";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
)

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "ForecastCard",


	components: {
		Line
	},


	props: {
		info: {}
	},


	data() {
		return {
			chartData: {
				labels: this.info.fans_forecast_of_months.map(b => b.month).slice(0, 7),
				datasets: [
					{
						data: this.info.fans_forecast_of_months.map(b => b.amount).slice(0, 7),
						backgroundColor: '#1976D2',
						borderColor: '#1976D2',
						pointStyle: 'circle',
						pointRadius: 3,
						pointHoverRadius: 8
					}
				]
			},
			chartOptions: {
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						padding: 12,
						titleMarginBottom: 12,
						caretSize: 8,
						usePointStyle: true,
						callbacks: {
							// label: (context) => {
							// 	return context.raw + '$'
							// },
							// labelPointStyle: () => {
							// 	return {
							// 		pointStyle: 'star',
							// 		rotation: 0
							// 	}
							// }
						},
						enabled: false,
						position: 'nearest',
						external: (context) => {
							// Tooltip Element
							const {chart, tooltip} = context;
							let tooltipEl = chart.canvas.parentNode.querySelector('div');

							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
								tooltipEl.style.borderRadius = '8px';
								tooltipEl.style.color = 'white';
								tooltipEl.style.opacity = 1;
								tooltipEl.style.pointerEvents = 'none';
								tooltipEl.style.position = 'absolute';
								tooltipEl.style.transform = 'translate(-17px, 24px)';
								tooltipEl.style.transition = 'all .1s ease';

								const triangle = document.createElement('div');
								triangle.style.position = 'absolute';
								triangle.style.bottom = '100%';
								triangle.style.left = '10px';
								triangle.style.border = '7px solid transparent';
								triangle.style.borderTop = '0';
								triangle.style.borderBottom = '10px solid rgba(0, 0, 0, 0.7)';
								// triangle.style.width = '10px';
								// triangle.style.height = '10px';

								const table = document.createElement('table');
								table.style.margin = '0px';

								tooltipEl.appendChild(triangle);
								tooltipEl.appendChild(table);
								chart.canvas.parentNode.appendChild(tooltipEl);
							}

							// Hide if no tooltip
							if (tooltip.opacity === 0) {
								tooltipEl.style.opacity = 0;
								return;
							}

							// Set Text
							if (tooltip.body) {
								const titleLines = tooltip.title || [];
								const bodyLines = tooltip.body.map(b => b.lines);

								const tableHead = document.createElement('thead');

								titleLines.forEach(title => {
									const tr = document.createElement('tr');
									tr.style.borderWidth = 0;

									const th = document.createElement('th');
									th.style.borderWidth = 0;
									th.style.paddingBottom = '12px';
									const text = document.createTextNode(title);

									th.appendChild(text);
									tr.appendChild(th);
									tableHead.appendChild(tr);
								});

								const tableBody = document.createElement('tbody');
								bodyLines.forEach((body, i) => {
									const colors = tooltip.labelColors[i];

									//create square
									const span = document.createElement('span');
									span.style.background = colors.backgroundColor;
									span.style.borderColor = colors.borderColor;
									span.style.borderWidth = '2px';
									span.style.marginRight = '10px';
									span.style.height = '10px';
									span.style.width = '10px';
									span.style.display = 'inline-block';

									const tr = document.createElement('tr');
									tr.style.backgroundColor = 'inherit';
									tr.style.borderWidth = 0;

									const td = document.createElement('td');
									td.style.borderWidth = 0;
									td.style.fontSize = '18px';

									const text = document.createTextNode(body + ' fans');

									// td.appendChild(span);
									td.appendChild(text);
									tr.appendChild(td);
									tableBody.appendChild(tr);
								});

								const tableRoot = tooltipEl.querySelector('table');

								// Remove old children
								while (tableRoot.firstChild) {
									tableRoot.firstChild.remove();
								}

								// Add new children
								tableRoot.appendChild(tableHead);
								tableRoot.appendChild(tableBody);
							}

							const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

							// Display, position, and set styles for font
							tooltipEl.style.opacity = 1;
							tooltipEl.style.left = positionX + tooltip.caretX + 'px';
							tooltipEl.style.top = positionY + tooltip.caretY + 'px';
							tooltipEl.style.font = tooltip.options.bodyFont.string;
							tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
						}
					},
				},
				scales: {
					x: {
						grid: { color: '#E0E0E0' },
						border: { color: '#E0E0E0' },
						ticks: { color: '#BDBDBD' }
					},
					y: {
						grid: { color: '#E0E0E0' },
						border: { color: '#E0E0E0' },
						ticks: { color: '#BDBDBD' }
					},
				},
				responsive: true,
				maintainAspectRatio: false,
			}
		}
	}
}
</script>

<template>
	<v-sheet
			class="px-5 py-4 rounded-lg elevation-1"
	>
		<v-row justify="space-between">
			<v-col :cols="mdAndDown ? '12' : '6'" style="max-width: 540px">
				<div class="mb-6">
					<div class="text-h6 text-grey-darken-3">{{ info.model }}</div>
					<div class="text-subtitle-2 text-grey-darken-1">{{ info.count_active_promo }} active promo</div>
				</div>
				<v-row>
					<v-col class="pr-8">
						<v-row justify="space-between" no-gutters class="mb-2">
							<div class="text-body-2 text-grey-darken-1">Future purchases</div>
							<div class="text-body-2 text-right">{{ info.future_purchase.toLocaleString('ru') }}$</div>
						</v-row>
						<v-row justify="space-between" no-gutters class="mb-2">
							<div class="text-body-2 text-grey-darken-1">Currency</div>
							<div class="text-body-2 text-right">{{ info.currency }}%</div>
						</v-row>
						<v-row justify="space-between" no-gutters class="mb-2">
							<div class="text-body-2 text-grey-darken-1">Debt, fans</div>
							<div class="text-body-2 text-right">{{ info.debt_fans.toLocaleString('ru') }}</div>
						</v-row>
						<v-row justify="space-between" no-gutters class="mb-2">
							<div class="text-body-2 text-grey-darken-1">Debt, $</div>
							<div class="text-body-2 text-right">{{ info.debt.toLocaleString('ru') }}$</div>
						</v-row>
						<v-row justify="space-between" no-gutters>
							<div class="text-body-2 text-grey-darken-1 font-weight-bold">Total, $</div>
							<div class="text-body-2 font-weight-bold text-right">{{ info.total.toLocaleString('ru') }}$</div>
						</v-row>
						<v-row>
							<v-col class="pr-4">
								<v-card class="px-4 py-2">
									<div class="text-caption text-grey-darken-1 mb-1">Fans flow per day</div>
									<div class="d-flex align-center">
										<v-icon color="secondary">mdi-account-multiple-plus</v-icon>
										<span class="text-caption pl-6">{{ info.fans_per_day.toLocaleString('ru') }}</span>
									</div>
								</v-card>
							</v-col>
							<v-col class="pl-4">
								<v-card class="px-4 py-2">
									<div class="text-caption text-grey-darken-1 mb-1">Forecast of days</div>
									<v-icon color="secondary">mdi-chart-bell-curve-cumulative</v-icon>
									<span class="text-caption pl-6">{{ info.forecast_of_days.toLocaleString('ru') }}</span>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
					<v-col class="pl-8" cols="auto">
						<div class="text-subtitle-2 text-grey-darken-1 font-weight-bold mb-2">Fans forecast of month</div>
						<template v-for="(item, index) in info.fans_forecast_of_months" :key="index">
							<v-row v-if="index < 7" justify="space-between" no-gutters class="mb-2">
								<div class="text-body-2 text-grey-darken-1">{{ item.month }}</div>
								<div class="text-body-2 text-right">{{ item.amount }}</div>
							</v-row>
						</template>
					</v-col>
				</v-row>
			</v-col>
			<v-col :cols="mdAndDown ? '12' : '6'" :style="mdAndDown ? '' : 'max-width: 50%'">
				<v-sheet class="bg-grey-lighten-5 px-5 py-4 rounded-lg fill-height">
					<div class="mb-15">
						<v-row justify="space-between" no-gutters class="mb-4">
							<div class="text-body-2 text-grey-darken-1">Completion</div>
							<div class="d-flex align-center">
								<div style="width: 80px">
									<v-progress-linear
											:model-value="info.completion"
											color="cyan-darken-3"
											rounded
											height="12"
									></v-progress-linear>
								</div>
								<div class="text-caption font-weight-bold text-cyan-darken-3 ml-2">{{ info.completion }}%</div>
							</div>
						</v-row>

<!--						<v-row justify="space-between" no-gutters>-->
<!--							<div class="text-body-2 text-grey-darken-1">End date</div>-->
<!--							<div class="text-body-2">03.06.2024</div>-->
<!--						</v-row>-->
					</div>
					<div>
						<Line :options="chartOptions" :data="chartData" :height="150"/>
					</div>
				</v-sheet>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<style scoped>

</style>