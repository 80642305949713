<template>
	<v-autocomplete
			placeholder="Model"
			variant="outlined"
			item-title="nickname"
			item-value="id"
			:items="items"
			:multiple='multiple'
			v-model:search.sync="searchString"
			@update:modelValue="searchString = ''"
	>
<!--		<template v-slot:selection="{ item, index }">-->
<!--			<span v-if="index === 0" class="text-grey text-caption align-self-center">(Выбрано элементов: {{ item }})</span>-->
<!--		</template>-->

		<template v-slot:item="data">
			<v-list-subheader v-if="data.props.header">
				{{ data.props.header }}
			</v-list-subheader>
			<v-divider v-else-if="data.props.divider" />
			<v-list-item v-else v-bind="data.props" :title="data.props.title">
				<template  v-if="multiple" v-slot:prepend="{ isActive }">
					<v-checkbox-btn :model-value="isActive"></v-checkbox-btn>
				</template>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>

<script>
export default {
	name: 'SelectModel',


	props: {
		items: {
			type: Array,
			default() {
				return []
			}
		},
		multiple: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			searchString: '',
		}
	}
}
</script>

<style>

</style>