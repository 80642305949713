<template>
	<div class="welcome bg-primary">
		<span class="welcome__bg-circle"></span>
		<img class="welcome__bg-splash" :src="require('@/assets/img/bg-splash.svg')" alt="bg-splash">
		<div class="welcome__inner">
			<div class="welcome__text-wrap">
				<div class="welcome__title">New Digital<br> soulution for<br> distance working</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'TheWelcome'
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.welcome {
		height: 100%;
		position: relative;
		overflow: hidden;

		&__bg-circle {
			position: absolute;
			left: 0;
			top: 65%;
			width: 570px;
			height: 570px;
			background-color: #264ECA;
			border-radius: 100%;
			transform: translateX(-35%);

			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				border-radius: 100%;
				transform: translate(-50%, -50%);
			}

			&:before {
				width: 80%;
				height: 80%;
				background-color: #244BC5;
			}

			&:after {
				width: 60%;
				height: 60%;
				background-color: #274FC7;
			}
		}

		&__bg-splash {
			position: absolute;
			top: -10px;
			right: -120px;
		}

		&__inner {
			height: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			margin-left: auto;
			position: relative;
		}

		&__text-wrap {
			flex: 1 1 auto;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__text {

		}

		&__title {
			font-size: 64px;
			font-weight: 700;
		}
	}
</style>