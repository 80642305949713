<template>
    <template v-if="myGroupLoaded">
        <v-form class="pa-6 elevation-6 rounded-lg bg-white position-relative" @submit.prevent>
            <transition-group name="schedule">
                <v-row no-gutters v-for="(item, index) in shifts" :key="index">
                    <v-col>
                        <div class="d-flex flex-wrap flex-md-nowrap mx-n3">
                            <v-col class="pt-0 pb-2" :class="smAndDown ? 'v-col-12' : ''">
                                <v-autocomplete
                                        label="Operator"
                                        variant="outlined"
                                        item-title="full_name"
                                        item-value="id"
                                        v-model="item.operator"
                                        :items="operatorsList"
                                        :rules="[rules.required]"
                                >
                                    <template #item="data">
                                        <v-list-subheader v-if="data.props.header">
                                            {{ data.props.header }}
                                        </v-list-subheader>
                                        <v-divider v-else-if="data.props.divider" />
                                        <v-list-item v-else v-bind="data.props"></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="pt-0 pb-2" :class="smAndDown ? 'v-col-12' : ''">
                                <v-select
                                        label="Shift"
                                        variant="outlined"
                                        item-title="name"
                                        item-value="id"
                                        v-model="item.shift_number"
                                        :items="shiftNumbers"
                                        :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                            <v-col class="pt-0 pb-2" :class="smAndDown ? 'v-col-12' : ''">
                                <SelectModel
                                        v-model="item.only_fans_models"
																				:items="modelsList"
																				:rules="[rules.required, rules.limited]"
																				multiple
                                />
                            </v-col>
                            <v-col class="pt-0 pb-7" :class="smAndDown ? 'v-col-12' : ''">
                                <VueDatePicker
                                        v-model="item.shift_date"
                                        auto-apply
                                        :format="format"
																				:clearable="false"
                                />
                            </v-col>
                        </div>
                    </v-col>
                    <v-col class="v-col-auto pt-0 pb-2" v-if="shifts.length > 1">
                        <v-btn icon="mdi-trash-can-outline" variant="text" size="large" @click="removeScheduleItem(index)"></v-btn>
                    </v-col>
                </v-row>
            </transition-group>
            <v-row justify="space-between" >
                <v-col cols="auto">
                    <label>
                        <v-btn icon="mdi-plus" size="small" color="primary" @click="addScheduleItem"></v-btn>
                        <span class="text-body-2 ml-2">Add shift</span>
                    </label>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                            type="submit"
                            size="large"
                            color="primary"
                            @click="handleAddShift"
                    >
                        Create
                    </v-btn>
                </v-col>
            </v-row>
            <div v-if="loadingAdd" class="overlay d-flex justify-center align-center">
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </div>
        </v-form>
    </template>
    <template v-else>
        <v-skeleton-loader
                class="rounded-lg elevation-2 mb-6"
                type="table-row, avatar, actions"
        ></v-skeleton-loader>
    </template>
</template>

<script>
  import { toast } from "vuetify-sonner"
  import { mapState } from 'vuex';
  import { useDisplay } from 'vuetify';
  import SelectModel from '@/components/SelectModel.vue';

  export default {
    setup () {
      // Destructure only the keys we want to use
      const { smAndDown } = useDisplay();

      return { smAndDown }
    },


    name: 'AddShift',


    components: {
      SelectModel,
    },


    data() {
      return {
        rules: {
          required: value => {
            if (value) return true;
            return 'Required field'
          },
          limited: value => {
            if (value.length > 3) {
              value.pop()
            }
          },
        },
        format: 'dd.MM.yyyy',
        shifts: [
          {
            operator: null,
            shift_number: null,
            only_fans_models: [],
            shift_date: new Date(),
          },
        ],
        otherLeads: [],
        operatorsList: [],
        shiftNumbers: [],
        messages: [],
				modelsList: [],
        loadingAdd: false,
        loadedAdd: false,
      };
    },


    computed: {
      ...mapState({
        userInfo: state => state.user.info,
        userLoading: state => state.user.userLoading,
        userLoaded: state => state.user.userLoaded,
        myGroup: state => state.user.myGroup,
        myGroupLoading: state => state.user.myGroupLoading,
        myGroupLoaded: state => state.user.myGroupLoaded,
      }),
    },


    created() {
      if (this.myGroupLoaded && this.userLoaded) {
        this.getOperators();
        this.getShiftNumbers();
				this.getModels();
      }
    },


    watch: {
      userLoaded() {
        if (this.myGroupLoaded && this.userLoaded) {
          this.getOperators();
          this.getShiftNumbers();
					this.getModels();
        }
      },
      myGroupLoaded() {
        if (this.myGroupLoaded && this.userLoaded) {
          this.getOperators();
          this.getShiftNumbers();
          this.getModels();
        }
      },
    },


    methods: {

			getModels() {
					this.modelsList = this.$store.getters['user/getModels'];
			},

      getShiftNumbers() {
        this.shiftNumbers = this.$store.getters['user/getShiftNumbers']
      },

      getOperators() {
        this.operatorsList = this.$store.getters['user/getOperators']
      },

      addScheduleItem() {
        const newItem = {
          operator: null,
          shift_number: null,
          only_fans_models: [],
          shift_date: new Date(),
        };
        this.shifts.push(newItem)
      },

      removeScheduleItem(index) {
        this.shifts.splice(index, 1)
      },

      async handleAddShift() {
        this.loadingAdd = true;
        this.loadedAdd = false;
        const successShiftsIndex = [];
        for (let i = 0; i < this.shifts.length; i++) {
          const shiftDate = this.shifts[i].shift_date;
          const shiftDateYear = shiftDate.getFullYear();
          const shiftDateMonth = (shiftDate.getMonth() + 1).toString().padStart(2, '0');
          const shiftDateDay = (shiftDate.getDate().toString().padStart(2, '0'));
          const formattedShiftDate = `${shiftDateYear}-${shiftDateMonth}-${shiftDateDay}`;
          const data = {
            operator: this.shifts[i].operator,
            shift_number: this.shifts[i].shift_number,
            only_fans_models: this.shifts[i].only_fans_models,
            shift_date: formattedShiftDate,
          };
          try {
            const response = await this.$store.dispatch('shifts/addShift', data);
            let models = Array.from(new Set(response.shift_info.models.map(el => el.name )));
            const dateElements = response.shift_date.split('-');
            const newDateFormat = `${dateElements[2]}.${dateElements[1]}.${dateElements[0]}`;
            toast('Shift successfully created:', {
              duration: 5000,
              description: `Operator: ${response.operator.full_name}, Shift: ${response.shift_number.name}, Models: ${models.join(',')}, Date: ${newDateFormat}`,
              cardProps: {
                color: 'success',
              },
            });
            successShiftsIndex.push(i)
          } catch (error) {
            toast('Shift cannot be created:', {
              duration: 5000,
              description: `${JSON.stringify(error.response.data)}`,
              cardProps: {
                color: 'error',
              },
            });
          }
        }
        this.shifts = this.shifts.filter((item, index) => !successShiftsIndex.includes(index) );
        if (this.shifts.length === 0) {
          this.shifts = [
            {
              operator: null,
              shift_number: null,
              only_fans_models: [],
              shift_date: new Date(),
            }
          ]
        }
        this.loadingAdd = false;
        this.loadedAdd = true;
      },

    }
  }
</script>

<style scoped lang="less">
	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(255, 255, 255, 0.6);
	}

	.counter-combobox .v-combobox__selection {
		display: none;
	}
</style>