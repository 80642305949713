<template>
	<v-card class="pa-6">
		<v-form v-model="form" class="fill-height d-flex flex-column" @submit.prevent>
			<div class="flex-0-0">
				<div class="mb-4">
					<template v-if="checkMonthChanging">
						<span class="text-grey-darken-1">{{ formTitle }}</span><span class="text-h5 mb-4"><v-icon class="mx-2" size="24">mdi-arrow-right</v-icon>{{monthName}} {{month.year}}</span>
					</template>
					<template v-else>
						<span class="text-h5">{{ formTitle }}</span>
					</template>
				</div>
				<v-divider></v-divider>
			</div>
			<div class="flex-1-1 overflow-y-auto">
				<div class="py-8 px-3" :class="mdAndDown ? 'overflow-x-auto mx-n3' : ''" :style="mdAndDown ? 'width: 1100px;' : ''">
					<v-row align="center" class="mb-6 flex-nowrap">
						<v-col cols="auto" class="flex-1-1">
							<v-row align="center">
								<v-col cols="7" class="pr-14">
									<div class="d-flex">
										<VueDatePicker
												class="width-auto"
												placeholder="Выбрать месяц"
												auto-apply
												v-model="month"
												month-picker
												position="left"
										>
											<template #trigger>
												<v-btn
														class="mr-6"
														variant="outlined"
														color="primary"
														density="comfortable"
														prepend-icon="mdi-pencil"
												>{{ edited !== -1 ? 'Изменить месяц' : 'Выбрать месяц' }}</v-btn>
											</template>
											<template #input-icon>
												<v-icon class="pl-4 mr-1" size="18">mdi-pencil</v-icon>
											</template>
										</VueDatePicker>
										<label class="d-inline-flex align-center">
											<v-btn
													:disabled="!previousPeriodId"
													variant="outlined"
													color="primary"
													density="comfortable"
													min-width="36"
													class="px-2"
													@click="dialogCopy = true"
											>
												<v-icon>mdi-content-copy</v-icon>
											</v-btn>
											<span class="text-caption ml-2">Копировать данные предыдущего месяца</span>
										</label>
									</div>
								</v-col>
								<v-col v-if="previousPeriod" cols="5" class="pl-2">
									<div class="text-subtitle-1 font-weight-medium">{{ previousPeriod.name }}</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col style="width: 60px" class="flex-0-0"></v-col>
					</v-row>

					<template v-if="items.length > 0">

						<v-row align="center" class="flex-nowrap">
							<v-col cols="auto" class="flex-1-1">
								<v-row align="center">
									<v-col cols="7" class="pr-14">
										<v-row align="center">
											<v-col cols="6"></v-col>
											<v-col cols="6">
												<div class="text-body-1">Бюджет</div>
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="5" class="pl-2">
										<v-row align="center" v-if="previousPeriod">
											<v-col cols="12">
												<div class="text-body-1">Потрачено</div>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
							<v-col style="width: 60px" class="flex-0-0"></v-col>
						</v-row>

						<v-row align="start" v-for="(item, index) in items" :key="index" class="flex-nowrap">
							<v-col cols="auto" class="flex-1-1">
								<v-row>
									<v-col cols="7" class="pr-14">
										<v-row align="start">
											<v-col class="plan-cell" cols="6">
												<span>{{ item.only_fans_model.nickname }}</span>
											</v-col>
											<v-col cols="6">
												<v-text-field
														v-model="item.cost_plan"
														density="compact"
														variant="outlined"
														placeholder="Бюджет"
														type="number"
														:rules="[rules.required, rules.notNull]"
														prepend-inner-icon="mdi-currency-usd"
														hide-details="auto"
												></v-text-field>
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="5"  class="pl-2">
										<template v-if="previousPeriod">
											<template v-if="targetModel(item.only_fans_model.id)">
												<v-row align="start">
													<v-col class="plan-cell" cols="12">
														<div class="d-flex align-center">
															<span style="width: 70px" class="text-no-wrap">{{ previousCostFact(item.only_fans_model.id) }}</span>
															<div style="width: 80px">
																<v-progress-linear
																		:model-value="previousCostFactPercent(item.only_fans_model.id)"
																		:color="previousCostFactColor(item.only_fans_model.id)"
																		rounded
																		height="12"
																></v-progress-linear>
															</div>
															<span style="text-overflow: ellipsis; width: 65px;" class="ml-2 text-no-wrap font-weight-bold overflow-hidden" :class="'text-' + previousCostFactColor(item.only_fans_model.id)">{{ previousCostFactPercent(item.only_fans_model.id) }}%</span>
														</div>
													</v-col>
												</v-row>
											</template>
											<template v-else>
												<div class="text-center">Нет данных за предыдущий период</div>
											</template>
										</template>
									</v-col>
								</v-row>
							</v-col>
							<v-col style="width: 60px" class="flex-0-0 plan-cell">
								<v-btn variant="flat" density="comfortable" icon="mdi-delete" @click="deleteModel(item)"></v-btn>
							</v-col>
						</v-row>

					</template>

					<template v-else>
						<div class="mt-10 text-body-1 text-center">
							<span>...</span><br>
							<span>Добавьте модель, чтобы начать заполнять план</span>
						</div>
					</template>

					<div class="mt-6">
						<v-btn variant="outlined" density="comfortable" prepend-icon="mdi-plus" @click="dialogAddModel = true">Добавить модель</v-btn>
					</div>
				</div>

				<v-dialog v-model="dialogAddModel" max-width="400px">
					<v-card class="pa-6">
						<div class="text-h5 mb-8">Добавить модель</div>
						<v-form @submit.prevent>
							<v-row class="flex-wrap">
								<v-col>
									<SelectModel
											v-model="modelToAdd"
											:items="models"
											:rules="[rules.required]"
											:no-data-text="noDataText"
											return-object
									/>
								</v-col>
							</v-row>
							<v-row justify="end" no-gutters class="mt-4 pa-0">
								<v-btn variant="text" @click="closeAddModelDialog" class="mr-2">Отмена</v-btn>
								<v-btn :disabled="!modelToAdd" color="primary" @click="addModelToPlan">Добавить</v-btn>
							</v-row>
						</v-form>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogDeleteModel" max-width="340px">
					<v-card class="pa-6">
						<div class="text-body-1 mb-2">Удалить модель из плана</div>
						<div class="text-subtitle-2">Вы уверены?</div>
						<v-row justify="end" no-gutters class="mt-10 pa-0">
							<v-btn color="primary" class="mr-2" variant="text" @click="closeDeleteModel">Отмена</v-btn>
							<v-btn color="primary" @click="deleteModelConfirm">Подтвердить</v-btn>
						</v-row>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogCopy" max-width="340px">
					<v-card class="pa-6">
						<div class="text-body-1 mb-2">Копировать предыдущий месяц</div>
						<div class="text-subtitle-2">Заполнить поля данными из предыдущего месяца?</div>
						<v-row justify="end" no-gutters class="mt-10 pa-0">
							<v-btn color="primary" class="mr-2" variant="text" @click="dialogCopy = false">Отмена</v-btn>
							<v-btn color="primary" @click="copyPreviousPeriod">Подтвердить</v-btn>
						</v-row>
					</v-card>
				</v-dialog>

			</div>
			<div class="flex-0-0">
				<v-divider></v-divider>
				<v-row justify="start" no-gutters class="mt-4 pa-0">
					<v-btn
							v-if="edited !== -1"
							:loading="formLoading"
							type="submit"
							color="primary"
							@click="editPlan"
					>Сохранить</v-btn>
					<v-btn
							v-else
							:loading="formLoading"
							type="submit"
							color="primary"
							@click="addPlan"
					>Создать</v-btn>
					<v-btn
							variant="text"
							@click="$emit('close')"
							class="ml-2"
					>Отмена</v-btn>
				</v-row>
				<div v-if="errors" class="mt-4 text-error">{{errors}}</div>
			</div>
		</v-form>
	</v-card>
</template>

<script>
import apiBudget from '@/api/budget';
import { mapState } from 'vuex';
import SelectModel from "@/components/SelectModel.vue";
import {useDisplay} from "vuetify";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: 'BudgetEditDialog',


	components: {
		SelectModel,
	},


	props: {
		edited: {
			type: Number
		},
		info: {
			type: Object
		},
		type: {},
		periods: {
			type: Array
		}
	},


	data() {
		return {
			rules: {
				required: value => {
					if (value === []) return 'Обязательное поле';
					if (value) return true;
					return 'Обязательное поле'
				},
				notNull: value => {
					if (value === 0 || value === '0') return 'Введите значение больше нуля';
				},
			},
			form: false,
			errors: null,
			monthNamesList: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
			formLoading: false,
			month: null,
			monthOldValue: null,
			format: 'MM/yyyy',
			dialogCopy: false,
			dialogAddModel: false,
			dialogDeleteModel: false,
			editedModelIndex: -1,
			modelToAdd: null,
			items: [],
			previousPeriodId: null,
			previousPeriodLoaded: false,
			previousPeriod: null,
			previousPeriodItems: [],
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),

		monthName() {
			return this.monthNamesList[this.month?.month]
		},

		formTitle() {
			return this.edited === -1 ? 'Новый месяц' : this.info.name
		},

		models() {
			let models = structuredClone(this.commonListModels);
			this.items.forEach(target => {
				models.forEach((model, index) => {
					if (target.only_fans_model.nickname === model.nickname) {
						models.splice(index, 1)
					}
				})
			})
			let activeModels = models.filter(elem => elem.marketer);
			return activeModels
		},

		checkMonthChanging() {
			if (this.edited !== -1) {
				return !(this.month.month === this.monthOldValue.month && this.month.year === this.monthOldValue.year)
			} else {
				return this.month && !this.monthOldValue
			}
		},

		noDataText() {
			if (this.commonListModels.length === 0) {
				return 'Список моделей пуст'
			} else if (this.models.length === 0) {
				return 'Все модели добавлены'
			} else {
				return ''
			}
		},
	},


	created() {
		if (this.edited !== -1) {
			this.month = {
				month: new Date(this.info.start_date).getMonth(),
				year: new Date(this.info.start_date).getFullYear()
			};
			this.monthOldValue = {
				month: new Date(this.info.start_date).getMonth(),
				year: new Date(this.info.start_date).getFullYear()
			};
			this.getPreviousPeriod();
			this.items = structuredClone(this.info.marketing_budget_expected_results);
		}
		if (this.previousPeriodId) {
			this.getPeriod();
		}
	},


	watch: {
		month() {
			this.getPreviousPeriod();
			this.errors = null
		}
	},


	methods: {
		getPreviousPeriod() {
			let year = this.month.year;
			let month = this.month.month;
			if (month === 0) {
				year = year - 1
				month = 12
			}
			month = month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
			const previousMonth = `${year}-${month}-01`;
			this.previousPeriodId = this.periods.find(item => item.start_date === previousMonth)?.id;
			if (this.previousPeriodId) {
				this.getPeriod();
			} else {
				this.previousPeriod = null
			}
		},

		calcValue(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			return Math.round(value2 / value1 * 100);
		},

		calcColor(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			const result = Math.round(value2 / value1 * 100);
			if (result > 60) {
				return 'cyan-darken-3'
			} else if (result <= 60 && result > 20) {
				return 'amber-darken-2'
			} else if (result <=20) {
				return 'deep-orange-darken-1'
			}
		},

		closeAddModelDialog() {
			this.dialogAddModel = false;
			this.modelToAdd = null
		},

		addModelToPlan() {
			const item = {
				only_fans_model: this.modelToAdd,
				cost_plan: null,
				promo_type: this.type
			}
			this.items.push(item)
			this.dialogAddModel = false;
			this.modelToAdd = null
		},

		deleteModel(item) {
			this.editedModelIndex = this.items.indexOf(item);
			this.dialogDeleteModel = true
		},

		closeDeleteModel() {
			this.dialogDeleteModel = false;
			this.$nextTick(() => {
				this.editedModelIndex = -1
			})
		},

		async deleteModelConfirm() {
			this.items.splice(this.editedModelIndex, 1)
			this.closeDeleteModel();
		},

		copyPreviousPeriod() {
			this.items = structuredClone(this.previousPeriodItems);
			this.dialogCopy = false
		},

		async getPeriod() {
			try {
				this.previousPeriodLoaded = false;
				this.previousPeriod = await apiBudget.getPeriod(this.previousPeriodId, this.type);
				this.previousPeriodItems = this.previousPeriod.marketing_budget_expected_results;
				this.previousPeriodLoaded = true
			} catch (error) {
				console.log('previous period loading error: ', error)
			}
		},

		targetModel(id) {
			return this.previousPeriodItems.find(item => item.only_fans_model.id === id)
		},

		previousCostFact(id) {
			const target = this.previousPeriodItems.find(item => item.only_fans_model.id === id);
			return target.cost_fact
		},

		previousCostFactPercent(id) {
			const target = this.previousPeriodItems.find(item => item.only_fans_model.id === id);
			return this.calcValue(target.cost_plan, target.cost_fact)
		},

		previousCostFactColor(id) {
			const target = this.previousPeriodItems.find(item => item.only_fans_model.id === id);
			return this.calcColor(target.cost_plan, target.cost_fact)
		},

		async addPlan() {
			if (this.month) {
				try {
					this.formLoading = true;
					const lastDayOfMonth = new Date(this.month.year, this.month.month + 1, 0).getDate();
					const expected_results = []
					this.items.forEach(item => {
						expected_results.push({
							cost_plan: item.cost_plan,
							only_fans_model: item.only_fans_model.id,
							promo_type: item.promo_type
						})
					});
					const data = {
						start_date: `${this.month.year}-${this.month.month + 1}-01`,
						end_date: `${this.month.year}-${this.month.month + 1}-${lastDayOfMonth}`,
						name: `${this.monthName} ${this.month.year}`,
						marketing_budget_expected_results: expected_results
					}
					const response = await apiBudget.addPeriod(data);
					this.$emit('add', response)
					this.$emit('close')
				} catch (error) {
					console.log('error', error)
				} finally {
					this.formLoading = false;
				}
			} else {
				this.errors = 'Не выбран месяц'
			}
		},

		async editPlan() {
			try {
				this.formLoading = true;
				const lastDayOfMonth = new Date(this.month.year, this.month.month + 1, 0).getDate();
				const expected_results = []
				let newName = null;
				if (this.month) {
					newName = `${this.monthName} ${this.month.year}`
				}
				this.items.forEach(item => {
					expected_results.push({
						cost_plan: item.cost_plan,
						only_fans_model: item.only_fans_model.id,
						promo_type: item.promo_type
					})
				});
				const data = {
					start_date: `${this.month.year}-${this.month.month + 1}-01`,
					end_date: `${this.month.year}-${this.month.month + 1}-${lastDayOfMonth}`,
					marketing_budget_expected_results: expected_results,
					promo_type: this.type
				}
				if (newName) {
					data.name = newName
				}
				const response = await apiBudget.editPeriod(this.info.id, data);
				this.$emit('edit', response)
				this.$emit('close')
			} catch (error) {
				console.log('error', error);
			} finally {
				this.formLoading = false;
			}
		},
	}
}
</script>

<style scoped lang="less">
.plan-cell {
	display: flex;
	align-items: center;
	min-height: 68px;
}
</style>