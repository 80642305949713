<script>
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
import {useDisplay} from "vuetify";

ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
)

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "WidgetLineChart",


	props: {
		title: {},
		subtitle: {},
		points: {},
		yesterdayDiffer: {}
	},


	components: {
		Line
	},


	data() {
		return {
			dialog: false,
			fullscreenChartData: {
				labels: ['150 days ago', '120 days ago', '90 days ago', '60 days ago', '30 days ago', '15 days ago', 'Today'],
				datasets: [
					{
						data: this.points?.map((el) => Math.round(el.cost_result)).reverse(),
						backgroundColor: '#1976D2',
						borderColor: '#1976D2',
						pointStyle: 'circle',
						pointRadius: 6,
						pointHoverRadius: 10
					}
				]
			},
			fullscreenChartOptions: {
				interaction: {
					intersect: false,
					mode: 'index',
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						padding: 16,
						titleMarginBottom: 12,
						caretSize: 8,
						usePointStyle: true,
						callbacks: {
							// label: (context) => {
							// 	return context.raw + '$'
							// },
							// labelPointStyle: () => {
							// 	return {
							// 		pointStyle: 'star',
							// 		rotation: 0
							// 	}
							// }
						},
						enabled: false,
						position: 'nearest',
						external: (context) => {
							// Tooltip Element
							const {chart, tooltip} = context;
							const toLeft = (tooltip.caretX + tooltip.width) > chart.width;
							let tooltipEl = chart.canvas.parentNode.querySelector('div');
							let triangle = document.createElement('div');

							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
								tooltipEl.style.borderRadius = '8px';
								tooltipEl.style.color = 'white';
								tooltipEl.style.opacity = 1;
								tooltipEl.style.pointerEvents = 'none';
								tooltipEl.style.position = 'absolute';
								tooltipEl.style.transition = 'all .1s ease';

								triangle.className = 'triangle';
								triangle.style.position = 'absolute';
								triangle.style.bottom = '100%';
								triangle.style.border = '7px solid transparent';
								triangle.style.borderTop = '0';
								triangle.style.borderBottom = '10px solid rgba(0, 0, 0, 0.7)';
								const table = document.createElement('table');
								table.style.margin = '0px';
								tooltipEl.appendChild(triangle);
								tooltipEl.appendChild(table);
								chart.canvas.parentNode.appendChild(tooltipEl);
							}

							if (toLeft) {
								tooltipEl.style.transform = `translate(-${tooltip.width}px, 24px)`;
								tooltipEl.querySelector('.triangle').style.right = '10px';
								tooltipEl.querySelector('.triangle').style.left = 'unset';
							} else {
								tooltipEl.style.transform = 'translate(-17px, 24px)';
								tooltipEl.querySelector('.triangle').style.left = '10px';
								tooltipEl.querySelector('.triangle').style.right = 'unset';
							}

							// Hide if no tooltip
							if (tooltip.opacity === 0) {
								tooltipEl.style.opacity = 0;
								return;
							}

							// Set Text
							if (tooltip.body) {
								const titleLines = tooltip.title || [];
								const bodyLines = tooltip.body.map(b => b.lines);

								const tableHead = document.createElement('thead');

								titleLines.forEach(title => {
									const tr = document.createElement('tr');
									tr.style.borderWidth = 0;

									const th = document.createElement('th');
									th.style.borderWidth = 0;
									th.style.paddingBottom = '12px';
									const text = document.createTextNode(title);

									th.appendChild(text);
									tr.appendChild(th);
									tableHead.appendChild(tr);
								});

								const tableBody = document.createElement('tbody');
								bodyLines.forEach((body, i) => {
									const colors = tooltip.labelColors[i];

									//create square
									const span = document.createElement('span');
									span.style.background = colors.backgroundColor;
									span.style.borderColor = colors.borderColor;
									span.style.borderWidth = '2px';
									span.style.marginRight = '10px';
									span.style.height = '10px';
									span.style.width = '10px';
									span.style.display = 'inline-block';

									const tr = document.createElement('tr');
									tr.style.backgroundColor = 'inherit';
									tr.style.borderWidth = 0;

									const td = document.createElement('td');
									td.style.borderWidth = 0;
									td.style.fontSize = '18px';

									const text = document.createTextNode(body + '$');

									// td.appendChild(span);
									td.appendChild(text);
									tr.appendChild(td);
									tableBody.appendChild(tr);
								});

								const tableRoot = tooltipEl.querySelector('table');

								// Remove old children
								while (tableRoot.firstChild) {
									tableRoot.firstChild.remove();
								}

								// Add new children
								tableRoot.appendChild(tableHead);
								tableRoot.appendChild(tableBody);
							}

							const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

							// Display, position, and set styles for font
							tooltipEl.style.opacity = 1;
							tooltipEl.style.left = positionX + tooltip.caretX + 'px';
							tooltipEl.style.top = positionY + tooltip.caretY + 'px';
							tooltipEl.style.font = tooltip.options.bodyFont.string;
							tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
						}
					}
				},
				scales: {
					x: {
						grid: { display: false },
						border: { display: false },
						// ticks: { display: false }
					},
					y: {
						grid: { display: false },
						border: { display: false },
						// ticks: { display: false }
					},
				},
			},
			chartData: {
				labels: ['150 days ago', '120 days ago', '90 days ago', '60 days ago', '30 days ago', '15 days ago', 'Today'],
				datasets: [
					{
						data: this.points?.map((el) => Math.round(el.cost_result)).reverse(),
						backgroundColor: '#fff',
						borderColor: 'rgba(255,255,255,0.5)',
						pointStyle: 'circle',
						pointRadius: 3,
						pointHoverRadius: 8
					}
				]
			},
			chartOptions: {
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						padding: 12,
						titleMarginBottom: 12,
						caretSize: 8,
						usePointStyle: true,
						callbacks: {
							// label: (context) => {
							// 	return context.raw + '$'
							// },
							// labelPointStyle: () => {
							// 	return {
							// 		pointStyle: 'star',
							// 		rotation: 0
							// 	}
							// }
						},
						enabled: false,
						position: 'nearest',
						external: (context) => {
							// Tooltip Element
							const {chart, tooltip} = context;
							let tooltipEl = chart.canvas.parentNode.querySelector('div');

							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
								tooltipEl.style.borderRadius = '8px';
								tooltipEl.style.color = 'white';
								tooltipEl.style.opacity = 1;
								tooltipEl.style.pointerEvents = 'none';
								tooltipEl.style.position = 'absolute';
								tooltipEl.style.transform = 'translate(-17px, 24px)';
								tooltipEl.style.transition = 'all .1s ease';

								const triangle = document.createElement('div');
								triangle.style.position = 'absolute';
								triangle.style.bottom = '100%';
								triangle.style.left = '10px';
								triangle.style.border = '7px solid transparent';
								triangle.style.borderTop = '0';
								triangle.style.borderBottom = '10px solid rgba(0, 0, 0, 0.7)';
								// triangle.style.width = '10px';
								// triangle.style.height = '10px';

								const table = document.createElement('table');
								table.style.margin = '0px';

								tooltipEl.appendChild(triangle);
								tooltipEl.appendChild(table);
								chart.canvas.parentNode.appendChild(tooltipEl);
							}

							// Hide if no tooltip
							if (tooltip.opacity === 0) {
								tooltipEl.style.opacity = 0;
								return;
							}

							// Set Text
							if (tooltip.body) {
								const titleLines = tooltip.title || [];
								const bodyLines = tooltip.body.map(b => b.lines);

								const tableHead = document.createElement('thead');

								titleLines.forEach(title => {
									const tr = document.createElement('tr');
									tr.style.borderWidth = 0;

									const th = document.createElement('th');
									th.style.borderWidth = 0;
									th.style.paddingBottom = '12px';
									const text = document.createTextNode(title);

									th.appendChild(text);
									tr.appendChild(th);
									tableHead.appendChild(tr);
								});

								const tableBody = document.createElement('tbody');
								bodyLines.forEach((body, i) => {
									const colors = tooltip.labelColors[i];

									//create square
									const span = document.createElement('span');
									span.style.background = colors.backgroundColor;
									span.style.borderColor = colors.borderColor;
									span.style.borderWidth = '2px';
									span.style.marginRight = '10px';
									span.style.height = '10px';
									span.style.width = '10px';
									span.style.display = 'inline-block';

									const tr = document.createElement('tr');
									tr.style.backgroundColor = 'inherit';
									tr.style.borderWidth = 0;

									const td = document.createElement('td');
									td.style.borderWidth = 0;
									td.style.fontSize = '18px';

									const text = document.createTextNode(body + '$');

									// td.appendChild(span);
									td.appendChild(text);
									tr.appendChild(td);
									tableBody.appendChild(tr);
								});

								const tableRoot = tooltipEl.querySelector('table');

								// Remove old children
								while (tableRoot.firstChild) {
									tableRoot.firstChild.remove();
								}

								// Add new children
								tableRoot.appendChild(tableHead);
								tableRoot.appendChild(tableBody);
							}

							const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

							// Display, position, and set styles for font
							tooltipEl.style.opacity = 1;
							tooltipEl.style.left = positionX + tooltip.caretX + 'px';
							tooltipEl.style.top = positionY + tooltip.caretY + 'px';
							tooltipEl.style.font = tooltip.options.bodyFont.string;
							tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
						}
					},
				},
				scales: {
					x: {
						grid: { display: false },
						border: { display: false },
						ticks: { display: false }
					},
					y: {
						grid: { display: false },
						border: { display: false },
						ticks: { display: false }
					},
				},
				responsive: true,
				maintainAspectRatio: false,
			}
		}
	}
}
</script>

<template>
	<v-sheet class="rounded-lg px-5 py-4 elevation-1 fill-height" @click="dialog = true">
		<v-row align="center">
			<v-col cols="auto" :hidden="mdAndDown">
				<v-icon size="40">mdi-chart-donut</v-icon>
			</v-col>
			<v-col>
				<div class="text-h6 mb-2">{{ title }}</div>
				<div class="text-body-1">{{ subtitle }}</div>
			</v-col>
		</v-row>
		<v-row align="center">
			<v-col :cols="mdAndDown ? 12 : 5">
				<div class="text-h6 font-weight-bold">
					{{ Math.round(points?.[0].cost_result).toLocaleString('ru') }}$
					<v-icon v-if="yesterdayDiffer === 'positive'" class="ml-2" size="16">mdi-arrow-up-thick</v-icon>
					<v-icon v-if="yesterdayDiffer === 'negative'" class="ml-2" size="16">mdi-arrow-down-thick</v-icon>
				</div>
			</v-col>
			<v-col :cols="mdAndDown ? 12 : 7" class="mt-n6">
				<div>
					<Line :options="chartOptions" :data="chartData" :height="110"/>
				</div>
			</v-col>
		</v-row>
		<v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
			<v-card :class="mdAndDown ? 'pa-3' : 'pa-12'">
				<v-row no-gutters class="fill-height" align="center" justify="center">
					<v-col cols="12">
						<div class="text-h4 mb-4 text-center">{{ title }}</div>
						<v-row no-gutters justify="end">
							<div class="d-flex align-center mb-6">
								<span class="bg-primary mr-2" style="width: 21px; height: 8px"></span>
								<div class="text-body-1">Cost result</div>
							</div>
						</v-row>
						<div style="width: 100%; max-height: 400px; height: 100%">
							<Line :options="fullscreenChartOptions" :data="fullscreenChartData" :height="400"/>
						</div>
						<div class="d-flex justify-center mt-10">
							<v-btn color="primary" @click="dialog = false" size="large">Close</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</v-sheet>
</template>

<style scoped>

</style>