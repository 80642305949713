import axios from "@/services/axios-instance";

export default {

  async getManagers() {
    const response = await axios.get('/v1/client-managers/');
    return response.data
  },

  async addManager(data) {
    const response = await axios.post('/v1/client-managers/', data);
    return response.data
  },

  async editManager(data, id) {
    const response = await axios.patch(`/v1/client-managers/${id}/`, data);
    return response.data
  },

  async deleteManager(id) {
    await axios.delete(`/v1/client-managers/${id}/`);
  },

  async getModelNationalities() {
    const response = await axios.get('/v1/client-management/nationalities/');
    return response.data
  },

  async getModelLanguages() {
    const response = await axios.get('/v1/client-management/languages/');
    return response.data
  },

  async getModelCountries() {
    const response = await axios.get('/v1/client-management/countries/');
    return response.data
  },

  async getSocials() {
    const response = await axios.get('/v1/client-management/socials/');
    return response.data
  },

  async getDatabook(data) {
    const response = await axios.get('/v1/client-management/models-info/', { params: data });
    return response.data
  },

  async getDatabookDetail(id) {
    const response = await axios.get(`/v1/client-management/models-info/${id}/`);
    return response.data
  },

  async editDatabookDetail(id, data) {
    const response = await axios.patch(`/v1/client-management/models-info/${id}/`, data);
    return response.data
  },

  async getCommonCalendar(data) {
    const response = await axios.get('/v1/client-management/calendar/', { params: data });
    return response.data
  },

  async getEventTypes() {
    const response = await axios.get('/v1/client-management/calendar/event-types/');
    return response.data
  },

  async getEventRepeatTypes() {
    const response = await axios.get('/v1/client-management/calendar/event-repeat-types/');
    return response.data
  },

  async addEvent(data) {
    const response = await axios.post('/v1/client-management/calendar/', data);
    return response.data
  },

  async editEvent(id, data) {
    const response = await axios.patch(`/v1/client-management/calendar/${id}/`, data);
    return response.data
  },

  async deleteEvent(id) {
    await axios.delete(`/v1/client-management/calendar/${id}/`);
  },

  async getFiles(data) {
    const response = await axios.get('/v1/client-management/files/', { params: data });
    return response.data
  },

  async addFile(data) {
    const response = await axios.post('/v1/client-management/files/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data
  },

  async deleteFile(id) {
    await axios.delete(`/v1/client-management/files/${id}/`);
  },
}