import axios from "@/services/axios-instance";

export default {
  async getTotalSpend(data) {
    const response = await axios.get('/v1/finance/total-spend/', {params: data});
    return response.data
  },

  async copyTable(data) {
    const response = await axios.get('/v1/finance/total-spend/copy-table/', {params: data});
    return response.data
  },

  async getAveragePurchaseOnModel() {
    const response = await axios.get('/v1/finance/average-purchase-on-model/');
    return response.data
  },

  async getFutureTotalPurchase() {
    const response = await axios.get('/v1/finance/future-total-purchase/');
    return response.data
  },

  async getDebts(data) {
    const response = await axios.get('/v1/finance/debts/', { params: data });
    return response.data
  },

  async getDebtsCopy(data) {
    const response = await axios.get('/v1/finance/debts/copy/', { params: data });
    return response.data
  },

  async getDebtsHistory(data) {
    const response = await axios.get('/v1/finance/total-debts-history/', { params: data });
    return response.data
  },

  async getWeekDebt(data) {
    const response = await axios.get('/v1/finance/debts-tracking/', { params: data });
    return response.data
  },

  async getFuturePurchase() {
    const response = await axios.get('/v1/finance/future-purchase/');
    return response.data
  },

  async getFuturePurchaseCopy() {
    const response = await axios.get('/v1/finance/future-purchase/copy/');
    return response.data
  },

  async getTodayReport() {
    const response = await axios.get('/v1/finance/today-report/');
    return response.data
  },

  async getTodayReportCopy() {
    const response = await axios.get('/v1/finance/today-report/copy/');
    return response.data
  },

  async getCategoriesSummary() {
    const response = await axios.get('/v1/finance/categories-summary/');
    return response.data
  },

  async getCategoriesSummaryCopy() {
    const response = await axios.get('/v1/finance/categories-summary/copy/');
    return response.data
  },

  async getDebtsByModel() {
    const response = await axios.get('/v1/finance/debts/summary/');
    return response.data
  },

  async getDebtsByModelCopy() {
    const response = await axios.get('/v1/finance/debts/summary/copy/');
    return response.data
  },

  async getForecast() {
    const response = await axios.get('/v1/finance/forecast/');
    return response.data
  },

  async getForecastTable() {
    const response = await axios.get('/v1/finance/forecast/table-view/');
    return response.data
  },

  async getForecastTableCopy() {
    const response = await axios.get('/v1/finance/forecast/table-view/copy/');
    return response.data
  },

  async getPaymentsRequests(data) {
    const response = await axios.get('/v1/finance/payments/requests/', { params: data });
    return response.data
  },

  async getPaymentSystems() {
    const response = await axios.get('/v1/finance/payments/systems/');
    return response.data
  },

  async getCreators() {
    const response = await axios.get('/v1/finance/payments/creators/');
    return response.data
  },

  async getPaymentTools() {
    const response = await axios.get('/v1/finance/payments/tools/');
    return response.data
  },

  async getExpenseItems() {
    const response = await axios.get('/v1/finance/payments/expense-items/');
    return response.data
  },

  async getCurrencies() {
    const response = await axios.get('/v1/finance/payments/currencies/');
    return response.data
  },

  async getDepartments() {
    const response = await axios.get('/v1/finance/payments/departments/');
    return response.data
  },

  async patchPaymentRequest(id, data) {
    const response = await axios.patch(`/v1/finance/payments/requests/${id}/`, data);
    return response.data
  },

  async toggleReviewerPaymentRequest(id) {
    const response = await axios.post(`/v1/finance/payments/requests/${id}/toggle-reviewer/`);
    return response.data
  },

  //-------------- Payouts--------------//

  async getPayoutAddresses() {
    const response = await axios.get('/v1/finance/payouts/addresses/');
    return response.data
  },

  async getPayoutsReviews(data) {
    const response = await axios.get('/v1/finance/payouts/reviews/', { params: data });
    return response.data
  },

  async patchPayout(id, data) {
    const response = await axios.patch(`/v1/finance/payouts/reviews/${id}/`, data);
    return response.data
  },

  async addPayout(data) {
    const response = await axios.post('/v1/finance/payouts/reviews/', data);
    return response.data
  },

  async toggleReviewerPayoutReview(id) {
    const response = await axios.post(`/v1/finance/payouts/reviews/${id}/toggle-reviewer/`);
    return response.data
  }
}