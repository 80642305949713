<script>
import PayoutConfirmDialog from "@/components/PayoutConfirmDialog.vue";
import apiFinance from "@/api/finance"
import {toast} from "vuetify-sonner";
import {useDisplay} from "vuetify";
import {mapState} from "vuex";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "PayoutCard",


	components: {
		PayoutConfirmDialog
	},


	props: {
		info: {}
	},


	data() {
		return {
			payoutCancelLoading: false,
			payoutCancelOpen: false,
			payoutConfirmOpen: false,
			payoutTakeOffOpen: false,
			takeLoading: false,
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
		})
	},


	methods: {

		async handlePayoutCancel() {
			try {
				this.payoutCancelLoading = true;
				const response = await apiFinance.patchPayout(this.info.id, {
					finance_review_result: 'reject'
				});
				this.$emit('reject', response);
				this.payoutCancelLoading = false;
				this.payoutCancelOpen = false
			} catch (error) {
				console.log(error)
			}
		},

		handleAccept(response) {
			this.$emit('accept', response);
		},

		handleUpdate(response) {
			this.$emit('update', response);
		},

		copyField(value) {
			window.navigator.clipboard.writeText(value);
			toast('Field copied:', {
				duration: 5000,
				description: 'Field contents copied to clipboard',
				cardProps: {
					color: 'success',
				},
			});
		},

		async toggleReviewer() {
			this.takeLoading = true;
			const response = await apiFinance.toggleReviewerPayoutReview(this.info.id);
			this.$emit('update', response);
			this.takeLoading = false;
			this.payoutTakeOffOpen = false;
		},

	},
}
</script>

<template>
	<v-card class="px-5 py-4 rounded-lg">

		<v-row justify="space-between" no-gutters>
			<v-col cols="auto">
				<div v-if="info.finance_review_result === 'review'" class="mb-2">
					<v-icon v-if="info.finance_reviewer && info.finance_reviewer.id !== userInfo.id" color="primary">mdi-flag</v-icon>
					<v-btn v-else-if="info.finance_reviewer" :loading="takeLoading" density="compact" variant="plain" icon="mdi-flag" color="primary" class="cursor-pointer" @click="payoutTakeOffOpen = true"></v-btn>
					<v-btn v-else :loading="takeLoading" density="compact" variant="plain" icon="mdi-flag" color="grey" class="cursor-pointer" @click="toggleReviewer"></v-btn>
					<span v-if="info.finance_reviewer" class="text-body-2 ml-1">{{ info.finance_reviewer.full_name }}</span>
				</div>
				<div v-if="info.payout_id" class="text-grey">ID: {{ info.payout_id }}</div>
			</v-col>
			<v-col cols="auto" class="mt-n3 mr-n4">
				<v-btn :disabled="info.finance_reviewer === null || (info.finance_reviewer.id !== userInfo.id && info.finance_review_result === 'review')" icon="mdi-pencil" variant="text" @click="$emit('edit', info)"></v-btn>
			</v-col>
		</v-row>

		<v-row justify="space-between">
			<v-col :cols="mdAndDown ? 12 : 4" style="max-width: 280px">
				<table class="text-body-2 w-100 table">
					<tbody>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Creation date:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span
									class="cursor-pointer"
									@click.stop="copyField(new Date(info.payout_creation_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'}))"
							>
								{{ new Date(info.payout_creation_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'}) }}
								<v-tooltip activator="parent" location="top">Copy field</v-tooltip>
							</span>
						</td>
					</tr>
					</tbody>
				</table>
			</v-col>
			<v-col :cols="mdAndDown ? 12 : 4" style="max-width: 280px">
				<table class="text-body-2 w-100 table">
					<tbody>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Model username:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.created_by)">
								{{ info.only_fans_model?.nickname}}
								<v-tooltip activator="parent" location="top">Copy field</v-tooltip>
							</span>
						</td>
					</tr>
					</tbody>
				</table>
			</v-col>
			<v-col :cols="mdAndDown ? 12 : 4" style="max-width: 280px">
				<table class="text-body-2 w-100 table">
					<tbody>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Amount:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.amount)">{{ info.amount }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					</tbody>
				</table>
			</v-col>
		</v-row>

		<v-row class="mt-8" align="end" justify="end" no-gutters>
			<v-col cols="auto" class="d-flex flex-column align-end" :class="mdAndDown ? 'mt-4' : ''">
				<div v-if="info.finance_review_result === 'review'" class="d-flex">
					<v-btn
							:disabled="info.finance_reviewer === null || (info.finance_reviewer && info.finance_reviewer.id !== userInfo.id)"
							variant="outlined"
							color="primary"
							@click="payoutCancelOpen = true"
					>
						Cancel
					</v-btn>
					<v-btn
							:disabled="info.finance_reviewer === null || (info.finance_reviewer && info.finance_reviewer.id !== userInfo.id)"
							class="ml-6"
							color="primary"
							@click="payoutConfirmOpen = true"
					>
						Payout
					</v-btn>
				</div>
				<div v-if="info.finance_review_result === 'accept'" class="d-flex flex-column align-end">
					<div class="d-flex align-center text-body-2 text-grey"><v-icon size="16" color="success">mdi-check-circle</v-icon><span class="pl-1">Provided by {{ info.financier }}</span></div>
					<div class="text-body-2 text-grey">{{ info.payout_address.address_name}}</div>
					<div class="text-body-2 text-grey">{{ new Date(info.finance_review_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) }}</div>
				</div>
				<div v-if="info.finance_review_result === 'reject'" class="d-flex flex-column align-end">
					<div class="d-flex align-center text-body-2 text-grey"><v-icon size="16" color="error">mdi-alert-circle</v-icon><span class="pl-1">Canceled by {{ info.financier }}</span></div>
					<div class="text-body-2 text-grey">{{ new Date(info.finance_review_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) }}</div>
				</div>
			</v-col>
		</v-row>

		<v-dialog v-model="payoutTakeOffOpen" width="300">
			<v-card :disabled="takeLoading" class="pa-4">
				<div class="text-body-1 mb-2">Cancel processing</div>
				<div class="text-subtitle-2">Are you sure?</div>
				<v-row justify="end" no-gutters class="mt-6 pa-0">
					<v-btn class="mr-2" variant="text" @click="payoutTakeOffOpen = false">No</v-btn>
					<v-btn color="primary" :loading="takeLoading" :disabled="takeLoading" @click="toggleReviewer">Agree</v-btn>
				</v-row>
			</v-card>
		</v-dialog>

		<v-dialog v-model="payoutCancelOpen" width="300">
			<v-card :disabled="payoutCancelLoading" class="pa-4">
				<div class="text-body-1 mb-2">Cancel payout</div>
				<div class="text-subtitle-2">Are you sure?</div>
				<v-row justify="end" no-gutters class="mt-6 pa-0">
					<v-btn class="mr-2" variant="text" @click="payoutCancelOpen = false">No</v-btn>
					<v-btn color="primary" :loading="payoutCancelLoading" :disabled="payoutCancelLoading" @click="handlePayoutCancel">Agree</v-btn>
				</v-row>
			</v-card>
		</v-dialog>

		<v-dialog v-model="payoutConfirmOpen" persistent width="600">
			<PayoutConfirmDialog
					@close="payoutConfirmOpen = false"
					:info="info"
					@accept="handleAccept"
			></PayoutConfirmDialog>
		</v-dialog>

	</v-card>
</template>

<style scoped>
.table td {
	vertical-align: top;
}
</style>