import axios from "@/services/axios-instance";

export default {
  async getShifts() {
    const response = await axios.get('/v1/shifts/');
    return response.data
  },
  async addShift(data) {
    const response = await axios.post('/v1/shifts/', data);
    return response.data
  },
  async removeShift(id) {
    await axios.delete(`/v1/shifts/${id}/`);
  },
  async startShift(id) {
    await axios.post(`/v1/shifts/${id}/start-shift/`);
  },
  async endShift(id) {
    const response = await axios.post(`/v1/shifts/${id}/end-shift/`);
    return response.data
  },
  async updateShift(data, id) {
    const response = await axios.patch(`/v1/shifts/${id}/`, data);
    return response.data
  },
  async getShiftsHistory(page = 1) {
    const response = await axios.get('/v1/shifts/history/', { params: { page }});
    return response.data
  },
  async getShiftsHistoryTotal(data) {
    const response = await axios.get('/v1/shifts/history/total/', { params: data });
    return response.data
  },
}