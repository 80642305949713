<template>
	<v-card class="pa-6">
		<div class="text-h5 mb-8">{{ formTitle }}</div>
		<v-form v-model="form" @submit.prevent>
			<v-row class="flex-wrap">
				<v-col cols="12">
					<div class="text-body-1 mb-2">Модель</div>
					<v-text-field
							v-if="(edited !== -1)"
							disabled
							readonly
							variant="outlined"
							placeholder="Our model"
							:model-value="info.only_fans_model.nickname"
					></v-text-field>
					<SelectModel
							v-else
							v-model="fields.model"
							:items="models"
							:rules="[rules.required]"
							:no-data-text="noDataText"
					/>
				</v-col>
				<v-col cols="12">
					<div class="text-body-1 mb-2">Бюджет</div>
					<v-text-field
							v-model="fields.cost_plan"
							variant="outlined"
							placeholder="Бюджет"
							type="number"
							prepend-inner-icon="mdi-currency-usd"
							:rules="[rules.required, rules.notNull]"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row justify="end" no-gutters class="mt-4 pa-0">
				<v-btn variant="text" @click="$emit('close')" class="mr-2">Отмена</v-btn>
				<v-btn v-if="(edited !== -1)" :loading="formLoading" type="submit" color="primary" @click="editPlanModel">Сохранить</v-btn>
				<v-btn v-else :loading="formLoading" type="submit" color="primary" @click="addPlanModel">Добавить</v-btn>
			</v-row>
		</v-form>
	</v-card>
</template>

<script>
import apiBudget from '@/api/budget';
import SelectModel from '@/components/SelectModel.vue';
import { mapState } from 'vuex';

export default {
	name: 'BudgetModelEditDialog',

	components: {
		SelectModel,
	},


	props: {
		edited: {},
		info: {},
		type: {},
		period: {},
		selected: {}
	},


	data() {
		return {
			rules: {
				required: value => {
					if (value === []) return 'Обязательное поле';
					if (value) return true;
					return 'Обязательное поле'
				},
				notNull: value => {
					if (value === 0 || value === '0') return 'Введите значение больше нуля';
				},
			},
			form: false,
			formLoading: false,
			fields: {
				id: '',
				model: null,
				cost_plan: '',
			},
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
		formTitle () {
			return this.edited === -1 ? 'Добавить модель' : 'Редактировать бюджет модели'
		},
		models() {
			let models = structuredClone(this.commonListModels);
			this.selected.forEach(target => {
				models.forEach((model, index) => {
					if (target === model.nickname) {
						models.splice(index, 1)
					}
				})
			})
			let activeModels = models.filter(elem => elem.marketer);
			return activeModels
		},
		noDataText() {
			if (this.commonListModels.length === 0) {
				return 'Список моделей пуст'
			} else if (this.models.length === 0) {
				return 'Все модели добавлены'
			} else {
				return ''
			}
		},
	},


	created() {
		if (this.edited !== -1) {
			this.fields.model = this.info.only_fans_model.id;
			this.fields.cost_plan = this.info.cost_plan;
		}
	},


	methods: {
		async addPlanModel() {
			if (!this.form) return
			try {
				this.formLoading = true;
				const response = await apiBudget.addPlanModel({
					cost_plan: this.fields.cost_plan,
					only_fans_model: this.fields.model,
					marketing_budget_period: this.period,
					promo_type: this.type
				});
				this.$emit('add', response)
				this.$emit('close')
			} catch (error) {
				console.log('add model error: ', error)
			} finally {
				this.formLoading = false;
			}
		},

		async editPlanModel() {
			if (!this.form) return
			try {
				this.formLoading = true;
				const response = await apiBudget.editPlanModel(this.info.id, {
					cost_plan: this.fields.cost_plan,
				});
				this.$emit('edit', response)
				this.$emit('close')
			} catch (error) {
				console.log('update model error: ', error);
			} finally {
				this.formLoading = false;
			}
		},

	}
}
</script>

<style scoped>

</style>