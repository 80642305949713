<script>
import apiTeams from "@/api/teams";

export default {
	name: "ScheduleItem",


	props: {
		info: {
			type: Object,
			default() {
				return {}
			}
		},
		edit: {
			type: Boolean,
			default() {
				return false
			}
		},
		operatorsList: {
			type: Array,
			default() {
				return []
			}
		},
		shiftNumbersList: {
			type: Array,
			default() {
				return []
			}
		}
	},


	data() {
		return {
			operator: null,
			shift_number: null,
			replacement: null,
			errors: {},
		}
	},


	created() {
		if (this.info.operator) this.operator = this.info.operator.id;
		if (this.info.shift_number) this.shift_number = this.info.shift_number.id;
		if (this.info.replacement) this.replacement = this.info.replacement.id;
	},


	watch: {
		edit(value) {
			if (!value) {
				this.errors = {};
				this.info.operator ? this.operator = this.info.operator.id : this.operator = null;
				this.info.shift_number ? this.shift_number = this.info.shift_number.id : this.shift_number = null;
				this.info.replacement ? this.replacement = this.info.replacement.id : this.replacement = null;
			}
		}
	},


	methods: {
		async handleFieldEdit(value, src) {
			const data = {};
			data[src] = value;

			if (!this.info.id) {
				data.shift_index = this.info.shift_index.id
			}

			if (this.info.id) {
				try {
					const response = await apiTeams.patchField(this.info.id, data);
					this.$emit('patch', response)
				} catch (error) {
					const errors = Object.keys(error.response.data);
					errors.forEach((key) => {
						this.errors[key] = error.response.data[key]
					});
				}
			} else {
				try {
					data.only_fans_model = this.info.only_fans_model.id;
					const response = await apiTeams.createScheduleItem(data);
					this.$emit('patch', response)
				} catch (error) {
					const errors = Object.keys(error.response.data);
					errors.forEach((key) => {
						this.errors[key] = error.response.data[key]
					});
				}
			}
		},
	}
}
</script>

<template>
	<div class="mb-6">
		<div class="text-h6 mb-2">{{ info.shift_index.number }} shift</div>
		<v-row>
			<v-col cols="4">
				<div class="text-subtitle-2 mb-2 font-weight-light">Operator:</div>
				<v-autocomplete
						v-if="edit"
						v-model="operator"
						:items="operatorsList"
						item-title="full_name"
						item-value="id"
						variant="outlined"
						density="compact"
						placeholder="Select operator"
						hide-details="auto"
						@update:modelValue="(value) => handleFieldEdit(value, 'operator')"
						:error-messages="errors.operator"
				>
					<template #item="{ props, item }">
						<v-list-subheader v-if="props.header">
							{{ props.header }}
						</v-list-subheader>
						<v-divider v-else-if="props.divider" />
						<v-list-item v-else v-bind="props" :title="item.title"></v-list-item>
					</template>
				</v-autocomplete>
				<div v-else class="text-body-1">{{ info.operator ? info.operator.full_name : '—' }}</div>
			</v-col>
			<v-col cols="4">
				<div class="text-subtitle-2 mb-2 font-weight-light">Shift number:</div>
				<v-select
						v-if="edit"
						v-model="shift_number"
						:items="shiftNumbersList.filter((item) => item.index.number === info.shift_index.number)"
						item-title="name"
						item-value="id"
						variant="outlined"
						density="compact"
						placeholder="Select shift"
						hide-details
						@update:modelValue="(value) => handleFieldEdit(value, 'shift_number')"
						:error-messages="errors.shift_number"
				></v-select>
				<div v-else class="text-body-1">{{ info.shift_number ? info.shift_number.name : '—' }}</div>
			</v-col>
			<v-col cols="4">
				<div class="text-subtitle-2 mb-2 font-weight-light">Replacement:</div>
				<v-autocomplete
						v-if="edit"
						v-model="replacement"
						:items="operatorsList"
						item-title="full_name"
						item-value="id"
						variant="outlined"
						density="compact"
						placeholder="Select operator"
						hide-details
						@update:modelValue="(value) => handleFieldEdit(value, 'replacement')"
						:error-messages="errors.replacement"
				>
					<template #item="{ props, item }">
						<v-list-subheader v-if="props.header">
							{{ props.header }}
						</v-list-subheader>
						<v-divider v-else-if="props.divider" />
						<v-list-item v-else v-bind="props" :title="item.title"></v-list-item>
					</template>
				</v-autocomplete>
				<div v-else class="text-body-1">{{ info.replacement ? info.replacement.full_name : '—' }}</div>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>

</style>