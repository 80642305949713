<template>
    <v-card>
        <v-card-title class="px-6 pt-4 pb-6">
            <span class="text-h5">Редактирование смены</span>
        </v-card-title>
        <v-card-text class="px-6 py-0">
            <div class="text-subtitle-1 mb-4">{{ formatDate }}</div>
            <v-row no-gutters>
                <v-col class="pr-4">
                    <v-autocomplete
                            label="Оператор"
                            variant="outlined"
                            item-title="full_name"
                            item-value="id"
                            v-model="operatorSelected"
                            :items="operatorsList"
                            :rules="[rules.required]"
                    >
                        <template #item="data">
                            <v-list-subheader v-if="data.props.header">
                                {{ data.props.header }}
                            </v-list-subheader>
                            <v-divider v-else-if="data.props.divider" />
                            <v-list-item v-else v-bind="data.props"></v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="pl-4">
                    <SelectModel
                            v-model="modelSelected"
														:items="modelsList"
														:rules="[rules.required, rules.limited]"
														multiple
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-end px-6 pb-2 pt-6">
            <v-btn color="black" @click="$emit('close')">Отмена</v-btn>
            <v-btn color="primary" @click="handleUpdateShift">Сохранить</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
  import { toast } from "vuetify-sonner"
  import {mapState} from 'vuex';
  import SelectModel from '@/components/SelectModel.vue';

  export default {
    name: 'ShiftEdit',


    components: {
      SelectModel,
    },


    props: {
      info: {
        type: Object,
      }
    },


    data() {
      return {
        rules: {
          required: value => {
            if (value === []) return 'Обязательное поле';
            if (value) return true;
            return 'Обязательное поле'
          },
          limited: value => {
            if (value.length > 3) {
              value.pop()
            }
          },
        },
        operatorsList: [],
        operatorSelected: null,
				modelsList: [],
        modelSelected: [],
      }
    },


    computed: {
      ...mapState({
        userLoaded: state => state.user.userLoaded,
        myGroupLoaded: state => state.user.myGroupLoaded,
      }),
      formatDate() {
        const dateElements = this.info.shift_date.split('-');
        return `${dateElements[2]}.${dateElements[1]}.${dateElements[0]}`;
      }
    },


    created() {
      if (this.myGroupLoaded && this.userLoaded) {
        this.getOperators();
        this.initData();
				this.getModels();
      }
    },


    watch: {
      userLoaded() {
        if (this.myGroupLoaded && this.userLoaded) {
          this.getOperators();
          this.initData();
					this.getModels();
        }
      },
      myGroupLoaded() {
        if (this.myGroupLoaded && this.userLoaded) {
          this.getOperators();
          this.initData();
					this.getModels();
        }
      },
    },


    methods: {
      getOperators() {
        this.operatorsList = this.$store.getters['user/getOperators']
      },
			getModels() {
				this.modelsList = this.$store.getters['user/getModels'];
			},
      initData() {
        this.operatorSelected = this.info.operator.id;
        let activeModels = this.info.shift_info.models.filter(elem => !elem.ended);
        this.modelSelected = Array.from(new Set(activeModels.map(el => el.id )));
      },
      async handleUpdateShift() {
        const data = {
          operator: this.operatorSelected,
          only_fans_models: this.modelSelected,
        };
        try {
          await this.$store.dispatch('shifts/updateShift', {data, id: this.info.id});
          this.$emit('close')
        } catch (error) {
          toast('Ошибка при редактировании смены:', {
            duration: 20000,
            description: `${JSON.stringify(error.response.data)}`,
            cardProps: {
              color: 'error',
            },
          });
        }
      }
    }
  }
</script>

<style scoped>

</style>