<script>
import FeedList from "@/components/feed/FeedList.vue";
import FeedFilter from "@/components/feed/FeedFilter.vue";

export default {
	name: "FeedContainer",


	components: {
		FeedFilter,
		FeedList
	}
}
</script>

<template>
	<v-row>
		<v-col cols="9">
			<FeedList />
			<div class="d-flex justify-center mt-8">
				<v-btn color="primary" size="large">Show more</v-btn>
			</div>
		</v-col>
		<v-col cols="3">
			<FeedFilter />
		</v-col>
	</v-row>
</template>

<style scoped>

</style>