<script>
  export default {
    name: "CalendarPage",


    data() {
      return {
        tab: 'common',
      }
    },


    created() {
      if (this.$route.meta.title === 'Calendar') {
        this.$router.push({ name: 'CalendarCommon' })
      }
    }
  }
</script>

<template>
    <div class="page-container pt-4 pb-10">
        <div class="page-wrap">

            <h1 class="text-h4 mb-8">Calendar</h1>

            <v-tabs
                    class="mb-3"
                    v-model="tab"
                    color="primary"
            >
                <v-tab :to="({ name: 'CalendarCommon' })">All events</v-tab>
                <v-tab :to="({ name: 'CalendarPersonal' })">Personal</v-tab>
            </v-tabs>

            <RouterView/>

        </div>
    </div>
</template>

<style scoped>

</style>