import { createRouter, createWebHistory } from "vue-router";

//app
import AuthPage from '@/pages/AuthPage.vue';
import ErrorPage from '@/pages/ErrorPage';
import NotFoundPage from "@/pages/NotFoundPage";
// import TestPage from '@/pages/TestPage.vue';
// shifts
import AppMain from '@/components/AppMain.vue';
import Dashboard from '@/pages/MainPage.vue';
import ShiftsPage from '@/pages/ShiftsPage';
import ShiftHistoryPage from '@/pages/ShiftHistoryPage';
import MyTeamsPage from '@/pages/MyTeamsPage.vue';
import OperatorsPage from '@/pages/OperatorsPage';
import ModelsPage from '@/pages/ModelsPage';
// marketing
import ModelsPlanPage from '@/pages/ModelsPlanPage';
import PivotTablePage from '@/pages/PivotTablePage';
import PivotTableModelPage from '@/pages/PivotTableModelPage.vue';
import BlacklistPage from '@/pages/BlacklistPage.vue';
import RomiPage from '@/pages/RomiPage.vue';
import BudgetMarketingPage from '@/pages/BudgetMarketingPage.vue';
import MarketersPage from '@/pages/MarketersPage.vue';
// financer
import TotalSpendPage from '@/pages/TotalSpendPage.vue';
import ReceivablePage from '@/pages/ReceivablePage.vue';
import AdsPage from '@/pages/AdsPage.vue';
import ForecastPage from '@/pages/ForecastPage.vue';
import PaymentsPage from '@/pages/PaymentsPage.vue';
import PayoutsPage from '@/pages/PayoutsPage.vue';
import WeekDebtPage from '@/pages/WeekDebtPage.vue';
// smm
import TrackingPage from '@/pages/TrackingPage.vue';
import TrackingDetailPage from "@/pages/TrackingDetailPage.vue";
import TrackingHistoryPage from "@/pages/TrackingHistoryPage.vue";
// researcher
import DonorsCheckerPage from "@/pages/DonorsCheckerPage.vue";
// managers
import ManagersPage from "@/pages/ManagersPage.vue";
import DatabookPage from "@/pages/DatabookPage.vue";
import DatabookDetailPage from "@/pages/DatabookDetailPage.vue";
import CalendarPage from "@/pages/CalendarPage.vue";
import CalendarCommonPage from "@/pages/CalendarCommonPage.vue";
import CalendarPersonalPage from "@/pages/CalendarPersonalPage.vue";
import FeedPage from "@/pages/FeedPage.vue";

// import store from './store'

const errorPages = {
  // '401': {
  //   name: 'errorPage',
  //   query: {
  //     status: 401,
  //     message: 'Вы не авторизованы'
  //   }
  // },
  '403': {
    name: 'errorPage',
    query: {
      status: 403,
      message: 'You have no permission to watch this page'
    }
  },
};

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage,
    meta: { requiresAnon: true }
  },
  {
    path: '/',
    name: 'App',
    component: AppMain,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: 'shifts-history',
        name: 'ShiftsHistory',
        component: ShiftHistoryPage,
        meta: {
          title: 'Shifts history',
          rule_view_shifts_history: true
        }
      },
      {
        path: 'shifts',
        name: 'Shifts',
        component: ShiftsPage,
        meta: {
          title: 'Shifts',
          rule_view_shifts: true
        }
      },
      {
        path: 'teams',
        name: 'MyTeams',
        component: MyTeamsPage,
        meta: {
          title: 'My teams',
          rule_view_my_teams: true
        }
      },
      {
        path: 'operators',
        name: 'Operators',
        component: OperatorsPage,
        meta: {
          title: 'Operators',
          rule_view_operators: true,
        }
      },
      {
        path: 'models',
        name: 'Models',
        component: ModelsPage,
        meta: {
          title: 'Models',
          rule_view_models: true,
        }
      },
      {
        path: 'models-plan',
        name: 'ModelsPlan',
        component: ModelsPlanPage,
        meta: {
          title: 'Models Plan',
          rule_view_models_plan: true,
        }
      },
      {
        path: 'pivot-table',
        name: 'PivotTable',
        component: PivotTablePage,
        meta: {
          title: 'Pivot Table',
          rule_view_pivot_table: true,
        }
      },
      {
        path: 'black-list',
        name: 'BlackList',
        component: BlacklistPage,
        meta: {
          title: 'Black list',
          rule_view_black_list: true,
        }
      },
      {
        path: 'pivot-table-by-model',
        name: 'PivotTableByModel',
        component: PivotTableModelPage,
        meta: {
          title: 'Pivot Table By Model',
          rule_view_pivot_table_by_model: true,
        }
      },
      {
        path: 'romi',
        name: 'Romi',
        component: RomiPage,
        meta: {
          title: 'ROMI',
          rule_view_romi: true
        }
      },
      {
        path: 'budget-marketing',
        name: 'BudgetMarketing',
        component: BudgetMarketingPage,
        meta: {
          title: 'Budget Marketing',
          rule_view_budget_marketing: true
        }
      },
      {
        path: 'marketers',
        name: 'Marketers',
        component: MarketersPage,
        meta: {
          title: 'Marketers',
          rule_view_marketers: true
        }
      },
      {
        path: 'total-spend',
        name: 'TotalSpend',
        component: TotalSpendPage,
        meta: {
          title: 'Total Spend',
          rule_view_total_spend: true
        }
      },
      {
        path: 'receivable',
        name: 'Receivable',
        component: ReceivablePage,
        meta: {
          title: 'Receivable',
          rule_view_receivable: true
        }
      },
      {
        path: 'promos',
        name: 'Ads',
        component: AdsPage,
        meta: {
          title: 'Ads',
          rule_view_ads: true
        }
      },
      {
        path: 'forecast',
        name: 'Forecast',
        component: ForecastPage,
        meta: {
          title: 'Forecast',
          rule_view_forecast: true,
        }
      },
      {
        path: 'payments',
        name: 'Payments',
        component: PaymentsPage,
        meta: {
          title: 'Payments',
          rule_view_payments: true,
        }
      },
      {
        path: 'payouts',
        name: 'Payouts',
        component: PayoutsPage,
        meta: {
          title: 'Payouts',
          rule_view_payouts: true,
        }
      },
      {
        path: 'week-debt',
        name: 'WeekDebt',
        component: WeekDebtPage,
        meta: {
          title: 'Week Debt',
          rule_view_week_debt: true,
        }
      },
      {
        path: 'tracking',
        name: 'Tracking',
        component: TrackingPage,
        meta: {
          title: 'Tracking List',
          rule_view_tracking: true,
        },
      },
      {
        path: 'tracking/:id',
        name: 'TrackingDetail',
        component: TrackingDetailPage,
        props: true,
        meta: {
          title: 'Promo detail',
          rule_view_tracking: true,
          parent: 'tracking'
        }
      },
      {
        path: 'tracking-history',
        name: 'TrackingHistory',
        component: TrackingHistoryPage,
        props: true,
        meta: {
          title: 'Tracking History',
          rule_view_tracking: true,
        }
      },
      {
        path: 'tracking-history/:id',
        name: 'TrackingHistoryDetail',
        component: TrackingDetailPage,
        props: true,
        meta: {
          title: 'Promo detail',
          rule_view_tracking: true,
          parent: 'tracking-history'
        }
      },
      {
        path: 'donors-checker',
        name: 'DonorsChecker',
        component: DonorsCheckerPage,
        meta: {
          title: 'Donors Checker',
          // rule_view_donors_checker: true,
        }
      },
      {
        path: 'managers',
        name: 'Managers',
        component: ManagersPage,
        meta: {
          title: 'Managers',
          rule_view_managers: true,
        }
      },
      {
        path: 'databook',
        name: 'Databook',
        component: DatabookPage,
        meta: {
          title: 'Databook',
          rule_view_databook: true
        }
      },
      {
        path: 'databook/:id',
        name: 'DatabookDetail',
        component: DatabookDetailPage,
        props: true,
        meta: {
          title: 'Databook',
          rule_view_databook_detail: true,
          parent: 'databook'
        }
      },
      {
        path: 'calendar',
        name: 'CalendarWrap',
        component: CalendarPage,
        meta: {
          title: 'Calendar',
          rule_view_calendar: true,
        },
        children: [
          {
            path: 'common',
            name: 'CalendarCommon',
            component: CalendarCommonPage,
            meta: {
              title: 'common',
            },
          },
          {
            path: 'personal',
            name: 'CalendarPersonal',
            component: CalendarPersonalPage,
            meta: {
              title: 'personal',
            },
          },
        ]
      },
      {
        path: 'feed',
        name: 'Feed',
        component: FeedPage,
        meta: {
          title: 'Feed'
        }
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFoundPage,
  },
  {
    path: '/error',
    name: 'errorPage',
    component: ErrorPage,
    props: true,
  },
];

const router = new createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // check auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const authStatus = JSON.parse(localStorage.getItem('authenticated'));

    if (!authStatus) {
      router.push({ name: 'Auth' })
    }

    // check rights
    else if (to.matched.some(record => record.meta.rule_view_shifts_history)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'team_lead' || user.role === 'senior_operator' || user.role === 'spectator') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_shifts)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'spectator') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_my_teams)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'team_lead' || user.role === 'senior_operator') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_operators)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'team_lead' || user.role === 'senior_operator') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_models)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'team_lead' || user.role === 'senior_operator') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_models_plan)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_ads)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'hof' || user.role === 'financier') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_pivot_table)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'hom' || user.role === 'marketer' || user.role === 'hobd' || user.role === 'business_dev') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_pivot_table_by_model)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'hom' || user.role === 'marketer' || user.role === 'hobd' || user.role === 'business_dev') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_black_list)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'hom' || user.role === 'marketer' || user.role === 'hobd' || user.role === 'business_dev') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_romi)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'hom' || user.role === 'marketer' || user.role === 'hobd' || user.role === 'business_dev') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_budget_marketing)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'superuser' || user.role === 'hom' || user.role === 'marketer') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_marketers)) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'hom') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_total_spend)) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'hof' || user.role === 'financier') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_receivable)) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'hof' || user.role === 'financier' || user.role === 'superuser' || user.role === 'marketer' || user.role === 'hom') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_forecast)) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'hof' || user.role === 'financier' || user.role === 'superuser' || user.role === 'marketer' || user.role === 'hom') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_payments)) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'hof' || user.role === 'financier') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_payouts)) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'hof' || user.role === 'financier') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_week_debt)) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'superuser' || user.role === 'hof' || user.role === 'financier' || user.role === 'marketer' || user.role === 'hom') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_tracking)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'marketer' || user.role === 'hom' || user.role === 'smm' || user.role === 'smm_of' || user.role === 'hobd' || user.role === 'business_dev') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_donors_checker)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'researcher') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_managers)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'hocm') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_databook)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'hocm' || user.role === 'client_manager') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_databook_detail)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'hocm' || user.role === 'client_manager') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else if (to.matched.some(record => record.meta.rule_view_calendar)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.role === 'hocm' || user.role === 'client_manager') {
        next()
      } else {
        next(errorPages['403'])
      }
    }

    else {
      next()
    }

    // redirect to total spend from '/' for financiers
    if (to.matched.some(record => record.name === 'Dashboard')) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.role === 'hof' || user.role === 'financier' ) {
        router.push({ name: 'Receivable' })
      }
      if (user.role === 'smm' || user.role === 'smm_of') {
        router.push({ name: 'Tracking' })
      }
      if (user.role === 'researcher') {
        router.push({ name: 'DonorsChecker' })
      }
      if (user.role === 'hocm') {
        router.push({ name: 'Managers' })
      }
      if (user.role === 'client_manager') {
        router.push({ name: 'Databook' })
      }
      if (user.role === 'spectator') {
        router.push({ name: 'Shifts' })
      }
    }
  // check unauth
  } else if (to.matched.some(record => record.meta.requiresAnon)) {
    const authStatus = JSON.parse(localStorage.getItem('authenticated'));
    if (authStatus) {
      router.push({ name: 'Dashboard' })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router