<script>
export default {
	name: "FeedFilter",


	data() {
		return {
			format: 'dd.MM.yyyy',
			date: new Date(),
		}
	},

	methods: {

	}
}
</script>

<template>
	<v-card class="pa-4" rounded="lg" elevation="0">
		<div class="text-subtitle-1 font-weight-bold mb-3">Filters</div>
		<v-row>
			<v-col cols="12">
				<VueDatePicker
						position="right"
						class="clearable-false"
						v-model="date"
						auto-apply
						:format="format"
						clearable
				/>
			</v-col>
			<v-col cols="12">
				<v-autocomplete
						label="Model"
						variant="outlined"
						hide-details
				></v-autocomplete>
			</v-col>
			<v-col cols="12">
				<v-autocomplete
						label="Client manager"
						variant="outlined"
						hide-details
				>
				</v-autocomplete>
			</v-col>
		</v-row>
	</v-card>
</template>

<style scoped>

</style>