<script>
import apiAds from "@/api/ads"
export default {
	name: "DonorsCheckerPage",


	data() {
		return {
			pageTitle: 'Donors checker',
			infoLoading: false,
			infoLoaded: false,
			donorName: '',
			result: null,
		}
	},


	watch: {
		donorName() {
			if (this.donorName.length > 0) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.checkDonor()
				}, 400);
			}
		}
	},


	methods: {
		async checkDonor() {
			try {
				this.infoLoading = true;
				this.infoLoaded = false;
				let name = null;
				if (this.donorName.charAt() === '@') {
					name = this.donorName.slice(1);
				} else {
					name = this.donorName
				}
				const response = await apiAds.checkDonor(name);
				this.result = response.exists;
				this.infoLoading = false;
				this.infoLoaded = true;
			} catch (error) {
				console.log(error)
			}
		}
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>

			<v-card class="px-5 py-4 rounded-lg">
				<div class="text-h6 mb-8">Put the name</div>
				<v-text-field
						placeholder="@Donorname"
						variant="outlined"
						v-model="donorName"
				></v-text-field>
				<template v-if="donorName">
					<div v-if="infoLoaded">
						<div v-if="result" class="d-flex align-center"><v-icon size="24" color="error">mdi-alert-circle</v-icon><span class="text-h6 ml-2">Has been found</span></div>
						<div v-else class="d-flex align-center"><v-icon size="24" color="success">mdi-check-circle</v-icon><span class="text-h6 ml-2">Not found</span></div>
					</div>
					<div v-else>
						<v-progress-circular color="primary" indeterminate></v-progress-circular>
					</div>
				</template>
			</v-card>

		</div>
	</div>
</template>

<style scoped>

</style>