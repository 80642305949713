<script>
import ForecastCard from '@/components/ForecastCard.vue'
import ForecastTableDialog from "@/components/ForecastTableDialog.vue";
import apiFinance from "@/api/finance";

export default {
	name: "ForecastPage",


	components: {
		ForecastTableDialog,
		ForecastCard
	},


	data() {
		return {
			search: '',
			dialog: false,
			ordering: '-count_active_promo',
			orderList: [
				{ name: 'Active promo', value: 'count_active_promo' },
				{ name: 'Fans flow per day', value: 'fans_per_day' },
				{ name: 'Forecast of days', value: 'forecast_of_days' },
			],
			searchItem: [],
			items: [],
			itemsLoading: false,
			itemsLoaded: false,
		}
	},


	computed: {
		filteredItems() {
			return this.searchItem.filter((item) => {
				if (item.model.toLowerCase().match(this.search)) return true
			});
		},
	},


	created() {
		this.getForecast()
	},


	watch: {
		ordering() {
			this.sortList()
		},
	},


	methods: {
		async getForecast() {
			try {
				this.itemsLoading = true;
				this.itemsLoaded = false;
				this.items = await apiFinance.getForecast();
				this.searchItem = this.items;
				this.sortList();
				this.itemsLoading = false;
				this.itemsLoaded = true;
			} catch (error) {
				console.log(error)
			}
		},
		setOrdering(value) {
			if (this.ordering === '-' + value || this.ordering === value) {
				if (this.ordering === value) {
					this.ordering = '-' + value;
				} else {
					this.ordering = value;
				}
			} else {
				this.ordering = '-' + value;
			}
		},
		sortList() {
			if (this.ordering.charAt() === '-') {
				const optionName = this.ordering.slice(1);
				this.searchItem.sort((a, b) => a[optionName] > b[optionName] ? 1 : -1).reverse();
			} else {
				const optionName = this.ordering;
				this.searchItem.sort((a, b) => a[optionName] > b[optionName] ? 1 : -1);
			}
		}
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Forecast promo</h1>

			<v-row class="mb-2" align="center" justify="space-between">
				<v-col cols="auto">
					<v-row align="center">
						<v-col cols="auto">
							<v-btn variant="outlined" color="primary" @click="dialog = true">Table view</v-btn>
						</v-col>
						<v-col>
							<div class="d-flex align-center flex-wrap">
								<div class="text-no-wrap pr-2">Sort by:</div>
								<div
										v-for="(item, index) in orderList"
										:key="index"
										class="d-flex align-center px-2 py-1 cursor-pointer text-no-wrap text-subtitle-1 font-weight-bold"
										:class="(ordering === item.value || ordering === '-' + item.value) ? 'text-primary' : ''"
										@click="setOrdering(item.value)"
								>
									<span :class="(ordering === item.value || ordering === '-' + item.value) ? '' : 'pr-5'">{{ item.name }}</span>
									<v-icon size="20" v-if="ordering === item.value">mdi-chevron-up</v-icon>
									<v-icon size="20" v-if="ordering === '-' + item.value">mdi-chevron-down</v-icon>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="auto">
					<div style="max-width: 100%; width: 400px">
						<v-text-field
								v-model="search"
								variant="underlined"
								label="Search model name"
								append-inner-icon="mdi-magnify"
								density="compact"
								hide-details
						></v-text-field>
					</div>
				</v-col>
			</v-row>

			<div v-if="itemsLoaded">
				<ForecastCard
						v-for="(item, index) in filteredItems"
						:key="index"
						:info="item"
						:class="{'mb-6': index !== filteredItems.length - 1}"
				/>
			</div>

			<v-skeleton-loader v-else type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

			<v-dialog v-model="dialog" fullscreen>
				<ForecastTableDialog @close="dialog = false"/>
			</v-dialog>

		</div>
	</div>
</template>

<style scoped>

</style>