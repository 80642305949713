<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">
			<h1 class="text-h4 mb-8">Managers</h1>

			<v-data-table
					class="py-2 px-4 elevation-1"
					:loading="managersLoading"
					v-model:page.sync="page"
					v-model:items-per-page="itemsPerPage"
					:headers="headers"
					:items="managers"
					item-value="name"
					:search="search"
					@update:options="getManagers"
			>
				<template v-slot:top>
					<v-row class="mb-4" no-gutters align="end" justify="space-between">
						<v-col>
							<v-responsive max-width="400">
								<v-text-field
										v-model="search"
										append-inner-icon="mdi-magnify"
										label="Search"
										variant="underlined"
										hide-details
								></v-text-field>
							</v-responsive>
						</v-col>
						<v-col class="v-col-auto">
							<v-btn
									class="ml-4"
									prepend-icon="mdi-plus"
									color="primary"
									@click="dialog = true"
							>Create</v-btn>
						</v-col>
					</v-row>
				</template>
				<template v-slot:[`item.activity`]="{ item }">
					<v-row no-gutters justify="center">
						<v-col class="v-col-auto">
							<v-switch color="primary" :model-value="item.raw.is_active" @update:modelValue="(value) => handleSwitchManagerActivity(value, item.raw.id)"></v-switch>
						</v-col>
					</v-row>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon="mdi-pencil" variant="text" @click="editItem(item.raw)"></v-btn>
					<v-btn icon="mdi-delete" variant="text" @click="deleteItem(item.raw)"></v-btn>
				</template>
				<template v-slot:bottom></template>
			</v-data-table>

			<v-pagination variant="elevated" active-color="primary" size="default" density="comfortable" class="mt-4" v-model="page" :length="pageCount"></v-pagination>

			<v-dialog v-model="dialogDelete" max-width="400px">
				<v-card class="pa-4">
					<div class="text-body-1 mb-2">Delete manager</div>
					<div class="text-subtitle-2">Are you sure?</div>
					<v-row justify="end" no-gutters class="mt-6 pa-0">
						<v-btn class="mr-2" variant="text" @click="closeDelete">Cancel</v-btn>
						<v-btn color="primary" @click="deleteItemConfirm">Agree</v-btn>
					</v-row>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialog" fullscreen persistent transition="slide-x-transition" max-width="900px" class="right-position">
				<ManagerEditDialog
						@close="close"
						:edited="editedIndex"
						:info="editedItem"
				/>
			</v-dialog>

		</div>
	</div>
</template>

<script>
  import {mapState} from 'vuex'
	import ManagerEditDialog from "@/components/ManagerEditDialog.vue";

  export default {
    name: 'ManagersPage',


    components: {
			ManagerEditDialog,
    },


    data () {
      return {
        dialog: false,
        dialogDelete: false,
        search: '',
        page: 1,
        itemsPerPage: 10,
        loading: false,
        headers: [
          { title: 'Name', align: 'start', key: 'first_name' },
          { title: 'Surname', align: 'start', key: 'last_name' },
          { title: 'Email', align: 'start', key: 'email' },
          { title: 'Activity', align: 'center', key: 'activity', sortable: false },
          { title: 'Actions', align: 'end', key: 'actions', sortable: false },
        ],
        editedItem: {
          email: '',
          first_name: '',
          last_name: '',
          telegram_id: '',
          lead: '',
        },
        defaultItem: {
          email: '',
          first_name: '',
          last_name: '',
          telegram_id: '',
          lead: '',
        },
        editedIndex: -1,
      }
    },


    computed: {
      ...mapState({
        managers: state => state.managers.managers,
        managersLoading: state => state.managers.managersLoading,
        managersLoaded: state => state.managers.managersLoaded,
      }),
      pageCount () {
        return Math.ceil(this.managers.length / this.itemsPerPage)
      },
    },


    created() {},


    watch: {},


    methods: {
      getManagers() {
        this.$store.dispatch('managers/getManagers')
      },

      async handleSwitchManagerActivity(value, id) {
        const fields = {
          is_active: value
        };
        try {
          await this.$store.dispatch('managers/editManager', { fields, id: id});
        } catch (error) {
          console.log(error)
        }
      },

      editItem(item) {
        this.editedIndex = this.managers.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true
      },

      deleteItem(item) {
        this.editedIndex = this.managers.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true
      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        })
      },

      deleteItemConfirm() {
        this.$store.dispatch('managers/deleteManager', this.editedItem.id);
        this.closeDelete()
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        })
      },
    }
  }
</script>

<style scoped>

</style>