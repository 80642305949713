<script>
import FeedContainer from "@/components/feed/FeedContainer.vue";

export default {
	name: "FeedPage",


	components: {
		FeedContainer
	},
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Feed</h1>

			<FeedContainer />
		</div>
	</div>
</template>

<style scoped>

</style>