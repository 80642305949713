import axios from 'axios'
import store from '@/store'
import router from '@/router'

const instance = axios.create();

console.log('process.env: ', process.env);

const BASE_URL = process.env.VUE_APP_URL;
instance.interceptors.request.use(
  config => {
    config.baseURL = BASE_URL;
    if (localStorage.getItem('access_token')) {
      const token = localStorage.getItem('access_token');
      config.headers.authorization = `Bearer ${token}`
    }
    return config
  },

  error => {
    // console.log('error request: ', error)
    return error
  }
);

instance.interceptors.response.use(
  config => {
    config.baseURL = BASE_URL;
    if (localStorage.getItem('access_token')) {
      const token = localStorage.getItem('access_token');
      config.headers.authorization = `Bearer ${token}`;
    }
    return config
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401 && store.state.user.authenticated) {
        try {
          const payload = { refresh: localStorage.getItem('refresh_token') };

          const { data } = await axios.post(`${BASE_URL}/v1/token/refresh/`, payload);

          localStorage.setItem('access_token', data.access);
          localStorage.setItem('refresh_token', data.refresh);
          error.config.headers.authorization = `Bearer ${data.access}`;

          return instance(error.config)
        } catch (error) {
          store.dispatch('user/resetState');
          router.push({ name: 'Auth' })
        }
      }
    }

    return Promise.reject(error);
  }
);

export default instance