<template>
	<RouterView/>
</template>

<script>

  export default {
    name: 'App',

    components: {},

    data: () => ({
      //
    }),
  }
</script>

<style lang="less">
	@import "@/assets/style/variables.less";

	#app {
		/*min-height: 100vh;*/
		height: 100%;
		line-height: 1.2;
		color: @color-text;
		font-family: 'Roboto', sans-serif;
	}

	.page-container {
		padding: 0 14px;

		@media (min-width: 1280px) {
			padding: 0 32px;
		}
	}

	.right-position.v-dialog--fullscreen > .v-overlay__content {
		left: unset;
		right: 0;
	}

	.custom-shadow {
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.1) !important;
	}

	.height-screen {

	}

	.cursor-pointer {
		cursor: pointer;
	}

	.transition {
		transition: all .2s ease;
	}

	.transition-3 {
		transition: all .3s ease;
	}

	.transition-4 {
		transition: all .4s ease;
	}

	.w-fit {
		width: fit-content;
	}

	.text-overflow-ellipsis {
		text-overflow: ellipsis;
	}

	input[type='number'] {
		-moz-appearance:textfield;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
</style>