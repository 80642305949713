<template>
  <template
      v-if=
          "
            user.info.role === 'superuser' ||
            user.info.role === 'team_lead' ||
            user.info.role === 'senior_operator' ||
            user.info.role === 'operator'
          "
  >
		<div class="page-container pt-4 pb-10">
			<div class="page-wrap">
				<h1 class="text-h4 mb-8">Main page</h1>
				<LeadsChipsGroup v-if="user.info.role === 'superuser'"/>
				<TotalStatPanel v-if="user.info.role === 'superuser' || user.info.role === 'team_lead' || user.info.role === 'senior_operator'" class="mb-10"/>
				<ShiftAdd v-if="user.info.role === 'team_lead' || user.info.role === 'senior_operator'"/>
				<ShiftList />
				<ShiftListCompleted v-if="this.user.info.role && user.info.role !== 'superuser'"/>
			</div>
		</div>
	</template>
	<template
			v-if=
          "
            user.info.role === 'hom' ||
            user.info.role === 'marketer' ||
            user.info.role === 'hobd' ||
            user.info.role === 'business_dev'
          "
	>
		<div class="page-container pt-4 pb-10 fill-height">
			<MarketingView title="Главная страница"/>
		</div>
	</template>
</template>

<script>
  import { mapState } from 'vuex';
  import ShiftAdd from '@/components/ShiftAdd.vue';
  import ShiftList from '@/components/ShiftList.vue';
  import ShiftListCompleted from '@/components/ShiftListCompleted.vue';
  import TotalStatPanel from '@/components/TotalStatPanel.vue';
  import LeadsChipsGroup from '@/components/LeadsChipsGroup.vue';
  import MarketingView from '@/components/MarketingView.vue';

  export default {
    name: 'MainPage',


    components: {
      LeadsChipsGroup,
      TotalStatPanel,
      ShiftAdd,
      ShiftList,
      ShiftListCompleted,
      MarketingView,
    },


    data() {
      return {};
    },


    computed: {
      ...mapState({
        user: state => state.user,
      })
    },
  }
</script>

<style scoped>

</style>