<template>

		<v-card class="pa-6">
			<div class="text-h5 mb-8">{{ formTitle }}</div>
			<v-form :disabled="formLoading" @submit.prevent>
				<v-list-subheader class="mb-4">Personal information</v-list-subheader>
				<v-row>
					<v-col cols="4">
						<div class="text-body-1 mb-2">Email</div>
						<v-text-field
								variant="outlined"
								v-model="fields.email"
								placeholder="Enter email"
								type="email"
								:rules="[rules.required, rules.validateEmail]"
						></v-text-field>
					</v-col>
					<v-col cols="4">
						<div class="text-body-1 mb-2">Name</div>
						<v-text-field
								variant="outlined"
								v-model="fields.first_name"
								placeholder="Enter name"
								:rules="[rules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="4">
						<div class="text-body-1 mb-2">Surname</div>
						<v-text-field
								variant="outlined"
								v-model="fields.last_name"
								placeholder="Enter surname"
								:rules="[rules.required]"
						></v-text-field>
					</v-col>
				</v-row>
				<v-sheet max-width="500">
					<div class="text-body-1 mb-2">New password</div>
					<PasswordGenerator
							v-model="fields.password"
							placeholder="Enter new password"
							size="10"
							auto="false"
							:required-field="this.edited === -1"
					/>
				</v-sheet>
				<v-divider class="mt-10"></v-divider>
				<v-list-subheader class="mb-4">Telegram</v-list-subheader>
				<v-sheet max-width="400">
					<div class="text-body-1 mb-2">Telegram id</div>
					<v-text-field
							variant="outlined"
							v-model="fields.telegram_id"
							placeholder="Enter id"
							type="number"
					></v-text-field>
				</v-sheet>
				<v-divider class="mt-10"></v-divider>
				<v-list-subheader class="mb-4">Models</v-list-subheader>
				<v-autocomplete
						:items="commonListModels"
						:loading="!commonListModelsLoaded"
						:disabled="!commonListModelsLoaded"
						v-model="fields.only_fans_models"
						multiple
						placeholder="Models"
						variant="underlined"
						item-title="nickname"
						item-value="id"
						chips
						closable-chips
						:error-messages="errors.only_fans_models"
				>
					<template v-slot:chip="{ props, item }">
						<v-chip
								v-bind="props"
								:text="item.raw.name"
								size="large"
								color="primary"
						></v-chip>
					</template>
				</v-autocomplete>
				<v-row justify="end" no-gutters class="mt-6 pa-0">
					<v-btn variant="text" @click="$emit('close')">Cancel</v-btn>
					<v-btn v-if="(edited !== -1)" :loading="formLoading" type="submit" color="primary" @click="handleEditMarketer">Save</v-btn>
					<v-btn v-else :loading="formLoading" type="submit" color="primary" @click="handleAddMarketer">Create</v-btn>
				</v-row>
			</v-form>
		</v-card>

</template>

<script>
  import {mapState} from 'vuex'
  import PasswordGenerator from "@/components/PasswordGenerator";
	import {toast} from "vuetify-sonner";

  export default {
    name: 'MarketerEditDialog',


    components: {
      PasswordGenerator,
    },


    props: {
      edited: {},
      info: {},
    },


    data() {
      return {
        emailRegularExpression: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        rules: {
          required: value => {
            if (value) {
							return true
						} else {
							return 'Required field'
						}
          },
          validateEmail: (value) => {
            if (this.emailRegularExpression.test(value)) {
              return true
            } else {
              return 'Incorrect email'
            }
          },
        },
        fields: {
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          telegram_id: '',
					only_fans_models: []
        },
        formLoading: false,
        formLoaded: false,
				errors: {},
      }
    },


    computed: {
      ...mapState({
        userInfo: state => state.user.info,
				commonListModels: state => state.user.commonListModels,
				commonListModelsLoaded: state => state.user.commonListModelsLoaded,
      }),
      formTitle () {
        return this.edited === -1 ? 'New marketer' : 'Edit marketer'
      },
    },


    created() {
      if (this.edited !== -1) {
        this.fields.email = this.info.email;
        this.fields.first_name = this.info.first_name;
        this.fields.last_name = this.info.last_name;
        // this.password = this.info.password;
        this.fields.telegram_id = this.info.telegram_id;
        this.fields.only_fans_models = this.info.only_fans_models;
        this.fields.only_fans_models = Array.from(new Set(this.info.only_fans_models.map(el => el.id )));
      }
    },


    methods: {
      async handleAddMarketer() {
        this.formLoading = true;
        const fields = Object.assign({}, this.fields);
        if (this.fields.password.length === 0) {
          delete fields.password
        }
        if (this.fields.telegram_id.length === 0) {
          delete fields.telegram_id
        }
        try {
          await this.$store.dispatch('marketers/addMarketer', fields);
          this.$emit('close')
        } catch (error) {
          console.log(error)
        } finally {
          this.formLoading = false;
          this.formLoaded = true;
        }
      },
      async handleEditMarketer() {
        this.formLoading = true;
        const fields = Object.assign({}, this.fields);
        if (this.fields.password === null || this.fields.password.length === 0) {
          delete fields.password
        }
				if (this.fields.telegram_id === '') {
					fields.telegram_id = null
				}
        try {
          await this.$store.dispatch('marketers/editMarketer', {fields, id: this.info.id});
          this.$emit('close')
        } catch (error) {
          console.log(error)
					const errors = Object.keys(error.response.data);
					errors.forEach((key) => {
						this.errors[key] = error.response.data[key]
					});
					toast('Ошибка:', {
						duration: 5000,
						description: 'Не удалось сохранить изменения. Проверьте правильность заполнения полей.',
						cardProps: {
							color: 'error',
						},
					});
        } finally {
          this.formLoading = false;
          this.formLoaded = true;
        }
      },
    }
  }
</script>

<style scoped>

</style>