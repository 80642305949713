import axios from "@/services/axios-instance";

export default {
  async authorize({ email, password }) {
    const response = await axios.post('/v1/token/', {
      email: email,
      password: password
    });
    return response.data
  },

  async getUserData() {
    const response = await axios.get('/v1/users/me/');
    return response.data
  },

  async getMyGroupData() {
    const response = await axios.get('/v1/users/my-group/');
    return response.data
  },

  async getCommonListModels() {
    const response = await axios.get('/v1/only-fans-models/');
    return response.data
  },

  async editModel(data, id) {
    const response = await axios.patch(`/v1/only-fans-models/${id}/`, data);
    return response.data
  },

  async getTeamLeads() {
    const response = await axios.get('/v1/users/team-leads/');
    return response.data
  }
}
