// import axios from '@/services/axios-instance'
import api from '@/api/shifts'
import {
  SET_SHIFTS,
  SHIFTS_LOADING,
  SHIFTS_LOADED,
  ADD_SHIFT,
  REMOVE_SHIFT,
  START_SHIFT,
  END_SHIFT,
  UPDATE_SHIFT,
  SET_SHIFTS_HISTORY,
  SHIFTS_HISTORY_LOADING,
  SHIFTS_HISTORY_LOADED,
} from './constants'
import { RESET_STATE } from '@/store/constants'
import resetState from '@/store/helpers/resetState'

/*--------------------------Initial State------------------------------*/

export const initialState = {
  shifts: [],
  shiftsLoading: false,
  shiftsLoaded: false,
  shiftsHistory: {},
  shiftsHistoryLoading: false,
  shiftsHistoryLoaded: false,
  newHistoryItem: null,
};

/*----------------------------Mutations----------------------------*/

const mutations = {
  [SET_SHIFTS](state, data) {
    state.shifts = data
  },
  [SHIFTS_LOADING](state, value) {
    state.shiftsLoading = value
  },
  [SHIFTS_LOADED](state, value) {
    state.shiftsLoaded = value
  },
  [ADD_SHIFT](state, shift) {
    const target = state.shifts.find(item => item.id === shift.id);
    if (target === undefined) {
      state.shifts.push(shift)
    }
  },
  [REMOVE_SHIFT](state, id) {
    const index = state.shifts.findIndex(shift => shift.id === id);
    if (index !== -1) {
      state.shifts.splice(index, 1)
    }
  },
  [START_SHIFT](state, data) {
    const target = state.shifts.find(shift => shift.id === data.id);
    if(target) {
      target.status = 'progress';
    } else {
      state.shifts.push(data)
    }
  },
  [END_SHIFT](state, {id, response}) {
    const index = state.shifts.findIndex(shift => shift.id === id);
    if (index !== -1) {
      const newHistoryItem = state.shifts[index];
      newHistoryItem.duration = response.duration;
      state.newHistoryItem = newHistoryItem;
      state.shifts.splice(index, 1);
    }
  },
  [UPDATE_SHIFT](state, shift) {
    const index = state.shifts.findIndex(item => item.id === shift.id);
    if (index !== -1) {
      state.shifts[index] = shift
    }
  },
  [SET_SHIFTS_HISTORY](state, data) {
    state.shiftsHistory = data
  },
  [SHIFTS_HISTORY_LOADING](state, value) {
    state.shiftsHistoryLoading = value
  },
  [SHIFTS_HISTORY_LOADED](state, value) {
    state.shiftsHistoryLoaded = value
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  },
};

/*----------------------------Actions----------------------------*/

const actions = {
  async getShifts({commit}) {
    commit(SHIFTS_LOADING, true);
    try {
      const data = await api.getShifts();
      commit(SET_SHIFTS, data)
    } catch (err) {
      console.log('get shifts error: ', err)
    } finally {
      commit(SHIFTS_LOADING, false);
      commit(SHIFTS_LOADED, true)
    }
  },
  async addShift({commit}, data) {
    const info = await api.addShift(data);
    commit(ADD_SHIFT, info);
    return info
  },
  async removeShift({commit}, id) {
    await api.removeShift(id);
    commit(REMOVE_SHIFT, id)
  },
  async startShift({commit}, data) {
    await api.startShift(data.id);
    commit(START_SHIFT, data)
  },
  async endShift({commit}, id) {
    const response = await api.endShift(id);
    commit(END_SHIFT, {id, response})
  },
  async updateShift({commit}, {data, id}) {
    const info = await api.updateShift(data, id);
    commit(UPDATE_SHIFT, info)
  },
  async getShiftsHistory({commit}, page) {
    commit(SHIFTS_HISTORY_LOADING, true);
    try {
      const data = await api.getShiftsHistory(page);
      commit(SET_SHIFTS_HISTORY, data)
    } catch (err) {
      console.log('get shifts error: ', err)
    } finally {
      commit(SHIFTS_HISTORY_LOADING, false);
      commit(SHIFTS_HISTORY_LOADED, true)
    }
  },
  resetState({commit}) {
    commit(RESET_STATE)
  },
};

/*----------------------------Export----------------------------*/

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
}