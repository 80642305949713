import axios from "@/services/axios-instance";

export default {
  async getPeriods() {
    const response = await axios.get('/v1/models-plan/periods/');
    return response.data
  },

  async getPeriod(id) {
    const response = await axios.get(`/v1/models-plan/periods/${id}/`);
    return response.data
  },

  async addPeriod(data) {
    const response = await axios.post('/v1/models-plan/periods/', data);
    return response.data
  },

  async editPeriod(id, data){
    const response = await axios.patch(`/v1/models-plan/periods/${id}/`, data);
    return response.data
  },

  async addPlanModel(data) {
    const response = await axios.post('/v1/models-plan/expected-results/', data);
    return response.data
  },

  async editPlanModel(id, data) {
    const response = await axios.patch(`/v1/models-plan/expected-results/${id}/`, data);
    return response.data
  },

  async deletePlanModel(id) {
    const response = await axios.delete(`/v1/models-plan/expected-results/${id}/`);
    return response.data
  },
}