import axios from "@/services/axios-instance";

export default {
  async getAdsByUrl(url) {
    const response = await axios.get('/v1/buying/smm/', { params: { trial_link: url } });
    return response.data
  },

  async getTrackingList(data) {
    const response = await axios.get('/v1/buying/tracking/', { params: data });
    return response.data
  },

  async getHistoryTrackingList(data) {
    const response = await axios.get('/v1/buying/tracking/history/', { params: data });
    return response.data
  },

  async addTrackingItem(data) {
    const response = await axios.post('/v1/buying/tracking/', data);
    return response.data
  },

  async editTrackingItem(id, data) {
    const response = await axios.patch(`/v1/buying/tracking/${id}/`, data);
    return response.data
  },

  async moveToHistoryTrackingItem(id) {
    const response = await axios.post(`/v1/buying/tracking/${id}/end-tracking/`);
    return response.data
  },

  async deleteTrackingItem(id) {
    const response = await axios.delete(`/v1/buying/tracking/${id}/`);
    return response.data
  },

  async getDetail(id, data) {
    const response = await axios.get(`/v1/buying/tracking/${id}/`, { params: data });
    return response.data
  },
}