<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">
			<h1 class="text-h4 mb-8">Сводная по модели</h1>
			<v-row class="justify-space-between">
				<v-col cols="auto">
					<v-row class="flex-wrap" style="max-width: 100%; width: 500px">
						<v-col cols="12">
							<VueDatePicker
									class="density-compact clearable-false"
									v-model="date"
									range
									auto-apply
									:format="format"
									:clearable="false"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectModel
									v-model="model"
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									density="compact"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectDonor
									v-model="donor"
									density="compact"
									clearable
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="auto">
					<div style="max-width: 100%; width: 400px">
						<v-text-field
								v-model="search"
								variant="underlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								density="compact"
						></v-text-field>
					</div>
				</v-col>
			</v-row>

			<div class="overflow-x-auto px-1">

				<div class="d-flex" :class="{'w-fit': mdAndDown}">
					<v-btn
							variant="text"
							v-for="(item, index) in heads"
							:key="index"
							class="px-2 text-body-1 font-weight-medium cursor-pointer justify-start"
							:class="(ordering === item || ordering === '-' + item) ? 'text-primary' : ''"
							:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
							@click="setOrdering(item)"
					>
						<template v-slot:append>
							<v-icon v-if="ordering === item">mdi-chevron-up</v-icon>
							<v-icon v-if="ordering === '-' + item">mdi-chevron-down</v-icon>
						</template>
						{{headName(item)}}
					</v-btn>
				</div>

				<template v-if="items.length > 0">
					<div class="bg-white rounded-lg elevation-1 mb-2" :class="{'w-fit': mdAndDown}">
						<template v-for="(item, index) in items" :key="index">
							<v-divider v-if="index > 0"></v-divider>
							<div
									class="d-flex"
							>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.donor_name + 'px' } : { width: sizes.donor_name + '%' }">{{ item.donor_name }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.only_fans_model_nickname + 'px' } : { width: sizes.only_fans_model_nickname + '%' }">{{ item.only_fans_model_nickname }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.promo_count + 'px' } : { width: sizes.promo_count + '%' }">{{ item.promo_count }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.total_claims_count + 'px' } : { width: sizes.total_claims_count + '%' }">{{ item.total_claims_count }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.total_romi + 'px' } : { width: sizes.total_romi + '%' }">{{ item.total_romi }}%</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.total_fans_count + 'px' } : { width: sizes.total_fans_count + '%' }">{{ item.total_fans_count }}</div>
							</div>
						</template>
					</div>
				</template>
				<template v-if="items.length === 0 && itemsLoaded">
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>Нет данных, соответствующих выбранным фильтрам</span>
					</div>
				</template>

				<v-skeleton-loader v-if="itemsLoading" type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

			</div>

		</div>
	</div>
</template>

<script>
	import apiAds from "@/api/ads";
	import SelectModel from '@/components/SelectModel.vue';
	import SelectDonor from "@/components/SelectDonor.vue";
	import {mapState} from "vuex";
	import {useDisplay} from "vuetify";

  export default {
		setup () {
			// Destructure only the keys we want to use
			const { mdAndDown } = useDisplay();
			return { mdAndDown }
		},


    name: 'PivotTablePage',


    components: {
			SelectDonor,
      SelectModel,
    },


    data() {
      return {
				timer: null,
				tableCellClass: 'px-2 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2',
				date: this.setDefaultDateRange(),
        format: 'dd.MM.yyyy',
        model: null,
        donor: null,
				ordering: '-donor_name',
        search: '',
				page: 1,
				heads: ['donor_name', 'only_fans_model_nickname', 'promo_count', 'total_claims_count', 'total_romi', 'total_fans_count'],
				sizes: {
					donor_name: '15',
					only_fans_model_nickname: '20',
					promo_count: '20',
					total_claims_count: '15',
					total_romi: '15',
					total_fans_count: '15',
				},
				sizesMobile: {
					donor_name: '150',
					only_fans_model_nickname: '150',
					promo_count: '150',
					total_claims_count: '150',
					total_romi: '130',
					total_fans_count: '130',
				},
				items: [],
				itemsLoading: false,
				itemsLoaded: false,
      }
    },


		computed: {
			...mapState({
				commonListModels: state => state.user.commonListModels,
				commonListModelsLoaded: state => state.user.commonListModelsLoaded,
			})
		},


		created() {
			if (this.commonListModelsLoaded) {
				this.model = this.commonListModels[0].id;
			}
			document.addEventListener('scroll', this.handleScroll);
		},


		unmounted () {
			document.removeEventListener('scroll', this.handleScroll);
		},


		watch: {
			ordering() {
				this.paginationReset();
				this.getModelsSummary();
			},
			date() {
				this.paginationReset();
				this.getModelsSummary();
			},
			model() {
				this.paginationReset();
				this.getModelsSummary();
			},
			donor() {
				this.paginationReset();
				this.getModelsSummary();
			},
			commonListModelsLoaded(value) {
				if (value) {
					this.model = this.commonListModels[0].id;
				}
			},
			search() {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.paginationReset();
					this.getModelsSummary()
				}, 400);
			}
		},


		methods: {
			paginationReset() {
				this.items= [];
				this.itemsLoaded = false;
				this.page = 1;
			},
			handleScroll (event) {
				const clientHeight = event.target.body.clientHeight
				const scrollHeight = event.target.body.scrollHeight
				const scrollTop = window.scrollY
				if (scrollTop + clientHeight + 30 >= scrollHeight) {
					if (this.page && !this.itemsLoading) {
						this.getModelsSummary()
					}
				}
			},
			setDefaultDateRange() {
				const now = new Date();
				const start = new Date(now.getFullYear(), now.getMonth(), -14);
				const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
				return [start, end]
			},
			formatingDate(date, format) {
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const day = (date.getDate().toString().padStart(2, '0'));
				if (format === 'dd.MM.yyyy') {
					return `${day}.${month}.${year}`;
				} else if (format === 'yyyy-MM-dd') {
					return `${year}-${month}-${day}`;
				}
			},
			headName(value) {
				if (value === 'donor_name') return 'Donor';
				if (value === 'only_fans_model_nickname') return 'Our model';
				if (value === 'promo_count') return 'Promo count';
				if (value === 'total_claims_count') return 'Claims count';
				if (value === 'total_romi') return 'ROMI';
				if (value === 'total_fans_count') return 'Fans count';
			},
			setOrdering(value) {
				if (this.ordering === '-' + value || this.ordering === value) {
					if (this.ordering === value) {
						this.ordering = '-' + value;
					} else {
						this.ordering = value;
					}
				} else {
					this.ordering = '-' + value;
				}
			},
			async getModelsSummary() {
				const params = {
					date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
					date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
					only_fans_model: this.model,
					donor: this.donor,
					ordering: this.ordering,
					search: this.search,
					page: this.page,
				}
				try {
					this.itemsLoaded = false;
					this.itemsLoading = true;
					const response = await apiAds.getModelsSummary(params);
					this.items = this.items.concat(response.results);
					this.page = response.next;
					this.itemsLoaded = true;
					this.itemsLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			},
		},
  }
</script>

<style scoped>

</style>