<script>
import {useDisplay} from "vuetify";
import {mapState} from "vuex";
import {toast} from "vuetify-sonner";
import apiAds from "@/api/ads";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: "PromoCopyDialog",


	props: {
		info: {
			type: Object,
		}
	},


	data() {
		return {
			format: 'dd.MM.yyyy',
			copyList: [{ date: null, only_fans_model: null }],
			loadingCopy: false,
			loadedCopy: false,
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
	},


	methods: {
		addPaymentTool() {
			this.copyList.push({ date: null, only_fans_model: null })
		},

		removeCopyListItem(index) {
			this.copyList.splice(index, 1)
		},

		async copyPromo() {
			this.loadingCopy = true;
			this.loadedCopy = false;
			const successCopyIndex = [];
			for (let i = 0; i < this.copyList.length; i++) {
				let formattedDate = '';
				if (this.copyList[i].date) {
					const date = this.copyList[i].date;
					const year = date.getFullYear();
					const month = (date.getMonth() + 1).toString().padStart(2, '0');
					const day = (date.getDate().toString().padStart(2, '0'));
					formattedDate = `${year}-${month}-${day}`;
				}
				const data = {
					date: formattedDate,
					only_fans_model: this.copyList[i].only_fans_model,
				};
				try {
					const response = await apiAds.copyPromo(this.info.id, data);
					console.log(response)
					let model = response.only_fans_model.nickname;
					const dateElements = response.date.split('-');
					const newDateFormat = `${dateElements[2]}.${dateElements[1]}.${dateElements[0]}`;
					toast('Promo successfully copied:', {
						duration: 5000,
						description: `Date: ${newDateFormat}, Model: ${model}`,
						cardProps: {
							color: 'success',
						},
					});
					this.$emit('copy', response)
					successCopyIndex.push(i)
				} catch (error) {
					toast('Promo cannot be copied:', {
						duration: 5000,
						description: `${JSON.stringify(error.response.data)}`,
						cardProps: {
							color: 'error',
						},
					});
				}
			}
			console.log('successCopyIndex: ', successCopyIndex)
			this.copyList = this.copyList.filter((item, index) => !successCopyIndex.includes(index) );
			if (this.copyList.length === 0) {
				this.$emit('close')
			}
			this.loadingCopy = false;
			this.loadedCopy = true;
		}
	}
}
</script>

<template>
	<v-card class="pa-6" :disabled="loadingCopy">
		<div class="fill-height d-flex flex-column">
			<div class="flex-1-1">
				<div class="text-h6 mb-6">Duplicate promo</div>
				<div class="text-body-1 mb-2">Promo details</div>
				<v-row  class="flex-wrap" v-for="(item, index) in copyList" :key="index">
					<v-col>
						<v-row>
							<v-col :cols="mdAndDown ? 12 : 6">
								<VueDatePicker
										position="right"
										class="clearable-false"
										placeholder="Date"
										v-model="item.date"
										auto-apply
										:format="format"
										clearable
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 6">
								<v-autocomplete
										v-model="item.only_fans_model"
										:loading="!commonListModelsLoaded"
										:disabled="!commonListModelsLoaded"
										placeholder="Model"
										variant="outlined"
										item-title="nickname"
										item-value="id"
										:items="commonListModels"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-col>
					<v-col class="v-col-auto" v-if="copyList.length > 1">
						<v-btn icon="mdi-trash-can-outline" variant="text" size="large" @click="removeCopyListItem(index)"></v-btn>
					</v-col>
				</v-row>
				<div class="mb-4">
					<label>
						<v-btn icon="mdi-plus" size="small" color="primary" @click="addPaymentTool"></v-btn>
						<span class="text-body-2 ml-2">Add element</span>
					</label>
				</div>
			</div>
			<div class="flex-0-0">
				<v-row justify="start" no-gutters class="mt-10 pa-0">
					<v-btn color="primary" @click="copyPromo" :disabled="loadingCopy" :loading="loadingCopy">Confirm</v-btn>
					<v-btn color="primary" class="mr-2" variant="text" @click="$emit('close')">Cancel</v-btn>
				</v-row>
			</div>
		</div>
	</v-card>
</template>

<style scoped>

</style>