<template>
	<div class="mt-16">
		<v-divider class="border-opacity-100" color="grey-darken-1"></v-divider>
		<h2 class="text-h5 font-weight-medium my-6">Shifts history</h2>
		<div class="mt-10" v-if="sorted">

			<template v-if="sortedList.length === 0">
				<div class="text-subtitle-2">Empty list</div>
			</template>

			<template v-else>
				<div
						v-for="(item, index) in sortedList"
						:key="index"
						class="group-date mb-10"
				>
					<h3 class="text-h5 mb-6">{{ item.title }}</h3>
					<div
							v-for="(shiftGroup, index) in item.shifts"
							:key="index"
							class="group-shift mb-6"
					>
						<h4 class="text-h6 mb-6">{{ shiftGroup.title }}</h4>
						<ShiftCard
								v-for="shift in shiftGroup.items"
								:key="shift.id"
								:shift-info="shift"
								class="mb-4"
								complited
						/>
					</div>
				</div>

				<div v-if="showButton" class="d-flex justify-center">
					<v-btn color="primary" size="large" @click="showMore">Show more</v-btn>
				</div>
			</template>

		</div>

		<div v-else>
			<v-skeleton-loader type="heading" max-width="300" class="bg-transparent mb-6"></v-skeleton-loader>
			<v-skeleton-loader
					v-for="(item, index) in ['','','']"
					:key="index"
					class="rounded-lg elevation-2 mb-6"
					type="table-heading, list-item-two-line"
			></v-skeleton-loader>
		</div>
	</div>
</template>

<script>
  import {mapState} from 'vuex';
  import ShiftCard from '@/components/ShiftCard.vue';

  export default {
    name: 'ShiftListCompleted',


    components: {
      ShiftCard
    },


    data() {
      return {
        unsortedList: [],
        sortedList: [],
        sorting: false,
        sorted: false,
        nextPage: 2,
        showButton: false,
      }
    },


    computed: {
      ...mapState({
        shiftsHistory: state => state.shifts.shiftsHistory,
        shiftsHistoryLoading: state => state.shifts.shiftsHistoryLoading,
        shiftsHistoryLoaded: state => state.shifts.shiftsHistoryLoaded,
        newHistoryItem: state => state.shifts.newHistoryItem,
      }),
    },


    created() {
      if (this.shiftsHistoryLoaded) {
        this.getUnsortedList();
      }
    },


    watch: {
      shiftsHistoryLoaded(value) {
        if (value && this.shiftsHistory !== {}) {
          this.getUnsortedList();
        }
      },
      newHistoryItem() {
        this.unsortedList.push(this.newHistoryItem);
        this.getSortedList();
      }
    },


    methods: {
      getUnsortedList() {
        this.nextPage = this.shiftsHistory.next;
        if (this.shiftsHistory.results.length > 0) {
          this.unsortedList = this.shiftsHistory.results[0].shifts;
        }
        this.getSortedList();
        this.nextPage = this.shiftsHistory.next;
        if (this.shiftsHistory.next !== null) {
          this.showButton = true;
        }
      },
      getSortedList() {
        this.sorted = false;
        this.sorting = true;
        this.sortedList = [];
        let dates = Array.from(new Set(this.unsortedList.map(el => el.shift_date)));
        dates.sort().reverse();
        if (dates.length > 0) {
          dates.forEach((dateItem, dateIndex) => {
            const dateElements = dateItem.split('-');
            const newDateFormat = `${dateElements[2]}.${dateElements[1]}.${dateElements[0]}`;
            this.sortedList[dateIndex] = {};
            this.sortedList[dateIndex].title = newDateFormat;
            this.sortedList[dateIndex].shifts = [];
            let shifts = this.unsortedList.filter(elem => elem.shift_date === dateItem);
            let shiftNames = Array.from(new Set(shifts.map(el => el.shift_number.name)));
            shiftNames.sort().reverse();
            shiftNames.forEach((shiftItem, shiftIndex) => {
              this.sortedList[dateIndex].shifts[shiftIndex] = {};
              this.sortedList[dateIndex].shifts[shiftIndex].title = shiftItem;
              this.sortedList[dateIndex].shifts[shiftIndex].items = shifts.filter(elem => elem.shift_number.name === shiftItem);
            });
          });
        }
        this.sorting = false;
        this.sorted = true;
      },
      async showMore() {
        await this.$store.dispatch('shifts/getShiftsHistory', (this.nextPage));
        this.unsortedList = this.unsortedList.concat(this.shiftsHistory.results[0].shifts);
        this.nextPage = this.shiftsHistory.next;
        if (this.nextPage === null) {
          this.showButton = false;
        }
        this.getSortedList();
      },
    },
  }
</script>

<style scoped>

</style>