<script>
  import apiManagers from "@/api/managers";

  export default {
    name: "CalendarCommonPage",


    data() {
      return {
        cellSize: 34,
        items: [],
        itemsLoading: false,
        itemsLoaded: false,
        rows: [],
        date: this.setDefaultDateRange(),
        daysCount: null,
        days: [],
        thisDay: 0,
      }
    },


    created() {
      this.getCommonCalendar();
      this.setInfo()
    },


    watch: {
      date() {
        this.getCommonCalendar();
        this.setInfo()
      }
    },


    methods: {
      setDefaultDateRange() {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth(), 1);
        const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return [start, end];
      },

      setInfo() {
        const today = new Date();
        if (today > new Date(this.date[0]) && today < new Date(this.date[1])) {
          this.thisDay = new Date().getDate()
        } else {
          this.thisDay = 0
        }
        this.days = [];
        const now = new Date(this.date[0]);
        this.daysCount = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        for (let i = 0; i < this.daysCount; i++) {
          const item = {
            day: i + 1,
            week_day: new Date(now.getFullYear(), now.getMonth(), i + 1).toLocaleString('en-US', {weekday: 'short'})
          };
          this.days.push(item)
        }
      },

      toPrevMonth() {
        const now = new Date(this.date[0]);
        const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const end = new Date(now.getFullYear(), now.getMonth(), 0);
        this.date = [start, end];
      },

      toNextMonth() {
        const now = new Date(this.date[0]);
        const start = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        const end = new Date(now.getFullYear(), now.getMonth() + 2, 0);
        this.date = [start, end];
      },

      setEventColor(type) {
        if (type === 'Birthday') return 'deep-purple-accent-2';
        if (type === 'Sick') return 'pink';
        if (type === 'Vacation') return 'green';
        if (type === 'Other') return 'grey-darken-1'
      },

      setEventIcon(type) {
        if (type === 'Birthday') return 'mdi-cake-variant';
        if (type === 'Sick') return 'mdi-heart-pulse';
        if (type === 'Vacation') return 'mdi-airplane';
        if (type === 'Other') return 'mdi-calendar-range'
      },

      formatingDate(date, format) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = (date.getDate().toString().padStart(2, '0'));
        if (format === 'dd.MM.yyyy') {
          return `${day}.${month}.${year}`;
        } else if (format === 'yyyy-MM-dd') {
          return `${year}-${month}-${day}`;
        }
      },

      async getCommonCalendar() {
        this.itemsLoading = true;
        this.itemsLoaded = false;
        const params = {
          date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
          date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
        };
        try {
          const response = await apiManagers.getCommonCalendar(params);
          this.generateRows(response);
          this.itemsLoading = false;
          this.itemsLoaded = true;
        } catch(error) {
          console.log(error)
        }
      },

      generateRows(response) {
        this.rows = [];
        const models =  Array.from(new Set(response.map(elem => elem.model_info.only_fans_model.nickname))) ;
        models.forEach((name, index) => {
          this.rows[index] = {};
          this.rows[index].name = name;
          this.rows[index].avatar = response.find(elem => elem.model_info.only_fans_model.nickname === name).model_info.avatar;
          this.rows[index].events = response.filter(elem => elem.model_info.only_fans_model.nickname === name);
        });
      },
    }
  }
</script>

<template>
    <div>

        <v-row justify="end" class="mb-3">
            <v-col cols="auto">
                <div class="d-flex align-center">
                    <v-btn variant="text" density="comfortable" icon="mdi-chevron-left" @click="toPrevMonth"></v-btn>
                    <v-btn class="ml-1" variant="text" density="comfortable" icon="mdi-chevron-right" @click="toNextMonth"></v-btn>
                    <div class="text-h5 font-weight-bold ml-3">{{ new Date(this.date[0]).toLocaleString('en-US', { month: 'long' }) }} {{ new Date(this.date[0]).getFullYear() }}</div>
                </div>
            </v-col>
        </v-row>

        <v-sheet v-if="itemsLoaded" class="overflow-x-auto" rounded="lg" elevation="1">
            <div style="min-width: 100%" class="w-fit">
                <div class="d-flex">
                    <div class="flex-0-0" style="flex-basis: 130px !important;"></div>
                    <div style="position: relative" class="flex-1-1 d-flex">
                        <template v-for="(item, index) in days" :key="index">
                            <v-divider vertical></v-divider>
                            <div
                                    :class="index + 1 === thisDay ? 'today text-white' : 'text-grey-darken-1'"
                                    :style="'min-width: ' + cellSize + 'px'"
                                    class="flex-1-1-100 py-2 text-center text-caption font-weight-medium"
                            >
                                <span>{{ item.week_day }}<br>{{ item.day }}</span>
                            </div>
                        </template>
                    </div>
                </div>
                <template v-if="rows.length > 0">
                    <template v-for="(item, index) in rows" :key="index">
                        <v-divider></v-divider>
                        <div class="d-flex" :style="{ height: 34 * item.events.length + 16 + 4 + 'px' }">
                            <div class="flex-0-0 pa-2 d-flex align-center" style="flex-basis: 130px !important; height: 100%">
                                <v-avatar v-if="item.avatar" size="32">
                                    <v-img cover :src="item.avatar"></v-img>
                                </v-avatar>
                                <v-avatar v-else size="32" color="primary">
                                    <v-icon size="24">mdi-account</v-icon>
                                </v-avatar>
                                <div class="text-subtitle-2 ml-1 text-no-wrap overflow-hidden text-overflow-ellipsis" style="width: 70px">{{ item.name }}</div>
                            </div>
                            <div style="position: relative" class="flex-1-1 d-flex">
                                <div style="width: 100%; position: absolute; left: 0; top: 0" class="py-2">
                                    <v-chip
                                            v-for="(event, eventIndex) in item.events"
                                            :key="eventIndex"
                                            class="d-flex pb-1"
                                            :style="{
                                            position: 'absolute',
                                            top: 34 * eventIndex + 8 + 4 + 'px',
                                            left: 100 / daysCount * (new Date(event.start_date).getDate() - 1) + '%',
                                            width: 100 / daysCount * (new Date(event.end_date).getDate()) - 100 / daysCount * (new Date(event.start_date).getDate() - 1) + '%'
                                        }"
                                            :color="setEventColor(event.event_type.name)"
                                            :prepend-icon="event.start_date === event.end_date ? '' : setEventIcon(event.event_type.name)"
                                    >
																			<span v-if="event.start_date === event.end_date"><v-icon>{{ setEventIcon(event.event_type.name) }}</v-icon></span>
																			<span v-else>{{ event.title }}</span>
                                    </v-chip>
                                </div>
                                <template v-for="(item, index) in days" :key="index">
                                    <v-divider vertical></v-divider>
                                    <div :style="'min-width: ' + cellSize + 'px'" class="flex-grow-1 py-2"></div>
                                </template>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <v-divider></v-divider>
                    <div class="px-2 py-4 mt-6 text-body-1 text-center">
                        <span>...</span><br>
                        <span>No events</span>
                    </div>
                </template>
            </div>
        </v-sheet>

        <div v-else>
            <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
        </div>
    </div>
</template>

<style scoped lang="less">
    .today {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 4px;
            top: 5px;
            background-color: rgb(var(--v-theme-primary));
            width: calc(100% - 8px);
            height: calc(100% - 12px);
            border-radius: 4px;
        }

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: calc(100% - 7px);
            width: 0;
            height: 0;
            transform: translateX(-50%);
            border: 4px solid transparent;
            border-top: 6px solid rgb(var(--v-theme-primary));
            border-bottom: unset;
        }

        & span {
            position: relative;
        }
    }
</style>