<template>

		<v-card class="pa-6">
			<div class="text-h5 mb-8">{{ formTitle }}</div>
			<v-form :disabled="formLoading" @submit.prevent>
				<v-list-subheader class="mb-4">Personal information</v-list-subheader>
				<v-row>
					<v-col cols="4">
						<div class="text-body-1 mb-2">Email</div>
						<v-text-field
								variant="outlined"
								v-model="fields.email"
								placeholder="Enter email"
								type="email"
								:rules="[rules.required, rules.validateEmail]"
						></v-text-field>
					</v-col>
					<v-col cols="4">
						<div class="text-body-1 mb-2">Name</div>
						<v-text-field
								variant="outlined"
								v-model="fields.first_name"
								placeholder="Enter name"
								:rules="[rules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="4">
						<div class="text-body-1 mb-2">Surname</div>
						<v-text-field
								variant="outlined"
								v-model="fields.last_name"
								placeholder="Enter surname"
								:rules="[rules.required]"
						></v-text-field>
					</v-col>
				</v-row>
				<v-sheet max-width="500">
					<div class="text-body-1 mb-2">New password</div>
					<PasswordGenerator
							v-model="fields.password"
							placeholder="Enter new password"
							size="10"
							auto="false"
							:required-field="this.edited === -1"
					/>
				</v-sheet>
				<v-divider class="mt-10"></v-divider>
				<v-list-subheader class="mb-4">Telegram</v-list-subheader>
				<v-sheet max-width="400">
					<div class="text-body-1 mb-2">Telegram id</div>
					<v-text-field
							variant="outlined"
							v-model="fields.telegram_id"
							placeholder="Enter id"
							type="number"
					></v-text-field>
				</v-sheet>
				<v-divider class="mt-10"></v-divider>
				<v-list-subheader class="mb-4">Teamlead</v-list-subheader>
				<div v-if="(this.edited === -1) && (userInfo.role === 'team_lead')">{{ userInfo.full_name }}</div>
				<div v-if="(this.edited === -1) && (userInfo.role === 'senior_operator')">{{ userInfo.parent.full_name }}</div>
				<v-sheet v-if="(this.edited !== -1) || (userInfo.role === 'superuser')" max-width="400">
					<div class="text-body-1 mb-2">Operator's teamlaed</div>
					<v-autocomplete
							placeholder="Select teamlead"
							variant="outlined"
							item-title="full_name"
							item-value="id"
							v-model="fields.parent"
							:items="leads"
							persistent-hint
							hint="Select the teamlead to whom you want to transfer the operator"
							:rules="[rules.required]"
					></v-autocomplete>
				</v-sheet>
				<v-row justify="end" no-gutters class="mt-6 pa-0">
					<v-btn variant="text" @click="$emit('close')">Cancel</v-btn>
					<v-btn v-if="(edited !== -1)" :loading="formLoading" type="submit" color="primary" @click="handleEditOperator">Save</v-btn>
					<v-btn v-else :loading="formLoading" type="submit" color="primary" @click="handleAddOperator">Create</v-btn>
				</v-row>
			</v-form>
		</v-card>

</template>

<script>
  import {mapState} from 'vuex'
  import PasswordGenerator from "@/components/PasswordGenerator";

  export default {
    name: 'OperatorEditDialog',


    components: {
      PasswordGenerator,
    },


    props: {
      edited: {},
      info: {},
    },


    data() {
      return {
        emailRegularExpression: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        rules: {
          required: value => {
            if (value) return true;
            return 'Required field'
          },
          validateEmail: (value) => {
            if (this.emailRegularExpression.test(value)) {
              return true
            } else {
              return 'Incorrect email'
            }
          },
        },
        leads: [],
        fields: {
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          telegram_id: '',
          parent: null,
        },
        formLoading: false,
        formLoaded: false,
      }
    },


    computed: {
      ...mapState({
        userInfo: state => state.user.info,
        teamLeads: state => state.user.teamLeads,
      }),
      formTitle () {
        return this.edited === -1 ? 'New operator' : 'Edit operator'
      },
    },


    created() {
      if (this.edited !== -1) {
        this.fields.email = this.info.email;
        this.fields.first_name = this.info.first_name;
        this.fields.last_name = this.info.last_name;
        // this.password = this.info.password;
        this.fields.telegram_id = this.info.telegram_id;
        this.fields.parent = this.info.parent.id;
      }
      this.getLeads()
    },


    methods: {
      getLeads() {
        if (this.userInfo.role === 'superuser') {
          this.leads = this.teamLeads
        }
        if ((this.userInfo.role === 'team_lead') || (this.userInfo.role === 'senior_operator')) {
          this.leads = this.$store.getters['user/getLeads']
        }
      },
      async handleAddOperator() {
        this.formLoading = true;
        const fields = Object.assign({}, this.fields);
        if (this.fields.password.length === 0) {
          delete fields.password
        }
        if (this.fields.telegram_id.length === 0) {
          delete fields.telegram_id
        }
        if ((this.userInfo.role === 'team_lead') || (this.userInfo.role === 'senior_operator')) {
          delete fields.parent
        }
        try {
          await this.$store.dispatch('operators/addOperator', fields);
          this.$emit('close')
        } catch (error) {
          console.log(error)
        } finally {
          this.formLoading = false;
          this.formLoaded = true;
        }
      },
      async handleEditOperator() {
        this.formLoading = true;
        const fields = Object.assign({}, this.fields);
        if (this.fields.password === null || this.fields.password.length === 0) {
          delete fields.password
        }
				if (this.fields.telegram_id === '') {
					fields.telegram_id = null
				}
        try {
          await this.$store.dispatch('operators/editOperator', {fields, id: this.info.id});
          this.$emit('close')
        } catch (error) {
          console.log(error)
        } finally {
          this.formLoading = false;
          this.formLoaded = true;
        }
      },
    }
  }
</script>

<style scoped>

</style>