<template>
	<v-card class="pa-6">
		<div class="text-h5 mb-8">{{ formTitle }}</div>
		<v-form v-model="form" @submit.prevent>
			<v-row class="flex-wrap">
				<v-col cols="12">
					<v-text-field
							v-if="(edited !== -1)"
							disabled
							readonly
							variant="outlined"
							placeholder="Our model"
							:model-value="info.only_fans_model.nickname"
					></v-text-field>
					<SelectModel
							v-else
							v-model="fields.model"
							:items="models"
							:rules="[rules.required]"
							:no-data-text="noDataText"
					/>
				</v-col>
				<v-col cols="6">
					<div class="text-body-1 mb-2">План revenue</div>
					<v-text-field
							v-model="fields.plan_revenue"
							variant="outlined"
							placeholder="План revenue"
							type="number"
							:rules="[rules.required, rules.notNull]"
							prepend-inner-icon="mdi-currency-usd"
					></v-text-field>
				</v-col>
				<v-col cols="6">
					<div class="text-body-1 mb-2">План subsq</div>
					<v-text-field
							v-model="fields.plan_subsq"
							variant="outlined"
							placeholder="План subsq"
							type="number"
							:rules="[rules.required, rules.notNull]"
							@update:model-value="checkSummary"
					></v-text-field>
					<div class="text-body-1 mb-2">План GG</div>
					<v-text-field
							v-model="fields.plan_gg"
							variant="outlined"
							placeholder="План GG"
							type="number"
							@update:model-value="getSummary"
							:error-messages="summaryError"
					></v-text-field>
					<div class="text-body-1 mb-2">План OFTV</div>
					<v-text-field
							v-model="fields.plan_of"
							variant="outlined"
							placeholder="План OFTV"
							type="number"
							@update:model-value="getSummary"
							:error-messages="summaryError"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row justify="end" no-gutters class="mt-4 pa-0">
				<v-btn
						variant="text"
						@click="$emit('close')"
						class="mr-2"
				>Отмена</v-btn>
				<v-btn
						v-if="(edited !== -1)"
						:disabled="formLoading || summaryError.length > 0"
						:loading="formLoading"
						type="submit"
						color="primary"
						@click="editPlanModel"
				>Сохранить</v-btn>
				<v-btn
						v-else
						:loading="formLoading"
						:disabled="formLoading || summaryError.length > 0"
						type="submit"
						color="primary"
						@click="addPlanModel"
				>Добавить</v-btn>
			</v-row>
		</v-form>
	</v-card>
</template>

<script>
import apiPlan from '@/api/plan';
import SelectModel from '@/components/SelectModel.vue';
import { mapState } from 'vuex';

export default {
	name: 'PlanModelEditDialog',

	components: {
		SelectModel,
	},


	props: {
		edited: {},
		info: {},
		period: {},
		selected: {}
	},


	data() {
		return {
			rules: {
				required: value => {
					if (value === [] || value === '') return 'Обязательное поле';
					if (value) return true;
					return 'Обязательное поле'
				},
				notNull: value => {
					if (value === 0 || value === '0') return 'Введите значение больше нуля';
					if (value) return true;
				},
			},
			form: false,
			formLoading: false,
			fields: {
				model: null,
				plan_gg: null,
				plan_of: null,
			},
			planSummary: null,
			summaryError: '',
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
		formTitle () {
			return this.edited === -1 ? 'Добавить модель' : 'Редактировать модель'
		},
		models() {
			let models = structuredClone(this.commonListModels);
			this.selected.forEach(target => {
				models.forEach((model, index) => {
					if (target === model.nickname) {
						models.splice(index, 1)
					}
				})
			})
			return models
		},
		noDataText() {
			if (this.commonListModels.length === 0) {
				return 'Список моделей пуст'
			} else if (this.models.length === 0) {
				return 'Все модели добавлены'
			} else {
				return ''
			}
		},
	},


	created() {
		if (this.edited !== -1) {
			this.fields.model = this.info.only_fans_model.id;
			this.fields.plan_revenue = this.info.expected_revenue;
			this.fields.plan_subsq = this.info.expected_subscribers;
			this.fields.plan_gg = this.info.expected_subscribers_gg;
			this.fields.plan_of = this.info.expected_subscribers_oftv;
		}
	},


	watch: {
		planSummary(value) {
			if (value > Number(this.fields.plan_subsq)) {
				this.summaryError = 'Сумма планов GG и OFTV не должна превышать значение "План subsq"'
			} else {
				this.summaryError = ''
			}
		},
	},


	methods: {
		getSummary() {
			this.planSummary = Number(this.fields.plan_gg) + Number(this.fields.plan_of);
		},

		checkSummary() {
			if (this.planSummary > Number(this.fields.plan_subsq)) {
				this.summaryError = 'Сумма планов GG и OFTV не должна превышать значение "План subsq"'
			} else {
				this.summaryError = ''
			}
		},

		async addPlanModel() {
			if (!this.form) return
			try {
				this.formLoading = true;
				const response = await apiPlan.addPlanModel({
					expected_revenue: this.fields.plan_revenue,
					expected_subscribers: this.fields.plan_subsq,
					expected_subscribers_gg: this.fields.plan_gg,
					expected_subscribers_oftv: this.fields.plan_of,
					only_fans_model: this.fields.model,
					period: this.period
				});
				this.$emit('add', response)
				this.$emit('close')
			} catch (error) {
				console.log('add model error: ', error)
			} finally {
				this.formLoading = false;
			}
		},

		async editPlanModel() {
			if (!this.form) return
			try {
				this.formLoading = true;
				const response = await apiPlan.editPlanModel(this.info.id, {
					expected_revenue: this.fields.plan_revenue,
					expected_subscribers: this.fields.plan_subsq,
					expected_subscribers_gg: this.fields.plan_gg,
					expected_subscribers_oftv: this.fields.plan_of,
				});
				this.$emit('edit', response)
				this.$emit('close')
			} catch (error) {
				console.log('update model error: ', error);
			} finally {
				this.formLoading = false;
			}
		},

	}
}
</script>

<style scoped>

</style>