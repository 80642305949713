<template>
	<v-sheet class="d-flex">
		<v-text-field
				:placeholder="placeholder"
				:value="modelValue"
				v-model="password"
				@input="$emit('update:modelValue', $event.target.value)"
				variant="outlined"
				persistent-hint
				hint="Generate a new strong password"
				:rules="requiredField ? [rules.required, rules.min] : [rules.min]"
		>
			<template v-slot:append>
				<v-btn prepend-icon="mdi-reload" color="primary" size="x-large" @click="generate()">Generate</v-btn>
			</template>
		</v-text-field>
	</v-sheet>
</template>

<script>
  export default {
    name: 'PasswordGenerator',


    props : {
      type: {
        type: String,
        default: 'text'
      },
      size: {
        type: String,
        default: '32'
      },
      characters: {
        type: String,
        default: 'a-z,A-Z,0-9,#'
      },
      placeholder: {
        type: String,
        default: 'Password'
      },
      requiredField: {
        type: Boolean,
        default: false,
      },
      auto: [String, Boolean],
      modelValue: {
        default: '',
      },
    },


    data: function() {
      return {
        password: '',
        rules: {
          required: value => {
            if (value) return true;
            return 'Required field'
          },
          min: value => {
            if (value) {
              if (value.length > 0 && value.length < 6) return 'Use the "Generate" button'
            }
          }
        },
      }
    },


    mounted: function() {
      if(this.auto == 'true' || this.auto == 1) {
        this.generate();
      }
    },


    methods: {
      generate () {
        let charactersArray = this.characters.split(',');
        let CharacterSet = '';
        let password = '';
        if( charactersArray.indexOf('a-z') >= 0) {
          CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
        }
        if( charactersArray.indexOf('A-Z') >= 0) {
          CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        }
        if( charactersArray.indexOf('0-9') >= 0) {
          CharacterSet += '0123456789';
        }
        if( charactersArray.indexOf('#') >= 0) {
          CharacterSet += '![]{}()%&*$#^<>~@|';
        }
        for(let i=0; i < this.size; i++) {
          password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
        }
        this.password = password;
        this.$emit('update:modelValue', password);
      }
    }
  }
</script>

<style scoped>

</style>