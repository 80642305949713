<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Черный список</h1>

			<v-row class="justify-space-between">
				<v-col cols="auto">
					<v-btn prepend-icon="mdi-plus" color="primary" @click="dialog = true">Добавить</v-btn>
				</v-col>
				<v-col cols="auto">
					<div style="max-width: 100%; width: 400px">
						<v-text-field
								v-model="search"
								variant="underlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								density="compact"
						></v-text-field>
					</div>
				</v-col>
			</v-row>

			<div class="overflow-x-auto px-1">

				<div class="d-flex" :class="{'w-fit': mdAndDown}">
					<div
							v-for="(item, index) in heads"
							:key="index"
							class="px-4 pt-2 pb-3 text-body-1 font-weight-medium"
							style="width: unset"
							:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
					>{{headName(item)}}</div>
				</div>

				<template v-if="items.length > 0">
					<template v-for="(item, index) in items" :key="index">
						<v-hover>
							<template v-slot:default="{ isHovering, props }">
								<div
										v-bind="props"
										class="d-flex elevation-1 rounded-lg mb-2 cursor-pointer transition"
										:class="[setItemBgColor(index, isHovering), {'w-fit': mdAndDown}]"
										@click="openEdit(item)"
								>
									<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.donor + 'px' } : { width: sizes.donor + '%' }">{{ item.donor.name }}</div>
									<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.comment + 'px' } : { width: sizes.comment + '%' }">{{ item.comment }}</div>
									<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.marketer + 'px' } : { width: sizes.marketer + '%' }">{{ item.marketer.full_name }}</div>
									<div class="px-4 d-flex justify-end align-center" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }">
										<v-btn color="grey" density="comfortable" icon="mdi-delete" variant="text" @click.stop="deleteItem(item)" ></v-btn>
									</div>
								</div>
							</template>
						</v-hover>
					</template>
				</template>
				<template v-if="items.length === 0 && itemsLoaded">
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>Нет данных, соответствующих выбранным фильтрам</span>
					</div>
				</template>

				<v-skeleton-loader v-if="itemsLoading" type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

			</div>

			<v-dialog v-model="dialogDelete" max-width="400px">
				<v-card class="pa-4">
					<div class="text-body-1 mb-2">Удалить запись</div>
					<div class="text-subtitle-2">Вы уверены?</div>
					<v-row justify="end" no-gutters class="mt-6 pa-0">
						<v-btn class="mr-2" variant="text" @click="closeDelete">Отмена</v-btn>
						<v-btn color="primary" @click="deleteItemConfirm">Ок</v-btn>
					</v-row>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialog" persistent max-width="700px">
				<BlacklistItemEditDialog
						@close="close"
						:edited="editedIndex"
						:info="editedItem"
						@add="addItem"
						@edit="editItem"
				/>
			</v-dialog>

		</div>
	</div>
</template>

<script>
	import apiAds from '@/api/ads';
	import BlacklistItemEditDialog from "@/components/BlacklistItemEditDialog.vue";
	import {useDisplay} from "vuetify";

	export default {
		setup () {
			// Destructure only the keys we want to use
			const { mdAndDown } = useDisplay();
			return { mdAndDown }
		},


		name: 'BlacklistPage',


		components: {
			BlacklistItemEditDialog,
		},


		data() {
			return {
				dialog: false,
				dialogDelete: false,
				editedIndex: -1,
				editedItem: {id: '', donor: '', comment: '', marketer: '',},
				defaultItem: {id: '', donor: '', comment: '', marketer: '',},
				search: '',
				page: 1,
				heads: ['donor', 'comment', 'marketer', 'action'],
				sizes: {
					donor: '15',
					comment: '60',
					marketer: '15',
					action: '10',
				},
				sizesMobile: {
					donor: '150',
					comment: '250',
					marketer: '120',
					action: '100',
				},
				itemsLoading: false,
				itemsLoaded: false,
				items: [],
				deleteLoading: false,
			}
		},


		computed: {

		},


		created() {
			this.getBlackList();
			document.addEventListener('scroll', this.handleScroll);
		},


		unmounted () {
			document.removeEventListener('scroll', this.handleScroll);
		},


		watch: {
			search() {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.paginationReset();
					this.getBlackList()
				}, 400);
			}
		},


		methods: {
			paginationReset() {
				this.items= [];
				this.itemsLoaded = false;
				this.page = 1;
			},
			handleScroll (event) {
				const clientHeight = event.target.body.clientHeight
				const scrollHeight = event.target.body.scrollHeight
				const scrollTop = window.scrollY
				if (scrollTop + clientHeight + 30 >= scrollHeight) {
					if (this.page && !this.itemsLoading) {
						this.getBlackList()
						console.log('start')
					}
				}
			},
			async getBlackList() {
				const params = {
					search: this.search,
					page: this.page,
				}
				try {
					this.itemsLoaded = false;
					this.itemsLoading = true;
					const response = await apiAds.getBlackList(params);
					this.items = this.items.concat(response.results)
					this.page = response.next;
					this.itemsLoaded = true;
					this.itemsLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			},
			headName(value) {
				if (value === 'donor') return 'Ban';
				if (value === 'comment') return 'Comment';
				if (value === 'marketer') return 'Buyer'
			},
			setItemBgColor(index, hover) {
				if (hover) {
					return 'bg-cyan-lighten-5'
				} else if (index % 2) {
					return 'bg-grey-lighten-4'
				} else {
					return 'bg-white'
				}
			},
			openEdit(item) {
				this.editedIndex = this.items.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialog = true
			},
			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1
				})
			},
			deleteItem(item) {
				this.editedIndex = this.items.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialogDelete = true
			},
			closeDelete() {
				this.dialogDelete = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1
				})
			},
			async deleteItemConfirm() {
				try {
					this.deleteLoading = true;
					await apiAds.deleteBlackListItem(this.editedItem.id);
					this.closeDelete();
					const index = this.items.findIndex(item => item.id === this.editedItem.id);
					if (index !== -1) {
						this.items.splice(index, 1)
					}
				} catch (error) {
					console.log('delete black list item error: ', error)
				} finally {
					this.deleteLoading = false;
				}
			},
			addItem(data) {
				this.items.push(data)
			},
			editItem(data) {
				const index = this.items.findIndex(item => item.id === data.id);
				this.items[index] = data
			}
		},
	}
</script>

<style scoped>

</style>