export  const SET_FROM_STORAGE = 'SET_FROM_STORAGE';
export const SET_AUTH = 'SET_AUTH';
export const SET_USER_DATA = 'SET_USER_DATA';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export  const REMOVE_USER = 'REMOVE_USER';
export const SET_MY_GROUP_DATA = 'SET_MY_GROUP_DATA';
export const MY_GROUP_LOADING = 'MY_GROUP_LOADING';
export const MY_GROUP_LOADED = 'MY_GROUP_LOADED';
export const SET_TEAM_LEADS = 'SET_TEAM_LEADS';
export const TEAM_LEADS_LOADING = 'TEAM_LEADS_LOADING';
export const TEAM_LEADS_LOADED = 'TEAM_LEADS_LOADED';
export const SET_ACTIVE_LEAD = 'SET_ACTIVE_LEAD';
export const SET_COMMON_LIST_MODELS = 'SET_COMMON_LIST_MODELS';
export const COMMON_LIST_MODELS_LOADING = 'COMMON_LIST_MODELS_LOADING';
export const COMMON_LIST_MODELS_LOADED = 'COMMON_LIST_MODELS_LOADED';
export const EDIT_MODEL = 'EDIT_MODEL';