<template>

	<div v-if="!sorted">
		<v-skeleton-loader type="heading" max-width="300" class="bg-transparent mb-6"></v-skeleton-loader>
		<v-skeleton-loader
				v-for="(item, index) in 3"
				:key="index"
				class="rounded-lg elevation-2 mb-6"
				type="table-heading, list-item-two-line"
		></v-skeleton-loader>
	</div>

	<div v-if="sorted" class="mt-10">
		<template v-if="sortedList.length === 0">
			<div class="text-subtitle-2">Empty list</div>
		</template>
		<template v-else>
			<div
					v-for="(item, index) in sortedList"
					:key="index"
					class="group-date"
					:class="index !== (sortedList.length - 1) ? 'mb-10' : ''"
			>
				<h3 class="text-h5 mb-6">{{ item.title }}</h3>
				<div
						v-for="(shiftGroup, index) in item.shifts"
						:key="index"
						class="group-shift"
						:class="index !== (item.shifts.length - 1) ? 'mb-6' : ''"
				>
					<h4 class="text-h6 mb-6">{{ shiftGroup.title }}</h4>
					<template v-for="(shift, index) in shiftGroup.items" :key="shift.id">
						<v-lazy :min-height="100" :options="{'threshold':0.5}" transition="fade-transition">
							<ShiftCard
									:shift-info="shift"
									@input="handleEditShift"
									:class="index !== (shiftGroup.items.length - 1) ? 'mb-4' : ''"
							/>
						</v-lazy>
					</template>
				</div>
			</div>
		</template>
	</div>

</template>

<script>
  import { mapState } from 'vuex';
  import ShiftCard from '@/components/ShiftCard.vue';

  export default {
    name: 'ShiftList',


    components: {
      ShiftCard,
    },


    data() {
      return {
        sortedList: [],
        sorting: false,
        sorted: false,
      }
    },


    computed: {
      ...mapState ({
        activeLead: state => state.user.activeLead,
        shifts: state => state.shifts.shifts,
        shiftsLoading: state => state.shifts.shiftsLoading,
        shiftsLoaded: state => state.shifts.shiftsLoaded,
      })
    },


    created() {
      if (this.shiftsLoaded) {
        this.sortShifts();
      }
    },


    watch: {
      shiftsLoaded(value) {
        if (value) {
          this.sortShifts();
        }
      },
      activeLead() {
        this.sortShifts();
      },
      shifts: {
        handler() {
          this.sortShifts();
        },
        deep: true,
      },
    },


    methods: {
      sortShifts() {
        this.sorted = false;
        this.sorting = true;
        try {
          this.sortedList = [];
          let shiftsByLead = [];
          if (this.activeLead === '0') {
            shiftsByLead = this.shifts.slice()
          } else {
            shiftsByLead = this.shifts.filter((item) => item.team_lead.id === this.activeLead)
          }
          let dates = Array.from(new Set(shiftsByLead.map(el => el.shift_date )));
          dates.sort();
          if (dates.length > 0) {
            dates.forEach((dateItem, dateIndex) => {
              const dateElements = dateItem.split('-');
              const newDateFormat = `${dateElements[2]}.${dateElements[1]}.${dateElements[0]}`;
              this.sortedList[dateIndex] = {};
              this.sortedList[dateIndex].title = newDateFormat;
              this.sortedList[dateIndex].shifts = [];
              let shifts = shiftsByLead.filter(elem => elem.shift_date === dateItem );
              let shiftNames = Array.from(new Set(shifts.map(el => el.shift_number.name )));
              shiftNames.sort();
              shiftNames.forEach((shiftItem, shiftIndex) => {
                this.sortedList[dateIndex].shifts[shiftIndex] = {};
                this.sortedList[dateIndex].shifts[shiftIndex].title = shiftItem;
                this.sortedList[dateIndex].shifts[shiftIndex].items = shifts.filter(elem => elem.shift_number.name === shiftItem);
              });
            });
          }
        } finally {
          this.sorting = false;
          this.sorted = true;
        }
      },
      handleEditShift(info) {
        console.log('shift info:', info);
        this.shiftEditOpen = true
      },
    }
  }
</script>

<style scoped>

</style>