<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">
			<h1 class="text-h4 mb-8">Сводная таблица</h1>

			<v-row class="justify-space-between">
				<v-col cols="auto">
					<v-row class="flex-wrap" style="max-width: 100%; width: 540px">
						<v-col :cols="mdAndDown ? 12 : 6">
							<VueDatePicker
									class="density-compact clearable-false"
									v-model="date"
									range
									auto-apply
									:format="format"
									:clearable="false"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectPromo
									v-model="promo"
									density="compact"
									clearable
									hide-details
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectModel
									v-model="model"
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									density="compact"
									clearable
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectDonor
									v-model="donor"
									density="compact"
									clearable
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="auto">
					<div style="max-width: 100%; width: 400px">
						<v-text-field
								v-model="search"
								variant="underlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								density="compact"
						></v-text-field>
					</div>
				</v-col>
			</v-row>

			<div class="overflow-x-auto px-1">

				<div class="d-flex" :class="{'w-fit': mdAndDown}">
					<v-btn
							variant="text"
							v-for="(item, index) in heads"
							:key="index"
							class="px-2 text-body-1 font-weight-medium cursor-pointer justify-start"
							:class="(ordering === item || ordering === '-' + item) ? 'text-primary' : ''"
							:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
							@click="setOrdering(item)"
					>
						<template v-slot:append>
							<v-icon v-if="ordering === item">mdi-chevron-up</v-icon>
							<v-icon v-if="ordering === '-' + item">mdi-chevron-down</v-icon>
						</template>
						{{headName(item)}}
					</v-btn>
				</div>

				<div
						v-for="(item, index) in items" :key="index"
						class="elevation-1 rounded-lg mb-2"
						:class="[index % 2 ? 'bg-grey-lighten-4' : 'bg-white', {'w-fit': mdAndDown}]"
				>
					<template v-for="(row, index) in item.items" :key="index">
						<v-divider v-if="index > 0"></v-divider>
						<div class="d-flex">
							<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.date + 'px' } : { width: sizes.date + '%' }">
								<span v-if="index === 0">{{item.date}}</span>
							</div>
							<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.only_fans_model + 'px' } : { width: sizes.only_fans_model + '%' }">{{ row.only_fans_model }}</div>
							<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.donor + 'px' } : { width: sizes.donor + '%' }">{{ row.donor }}</div>
							<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.donor + 'px' } : { width: sizes.of_link + '%' }">{{ row.of_link }}</div>
							<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.claims_count + 'px' } : { width: sizes.claims_count + '%' }">{{ row.claims_count }}</div>
							<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.fans_count + 'px' } : { width: sizes.fans_count + '%' }">{{ row.fans_count }}</div>
							<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.romi + 'px' } : { width: sizes.romi + '%' }">{{ row.romi }}%</div>
						</div>
					</template>
				</div>
				<template v-if="items.length === 0 && itemsLoaded">
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>Нет данных соответствующих выбранным фильтрам</span>
					</div>
				</template>

				<v-skeleton-loader v-if="itemsLoading" type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

			</div>
		</div>
	</div>
</template>

<script>
	import apiAds from "@/api/ads";
	import {mapState} from "vuex";
	import SelectModel from '@/components/SelectModel.vue';
	import SelectDonor from "@/components/SelectDonor.vue";
	import {useDisplay} from "vuetify";
	import SelectPromo from "@/components/SelectPromo.vue";

  export default {
		setup () {
			// Destructure only the keys we want to use
			const { mdAndDown } = useDisplay();
			return { mdAndDown }
		},


    name: 'PivotTablePage',


    components: {
			SelectPromo,
			SelectDonor,
      SelectModel,
    },


    data() {
      return {
				timer: null,
				tableCellClass: 'px-2 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2',
				date: this.setDefaultDateRange(),
        format: 'dd.MM.yyyy',
				promo: null,
        model: null,
        donor: null,
        search: '',
				ordering: '-date',
				page: 1,
				heads: ['date', 'only_fans_model', 'donor', 'of_link', 'claims_count', 'fans_count', 'romi'],
				sizes: {
					date: '10',
					only_fans_model: '20',
					donor: '15',
					of_link: '20',
					claims_count: '15',
					fans_count: '10',
					romi: '10',
				},
				sizesMobile: {
					date: '100',
					only_fans_model: '150',
					donor: '200',
					of_link: '200',
					claims_count: '150',
					fans_count: '100',
					romi: '100',
				},
				items: [],
				itemsLoaded: false,
				itemsLoading: false,
      }
    },


		computed: {
			...mapState({
				commonListModels: state => state.user.commonListModels,
				commonListModelsLoaded: state => state.user.commonListModelsLoaded,
			})
		},


		created() {
			this.getSummary();
			document.addEventListener('scroll', this.handleScroll);
		},


		unmounted () {
			document.removeEventListener('scroll', this.handleScroll);
		},


		watch: {
			ordering() {
				this.paginationReset();
				this.getSummary();
			},
			date() {
				this.paginationReset();
				this.getSummary();
			},
			promo() {
				this.paginationReset();
				this.getSummary();
			},
			model() {
				this.paginationReset();
				this.getSummary();
			},
			donor() {
				this.paginationReset();
				this.getSummary();
			},
			search() {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.paginationReset();
					this.getSummary();
				}, 400);
			}
		},


		methods: {
			paginationReset() {
				this.items= [];
				this.itemsLoaded = false;
				this.page = 1;
			},
			handleScroll (event) {
				const clientHeight = event.target.body.clientHeight
				const scrollHeight = event.target.body.scrollHeight
				const scrollTop = window.scrollY
				if (scrollTop + clientHeight + 30 >= scrollHeight) {
					if (this.page && !this.itemsLoading) {
						this.getSummary()
					}
				}
			},
			setDefaultDateRange() {
				const now = new Date();
				const start = new Date(now.getFullYear(), now.getMonth(), -14);
				const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
				return [start, end]
			},
			formatingDate(date, format) {
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const day = (date.getDate().toString().padStart(2, '0'));
				if (format === 'dd.MM.yyyy') {
					return `${day}.${month}.${year}`;
				} else if (format === 'yyyy-MM-dd') {
					return `${year}-${month}-${day}`;
				}
			},
			headName(value) {
				if (value === 'date') return 'Date';
				if (value === 'only_fans_model') return 'Our model';
				if (value === 'donor') return 'Donor';
				if (value === 'of_link') return 'OF link';
				if (value === 'claims_count') return 'Claims count';
				if (value === 'fans_count') return 'Fans';
				if (value === 'romi') return 'ROMI';
			},
			setOrdering(value) {
				if (this.ordering === '-' + value || this.ordering === value) {
					console.log('value: ', value)
					if (this.ordering === value) {
						this.ordering = '-' + value;
					} else {
						this.ordering = value;
					}
				} else {
					this.ordering = '-' + value;
				}
			},
			async getSummary() {
				const params = {
					date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
					date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
					only_fans_model: this.model,
					promo: this.promo,
					donor: this.donor,
					ordering: this.ordering,
					search: this.search,
					page: this.page,
				}
				try {
					this.itemsLoaded = false;
					this.itemsLoading = true;
					const response = await apiAds.getSummary(params);
					this.items = this.items.concat(response.results);
					this.page = response.next;
					this.itemsLoaded = true;
					this.itemsLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			},
		},
  }
</script>

<style scoped>

</style>