<template>
	<v-app-bar class="elevation-0 custom-shadow">
		<v-app-bar-nav-icon :icon="show ? 'mdi-arrow-collapse-horizontal' : 'mdi-arrow-expand-horizontal'" variant="outlined" color="grey" @click.stop="$emit('toggleSidebar')"></v-app-bar-nav-icon>
		<v-spacer></v-spacer>
		<v-btn
				icon="mdi-exit-to-app"
				variant="outlined"
				color="grey"
				@click="logout"
		></v-btn>
	</v-app-bar>
</template>

<script>
  export default {
    name: 'BaseHeader',


		props: {
			show: {}
		},


    data: () => ({}),


    methods: {
      logout() {
				this.$emit('logout')
        this.$store.dispatch('resetState')
      },
    }
  }
</script>

<style scoped>

</style>