<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Receivable</h1>

			<v-row>

				<v-col :cols="mdAndDown ? 12 : 6">
					<div class="d-flex flex-column fill-height">
						<div class="flex-1-1 pb-3">
							<WidgetLineChart
									v-if="futureTotalPurchaseLoaded"
									color="primary"
									:title="'Future total purchase'"
									:subtitle="'Today'"
									:points="futureTotalPurchase.points"
									:yesterday-differ="futureTotalPurchase.yesterday_differ"
							/>
							<v-card v-else class="pa-4 fill-height rounded-lg d-flex align-center justify-center">
								<v-progress-circular indeterminate color="primary"></v-progress-circular>
							</v-card>
						</div>
						<div class="flex-1-1 pt-3">
							<WidgetLineChart
									v-if="averagePurchaseOnModelLoaded"
									color="secondary"
									:title="'Average purchase on model'"
									:subtitle="'Today'"
									:points="averagePurchaseOnModel.points"
									:yesterday-differ="averagePurchaseOnModel.yesterday_differ"
							/>
							<v-card v-else class="pa-4 fill-height rounded-lg d-flex align-center justify-center">
								<v-progress-circular indeterminate color="primary"></v-progress-circular>
							</v-card>
						</div>
					</div>
				</v-col>

				<v-col :cols="mdAndDown ? 12 : 3">
					<WidgetDept :current-month="currentMonth"/>
				</v-col>

				<v-col :cols="mdAndDown ? 12 : 3">
					<WidgetFuturePurchases :current-month="currentMonth"/>
				</v-col>

				<v-col :cols="mdAndDown ? 12 : 7">
					<WidgetTodayReport :height="mdAndDown ? 400 : 600"/>
				</v-col>

				<v-col :cols="mdAndDown ? 12 : 5">
					<WidgetDeptBy :height="mdAndDown ? 400 : 600"/>
				</v-col>

				<v-col cols="12">
					<WidgetDeptByModel :height="mdAndDown ? 400 : 600"/>
				</v-col>

			</v-row>

		</div>
	</div>
</template>

<script>
	import WidgetDept from "@/components/widgets/WidgetDept.vue";
	import WidgetFuturePurchases from "@/components/widgets/WidgetFuturePurchases.vue";
	import WidgetTodayReport from "@/components/widgets/WidgetTodayReport.vue";
	import WidgetDeptBy from "@/components/widgets/WidgetDeptBy.vue";
	import WidgetLineChart from "@/components/widgets/WidgetLineChart.vue";
	import {useDisplay} from "vuetify";
	import apiFinance from "@/api/finance"
	import WidgetDeptByModel from "@/components/widgets/WidgetDeptByModel.vue";

	export default {
		setup () {
			// Destructure only the keys we want to use
			const { mdAndDown } = useDisplay();
			return { mdAndDown }
		},


		name: 'ReceivablePage',


		components: {
			WidgetDeptByModel,
			WidgetLineChart,
			WidgetDeptBy,
			WidgetTodayReport,
			WidgetFuturePurchases,
			WidgetDept
		},


		data() {
			return {
				averagePurchaseOnModel: {},
				averagePurchaseOnModelLoading: false,
				averagePurchaseOnModelLoaded: false,
				futureTotalPurchase: {},
				futureTotalPurchaseLoading: false,
				futureTotalPurchaseLoaded: false,
				currentMonth: null,
				newTable: [
					{ model: 'name', data: [ { month: '01.2023', value: '0' }, { month: '02.2023', value: '1' }, { month: '03.2023', value: '2' } ] },
					{ model: 'name2', data: [ { month: '01.2023', value: '3' }, { month: '02.2023', value: '4' }, { month: '03.2023', value: '5' } ] }
				]
			}
		},


		computed: {},


		created() {
			this.getAveragePurchaseOnModel();
			this.getFutureTotalPurchase();
			const now = new Date();
			this.currentMonth = `${(now.getMonth() + 1).toString().padStart(2, '0')}.${now.getFullYear()}`
		},


		watch: {},


		methods: {
			async getAveragePurchaseOnModel() {
				try {
					this.averagePurchaseOnModelLoading = true;
					this.averagePurchaseOnModelLoaded = false;
					this.averagePurchaseOnModel = await apiFinance.getAveragePurchaseOnModel();
					this.averagePurchaseOnModelLoading = false
					this.averagePurchaseOnModelLoaded = true
				} catch (error) {
					console.log(error)
				}
			},
			async getFutureTotalPurchase() {
				try {
					this.futureTotalPurchaseLoading = true;
					this.futureTotalPurchaseLoaded = false;
					this.futureTotalPurchase = await apiFinance.getFutureTotalPurchase();
					this.futureTotalPurchaseLoading = false
					this.futureTotalPurchaseLoaded = true
				} catch (error) {
					console.log(error)
				}
			},
		}
	}
</script>

<style scoped>

</style>