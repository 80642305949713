// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VDataTable } from 'vuetify/labs/VDataTable'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'
import { VStepper } from 'vuetify/labs/VStepper'
import { VStepperHeader } from 'vuetify/labs/VStepper'
import { VStepperItem } from 'vuetify/labs/VStepper'

import colors from 'vuetify/lib/util/colors'

export default createVuetify(
  {
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: colors.blue.darken2,
            secondary: colors.cyan.darken1,
            black: colors.grey.darken4,
            background: '#F3F5F8',
          }
        },
      },
    },
    components: {
      VDataTable,
      VSkeletonLoader,
      VStepper,
      VStepperHeader,
      VStepperItem,
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
