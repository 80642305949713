<script>
  import apiManagers from "@/api/managers"
  import { mapState } from 'vuex'
  import FullCalendar from '@fullcalendar/vue3'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import EventDialog from "@/components/EventDialog"

  export default {
    name: "CalendarPersonalPage",


    components: {
      EventDialog,
      FullCalendar,
    },


    data() {
      return {
        calendarTitle: '',
        eventDialogOpen: false,
        selectedEvents: [],
        selectedModel: null,
        calendarOptions: {},
        selectedMonth: {
          month: new Date().getMonth(),
          year: new Date().getFullYear()
        },
        eventTypes: null,
        eventTypesLoaded: false,
        eventTypesRepeat: null,
        eventTypesRepeatLoaded: false,
        itemsLoading: false,
        itemsLoaded: false,
        items: [],
        editedItem: {},
        defaultItem: {},
        editedIndex: false,
        editMode: false,
      }
    },


    computed: {
      ...mapState({
        commonListModels: state => state.user.commonListModels,
        commonListModelsLoaded: state => state.user.commonListModelsLoaded,
      })
    },


    created() {
      if (this.commonListModelsLoaded) {
        this.selectedModel = this.commonListModels[0].id;
      }
      this.setCalendarOptions();
      this.getEventTypes();
      this.getEventRepeatTypes();
      if (this.eventTypesLoaded && this.commonListModelsLoaded) {
        this.getPersonalCalendar()
      }
    },


    watch: {
      commonListModelsLoaded() {
        this.selectedModel = this.commonListModels[0].id;
      },
      selectedMonth: {
        handler() {
          this.$refs.fullCalendar.getApi().gotoDate(new Date(this.selectedMonth.year, this.selectedMonth.month, 1));
          this.getPersonalCalendar()
        },
        deep: true
      },
      selectedModel() {
        if(this.eventTypesLoaded && this.selectedModel) {
          this.getPersonalCalendar()
        }
      },
      selectedEvents() {
        if (this.eventTypesLoaded && this.selectedModel) {
          this.getPersonalCalendar()
        }
      },
      eventTypesLoaded() {
        if (this.eventTypesLoaded && this.selectedModel) {
          this.getPersonalCalendar()
        }
      }
    },


    methods: {
      setCalendarOptions() {
        this.calendarOptions = {
          initialDate: new Date(this.selectedMonth.year, this.selectedMonth.month, 1),
          plugins: [dayGridPlugin, interactionPlugin],
          headerToolbar: false,
          initialView: 'dayGridMonth',
          selectable: true,
          select: (info) => {
            this.handleDateClick(info)
          },
          eventClick: (info) => {
            this.handleEventClick(info)
          },
          events: [{title: '', start: new Date(), end: new Date(), color: 'transparent'}],
        }
      },

      setEventColor(type) {
        if (type === 'Birthday') return 'deep-purple-accent-2';
        if (type === 'Sick') return 'pink';
        if (type === 'Vacation') return 'green';
        if (type === 'Other') return 'grey-darken-1';
        if (type === '') return 'white'
      },

      setEventIcon(type) {
        if (type === 'Birthday') return 'mdi-cake-variant';
        if (type === 'Sick') return 'mdi-heart-pulse';
        if (type === 'Vacation') return 'mdi-airplane';
        if (type === 'Other') return 'mdi-calendar-range'
      },

      formatingDate(date, format) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = (date.getDate().toString().padStart(2, '0'));
        if (format === 'dd.MM.yyyy') {
          return `${day}.${month}.${year}`;
        } else if (format === 'yyyy-MM-dd') {
          return `${year}-${month}-${day}`;
        }
      },

      handleEventClick(info){
        this.eventDialogOpen = true;
        this.editedIndex = true;
        console.log(info);
        this.editedItem = Object.assign({}, {
          id: info.event.id,
          start_date: info.event.start,
          end_date: new Date(new Date(info.event.end).getFullYear(), new Date(info.event.end).getMonth(), new Date(info.event.end).getDate() - 1),
          title: info.event.title,
          label: info.event.extendedProps.label,
          description: info.event.extendedProps.description,
          repeat: info.event.extendedProps.repeat,
        });
      },

      handleDateClick(info){
        this.eventDialogOpen = true;
        this.editMode = true;
        this.editedItem = Object.assign({}, {
          start_date: info.start,
          end_date: new Date(new Date(info.end).getFullYear(), new Date(info.end).getMonth(), new Date(info.end).getDate() - 1),
        });
        console.log(info);
      },

      handleAddEventClick() {
        this.eventDialogOpen = true;
        this.editMode = true;
      },

      handleEventDialogClose() {
        this.eventDialogOpen = false;
        this.create = false;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = false;
        this.editMode = false;
      },

      handleAddEvent(response) {
        const index = this.calendarOptions.events.findIndex(item => item.id === response.id);

        console.log(response.id);
        console.log(index);

        let newItem = {
          id: response.id,
          title: response.title,
          start: response.start_date,
          end: new Date(new Date(response.end_date).getFullYear(), new Date(response.end_date).getMonth(), new Date(response.end_date).getDate() + 1),
          extendedProps: {
            description: response.description,
            label: response.event_type,
            repeat: response.repeat_type,
          },
          color: 'transparent',
        };

        if (index !== -1) {
          this.calendarOptions.events[index] = newItem
        } else {
          this.calendarOptions.events.push(newItem)
        }
      },

      handleDeleteEvent(id) {
        const index = this.calendarOptions.events.findIndex(item => item.id === id);
        if (index !== -1) {
          this.calendarOptions.events.splice(index, 1)
        }
      },

      async getEventTypes() {
        this.eventTypesLoaded = false;
        try {
          this.eventTypes = await apiManagers.getEventTypes();
          this.eventTypesLoaded = true;
        } catch (error) {
          console.log(error)
        }

        this.eventTypes.forEach((item) => {
          this.selectedEvents.push(item.id)
        })
      },

      async getEventRepeatTypes() {
        this.eventTypesRepeatLoaded = false;
        try {
          this.eventTypesRepeat = await apiManagers.getEventRepeatTypes();
          this.eventTypesRepeatLoaded = true;
        } catch (error) {
          console.log(error)
        }
      },

      async getPersonalCalendar() {
        this.itemsLoading = true;
        this.itemsLoaded = false;
        const params = {
          date_after: this.formatingDate(new Date(this.selectedMonth.year, this.selectedMonth.month, 1), 'yyyy-MM-dd'),
          date_before: this.formatingDate(new Date(this.selectedMonth.year, this.selectedMonth.month + 1, 0), 'yyyy-MM-dd'),
          only_fans_model: this.selectedModel,
          event_type: this.selectedEvents.join(', ')
        };
        try {
          const response = await apiManagers.getCommonCalendar(params);
          await this.generateItems(response);
          this.itemsLoading = false;
          this.itemsLoaded = true;
          this.getCalendarTitle()
        } catch(error) {
          console.log(error)
        }
      },

      generateItems(response) {
        this.items = [];
        response.forEach((el) => {
          let newItem = {
            id: el.id,
            title: el.title,
            start: el.start_date,
            end: new Date(new Date(el.end_date).getFullYear(), new Date(el.end_date).getMonth(), new Date(el.end_date).getDate() + 1),
            extendedProps: {
              description: el.description,
              label: el.event_type,
              repeat: el.repeat_type,
            },
            color: 'transparent',
          };
          this.items.push(newItem)
        });
        this.calendarOptions.events = this.items
      },

      toPrevMonth() {
        if (this.selectedMonth.month === 0) {
          this.selectedMonth.year = this.selectedMonth.year - 1;
          this.selectedMonth.month = 11
        } else {
          this.selectedMonth.month = this.selectedMonth.month - 1
        }
      },

      toNextMonth() {
        if (this.selectedMonth.month === 11) {
          this.selectedMonth.year = this.selectedMonth.year + 1;
          this.selectedMonth.month = 0
        } else {
          this.selectedMonth.month = this.selectedMonth.month + 1
        }
      },

      getCalendarTitle() {
        this.calendarTitle = this.$refs.fullCalendar.getApi().view.title;
      }
    }
  }
</script>

<template>
    <div>

        <v-row justify="end" class="mb-3">
            <v-col cols="auto">
                <div class="d-flex align-center">
                    <v-btn variant="text" density="comfortable" icon="mdi-chevron-left" @click="toPrevMonth"></v-btn>
                    <v-btn class="ml-1" variant="text" density="comfortable" icon="mdi-chevron-right" @click="toNextMonth"></v-btn>
                    <div class="text-h5 font-weight-bold ml-3">{{ calendarTitle }}</div>
                </div>
            </v-col>
        </v-row>

        <v-sheet elevation="1">
            <v-row no-gutters>
                <v-col cols="auto">
                    <div class="pa-4">
                        <v-btn class="text-none w-100 mb-3" prepend-icon="mdi-plus" color="primary" variant="tonal" size="large" @click="handleAddEventClick">Add event</v-btn>
                        <v-autocomplete
                                class="mb-3 position-relative"
                                style="z-index: 2"
                                v-model="selectedModel"
                                :loading="!commonListModelsLoaded"
                                :disabled="!commonListModelsLoaded"
                                density="compact"
                                placeholder="Model"
                                variant="outlined"
                                item-title="nickname"
                                item-value="id"
                                :items="commonListModels"
                                hide-details
                        ></v-autocomplete>
                        <VueDatePicker
                                v-model="selectedMonth"
                                style="z-index: 1"
                                inline
                                class="clearable-false"
                                auto-apply
                                month-picker
                                :clearable="false"
                                :enable-time-picker="false"
                        />
                        <v-divider class="my-6"></v-divider>
                        <div class="text-subtitle-1 font-weight-bold mb-2">Labels filters</div>
                        <v-checkbox
                                v-for="(item, index) in eventTypes"
                                :key="index"
                                v-model="selectedEvents"
                                :label="item.name"
                                :value="item.id"
                                :color="setEventColor(item.name)"
                                density="compact"
                                hide-details
                        ></v-checkbox>
                    </div>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                    <div class="pa-4">
                        <FullCalendar
                                ref="fullCalendar"
                                :options='calendarOptions'
                        >
                            <template v-slot:eventContent='arg'>
                                <v-chip
                                        class="w-100"
                                        :color="setEventColor(arg.event.extendedProps.label?.name)"
                                        :prepend-icon="setEventIcon(arg.event.extendedProps.label?.name)"
                                >
                                    {{ arg.event.title }}
                                </v-chip>
                            </template>
                        </FullCalendar>
                    </div>
                </v-col>
            </v-row>

        </v-sheet>

        <v-dialog
                fullscreen
                persistent
                transition="slide-x-transition"
                max-width="368"
                class="right-position"
                v-model="eventDialogOpen"
        >
            <EventDialog
                    :edited="editedIndex"
                    :info="editedItem"
                    :editing="editMode"
                    :types="eventTypes"
                    :typesRepeat="eventTypesRepeat"
                    :model="selectedModel"
                    @add="handleAddEvent"
                    @delete="handleDeleteEvent"
                    @close="handleEventDialogClose"
            />
        </v-dialog>

    </div>
</template>

<style>
    .fc-header-toolbar .fc-toolbar-chunk {
        display: flex;
        align-items: center;
    }

    .fc-toolbar-title {
        font-size: 1.5rem !important;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: normal !important;
        font-family: "Roboto", sans-serif !important;
        text-transform: none !important;
        font-weight: 700 !important;
    }

    .fc .fc-header-toolbar .fc-button-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background-color: unset;
        border-color: #757575;
        color: #000;
    }

    .fc .fc-header-toolbar .fc-button-primary .fc-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fc-theme-standard .fc-scrollgrid {
        /*border-top: unset;*/
    }

    .fc-col-header-cell {
        /*border: unset !important;*/
    }

    .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end) .v-chip, .fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) .v-chip {
        border-bottom-right-radius: 0px;
        border-right-width: 0px;
        border-top-right-radius: 0px;
    }

    .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start) .v-chip, .fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) .v-chip {
        border-bottom-left-radius: 0px;
        border-left-width: 0px;
        border-top-left-radius: 0px;
    }
</style>