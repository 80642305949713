<script>
export default {
	name: "FeedCard"
}
</script>

<template>
	<v-card rounded="lg" class="pa-4" elevation="0">
		<v-row no-gutters justify="start" align="center" class="mb-4">
			<v-col>
				<div class="d-flex align-center">
					<v-chip class="custom-chip" size="x-large" variant="outlined" color="grey-lighten-3">
						<span class="text-subtitle-2 font-weight-bold text-black">Александр Сергеев</span>
					</v-chip>
					<div class="separator"></div>
					<v-chip variant="flat" size="x-large" color="grey-lighten-3">
						<v-avatar size="24"><v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img></v-avatar><span class="text-subtitle-2 font-weight-bold text-black pl-2">Your.tasha</span>
					</v-chip>
				</div>
			</v-col>
			<v-col cols="auto">
				<div class="text-subtitle-2 text-grey d-flex align-center">
					<v-icon>mdi-history</v-icon><span>17.08.2024 11:20</span>
				</div>
			</v-col>
		</v-row>
		<div class="text-body-1 text-grey-darken-1">
			Очень хочет подключить ОФ ТВ, но пока что ее не берут в продакшн, в связи с тем что она вряд ли сможет сдавать 4 видео в месяц, что есть обязательным, и уже была первая попытка снять видео - получили вообще не то что нужно. Модели мы говорим о том, что нужно подождать конца операции, чтобы не прерываться и делать регулярно 4 видео в месяц.
		</div>
		<div class="d-flex justify-end">
			<v-btn variant="text" icon="mdi-delete"></v-btn>
		</div>
	</v-card>
</template>

<style scoped>
.separator {
	height: 3px;
	width: 24px;
	background-color: #EEEEEE;
}

.custom-chip {
	border-width: 2px;
}

.separator {
	position: relative;

	&:before, &:after {
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		background-color: #EEEEEE;
		border-radius: 100%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&:before {
		left: 0;
	}

	&:after {
		left: 100%;
	}
}
</style>