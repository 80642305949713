<script>
  import apiManagers from "@/api/managers"

  export default {
    name: "EventDialog",


    props: {
      edited: {},
      info: {},
      editing: {},
      types: {},
      typesRepeat: {},
      model: {}
    },


    data() {
      return {
        editMode: false,
        dialogDeleteOpen: false,
        format: 'dd.MM.yyyy',
        formLoading: false,
        fields: {
          title: '',
          label: null,
          repeat: null,
          start: null,
          end: null,
        },
        errors: {}
      }
    },


    created() {
      this.editMode = this.editing;
      if (this.info.start_date) { this.fields.start = this.info.start_date }
      if (this.info.end_date) { this.fields.end = this.info.end_date }
      if (this.info.title) { this.fields.title = this.info.title }
      if (this.info.label) {
        this.fields.labelName = this.info.label.name;
        this.fields.labelId = this.info.label.id;
      }
      if (this.info.repeat) {
        this.fields.repeatName = this.info.repeat.name;
        this.fields.repeatId = this.info.repeat.id;
      }
      if (this.info.description) { this.fields.description = this.info.description }
    },


    methods: {
      formatingDate(date, format) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = (date.getDate().toString().padStart(2, '0'));
        if (format === 'dd.MM.yyyy') {
          return `${day}.${month}.${year}`;
        } else if (format === 'yyyy-MM-dd') {
          return `${year}-${month}-${day}`;
        }
      },

      setEventColor(type) {
        if (type === 'Birthday') return 'deep-purple-accent-2';
        if (type === 'Sick') return 'pink';
        if (type === 'Vacation') return 'green';
        if (type === 'Other') return 'grey-darken-1';
        if (type === '') return 'white'
      },

      setEventIcon(type) {
        if (type === 'Birthday') return 'mdi-cake-variant';
        if (type === 'Sick') return 'mdi-heart-pulse';
        if (type === 'Vacation') return 'mdi-airplane';
        if (type === 'Other') return 'mdi-calendar-range'
      },

      async handleAddEvent() {
        this.errors = {};
        try {
          this.formLoading = true;
          const params = {
            only_fans_model: this.model,
            title: this.fields.title,
            event_type: this.fields.labelId,
            repeat_type: this.fields.repeatId,
            description: this.fields.description,
          };
          if (this.fields.start) { params.start_date = this.formatingDate(this.fields.start, 'yyyy-MM-dd') }
          if (this.fields.end) { params.end_date = this.formatingDate(this.fields.end, 'yyyy-MM-dd') }
          const response = await apiManagers.addEvent(params);
          this.$emit('add', response);
          this.$emit('close')
        } catch (error) {
          console.log('error', error);
          const errors = Object.keys(error.response.data);
          errors.forEach((key) => {
            this.errors[key] = error.response.data[key]
          });
        } finally {
          this.formLoading = false;
        }
      },

      async handleEditEvent() {
        this.errors = {};
        try {
          this.formLoading = true;
          const params = {
            only_fans_model: this.model,
            title: this.fields.title,
            event_type: this.fields.labelId,
            repeat_type: this.fields.repeatId,
            description: this.fields.description,
          };
          if (this.fields.start) { params.start_date = this.formatingDate(this.fields.start, 'yyyy-MM-dd') }
          if (this.fields.end) { params.end_date = this.formatingDate(this.fields.end, 'yyyy-MM-dd') }
          const response = await apiManagers.editEvent(this.info.id, params);
          this.$emit('add', response);
          this.$emit('close')
        } catch (error) {
          console.log('error', error);
          const errors = Object.keys(error.response.data);
          errors.forEach((key) => {
            this.errors[key] = error.response.data[key]
          });
        } finally {
          this.formLoading = false;
        }
      },

      async handleDeleteEvent() {
        try {
          this.formLoading = true;
          await apiManagers.deleteEvent(this.info.id);
          this.dialogDeleteOpen = false;
          this.$emit('delete', this.info.id);
          this.$emit('close')
        } catch(error) {
          console.log('error: ', error);
        } finally {
          this.formLoading = false;
        }
      }
    }
  }
</script>

<template>
    <v-card class="pa-4" color="grey-lighten-4">
        <div class="flex-0-0">
            <v-row align="center" justify="space-between">
                <v-col>
                    <div v-if="edited" class="text-subtitle-1 font-weight-bold">Edit event</div>
                    <div v-else class="text-subtitle-1 font-weight-bold">Add event</div>
                </v-col>
                <v-col v-if="edited" cols="auto">
                    <v-btn @click="dialogDeleteOpen = true" variant="text" size="x-large" density="compact" icon="mdi-delete"></v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
        </div>
        <div class="flex-1-1 overflow-y-auto py-4 px-3 mx-n3">
            <template v-if="editMode">
                <v-text-field
                        v-model="fields.title"
                        label="Title"
                        variant="outlined"
                        :error-messages="errors.title"
                ></v-text-field>
                <v-select
                        v-model="fields.labelId"
                        :items="types"
                        item-title="name"
                        item-value="id"
                        label="Label"
                        variant="outlined"
                        :error-messages="errors.event_type"
                ></v-select>
                <VueDatePicker
                        class="clearable-false"
                        :class="errors.start_date ? 'mb-1' : 'mb-6'"
                        v-model="fields.start"
                        auto-apply
                        :format="format"
                        :clearable="false"
                        :state="(errors.start_date) ? !errors.start_date : null"
                />
                <v-slide-y-transition leave-absolute>
                    <div class="pl-4 text-error text-caption" v-if="errors.start_date" transition="slide-x-transition" style="height: 20px">{{ errors.start_date[0] }}</div>
                </v-slide-y-transition>
                <VueDatePicker
                        class="clearable-false"
                        :class="errors.end_date ? 'mb-1' : 'mb-6'"
                        v-model="fields.end"
                        auto-apply
                        :format="format"
                        :clearable="false"
                        :state="(errors.end_date) ? !errors.end_date : null"
                />
                <v-slide-y-transition leave-absolute>
                    <div class="pl-4 text-error text-caption" v-if="errors.end_date" transition="slide-x-transition" style="height: 20px">{{ errors.end_date[0] }}</div>
                </v-slide-y-transition>
                <v-select
                        v-model="fields.repeatId"
                        :items="typesRepeat"
                        item-title="name"
                        item-value="id"
                        label="Repeat"
                        variant="outlined"
                        :error-messages="errors.repeat_type"
                ></v-select>
                <v-textarea
                        label="Description"
                        v-model="fields.description"
                        variant="outlined"
                        :error-messages="errors.description"
                ></v-textarea>
            </template>
            <template v-else>
                <v-sheet class="py-2 px-3 mb-2" rounded>
                    <div class="text-subtitle-1 font-weight-bold mb-1">Title</div>
                    <div class="text-subtitle-2">{{ fields.title }}</div>
                </v-sheet>
                <v-sheet class="py-2 px-3 mb-2" rounded>
                    <div class="text-subtitle-1 font-weight-bold mb-1">Label</div>
                    <div class="text-subtitle-2"><v-chip :color="setEventColor(fields.labelName)" :prepend-icon="setEventIcon(fields.labelName)">{{ fields.labelName }}</v-chip></div>
                </v-sheet>
                <v-sheet class="py-2 px-3 mb-2" rounded>
                    <div class="text-subtitle-1 font-weight-bold mb-1">Start date</div>
                    <div class="text-subtitle-2">{{ fields.start }}</div>
                </v-sheet>
                <v-sheet class="py-2 px-3 mb-2" rounded>
                    <div class="text-subtitle-1 font-weight-bold mb-1">End date</div>
                    <div class="text-subtitle-2">{{ fields.end }}</div>
                </v-sheet>
                <v-sheet v-if="fields.repeatName" class="py-2 px-3 mb-2" rounded>
                    <div class="text-subtitle-1 font-weight-bold mb-1">Repeat</div>
                    <div class="text-subtitle-2">{{ fields.repeatName }}</div>
                </v-sheet>
                <v-sheet v-if="fields.description" class="py-2 px-3 mb-2" rounded>
                    <div class="text-subtitle-1 font-weight-bold mb-1">Description</div>
                    <div class="text-subtitle-2">{{ fields.description }}</div>
                </v-sheet>
            </template>
        </div>
        <div class="flex-0-0">
            <v-divider></v-divider>
            <v-row v-if="editMode" justify="start" class="mt-3">
                <template v-if="edited">
                    <v-col>
                        <v-btn class="mr-2 w-100" :loading="formLoading" type="submit" color="primary" @click="handleEditEvent">Save</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="w-100" variant="text" @click="editMode = false">Cancel</v-btn>
                    </v-col>
                </template>
                <template v-else>
                    <v-col>
                        <v-btn class="mr-2 w-100" :loading="formLoading" type="submit" color="primary" @click="handleAddEvent">Add</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="w-100" variant="text" @click="$emit('close')">Close</v-btn>
                    </v-col>
                </template>
            </v-row>
            <v-row v-else justify="start" class="mt-3">
                <v-col>
                    <v-btn class="mr-2 w-100" :loading="formLoading" type="submit" color="primary" @click="editMode = true">Edit</v-btn>
                </v-col>
                <v-col>
                    <v-btn class="w-100" variant="text" @click="$emit('close')">Close</v-btn>
                </v-col>
            </v-row>
        </div>

        <v-dialog v-model="dialogDeleteOpen" max-width="400px">
            <v-card class="pa-4">
                <div class="text-body-1 mb-2">Delete event</div>
                <div class="text-subtitle-2">Are you sure?</div>
                <v-row justify="end" no-gutters class="mt-6 pa-0">
                    <v-btn class="mr-2" variant="text" @click="dialogDeleteOpen = false">Cancel</v-btn>
                    <v-btn color="primary" @click="handleDeleteEvent">Agree</v-btn>
                </v-row>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<style scoped>

</style>