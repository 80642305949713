import axios from "@/services/axios-instance";

export default {
  async getScheduleByModel(id) {
    const response = await axios.get(`/v1/schedule-teams/`, { params: { only_fans_model: id } });
    return response.data
  },

  async getShiftIndexes() {
    const response = await axios.get(`/v1/shift-indexes/`);
    return response.data
  },

  async patchField(id, data){
    const response = await axios.patch(`/v1/schedule-teams/${id}/`, data);
    return response.data
  },

  async createScheduleItem(data){
    const response = await axios.post(`/v1/schedule-teams/`, data);
    return response.data
  }
}