import axios from "@/services/axios-instance";

export default {
  async getOperators() {
    const response = await axios.get('/v1/operators/');
    return response.data
  },

  async addOperator(data) {
    const response = await axios.post('/v1/operators/', data);
    return response.data
  },

  async editOperator(data, id) {
    const response = await axios.patch(`/v1/operators/${id}/`, data);
    return response.data
  },

  async deleteOperator(id) {
    await axios.delete(`/v1/operators/${id}/`);
  }
}