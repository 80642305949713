<template>
	<v-autocomplete
			:disabled="!itemsLoaded"
			:loading="!itemsLoaded"
			variant="outlined"
			item-title="full_name"
			item-value="id"
			:items="items"
			placeholder="Marketer"
	></v-autocomplete>
</template>

<script>
import apiAds from "@/api/ads";

export default {
	name: 'SelectMarketer',


	props: {
		selected: {
			type: Object,
			default: null
		}
	},


	data() {
		return {
			items: [],
			itemsLoaded: false,
			itemsLoading: false,
		}
	},


	computed: {

	},

	created() {
		this.getMarketers()
	},


	watch: {},


	methods: {
		async getMarketers() {
			try {
				this.itemsLoaded = false;
				this.itemsLoading = true;
				this.items = await apiAds.getMarketers();
				this.itemsLoaded = true;
				this.itemsLoading = false;
				if (this.selected) {
					const target = this.items.find(item => item.id === this.selected.id);
					if (target === undefined) {
						this.items.push(this.selected)
					}
				}
			} catch (error) {
				console.log('error', error)
			}
		},
	}
}
</script>

<style scoped>

</style>