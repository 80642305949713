export const SET_SHIFTS = 'SET_SHIFTS';
export const SHIFTS_LOADING = 'SHIFTS_LOADING';
export const SHIFTS_LOADED = 'SHIFTS_LOADED';
export const ADD_SHIFT = 'ADD_SHIFT';
export const REMOVE_SHIFT = 'REMOVE_SHIFT';
export const START_SHIFT = 'START_SHIFT';
export const END_SHIFT = 'END_SHIFT';
export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const SET_SHIFTS_HISTORY = 'SET_SHIFTS_HISTORY';
export const SHIFTS_HISTORY_LOADING = 'SHIFTS_HISTORY_LOADING';
export const SHIFTS_HISTORY_LOADED = 'SHIFTS_HISTORY_LOADED';