import axios from "@/services/axios-instance";

export default {
  async getModelGenres() {
    const response = await axios.get('/v1/only-fans-models/genres/');
    return response.data
  },

  async getModelPageTypes() {
    const response = await axios.get('/v1/only-fans-models/page-types/');
    return response.data
  },

  async getModelStatuses() {
    const response = await axios.get('/v1/only-fans-models/statuses/');
    return response.data
  },
}