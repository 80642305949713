<script>
import {mapState} from 'vuex'
import ModelEditDialog from "@/components/ModelEditDialog.vue";

export default {
	name: "ModelsPage",
	components: {
		ModelEditDialog
	},


	data () {
		return {
			dialog: false,
			dialogDelete: false,
			search: '',
			page: 1,
			itemsPerPage: 10,
			loading: false,
			headers: [
				{ title: 'Username OF', align: 'start', key: 'username_of' },
				{ title: 'ID', align: 'start', key: 'model_id' },
				{ title: 'Team lead', align: 'start', key: 'team_lead.full_name' },
				{ title: 'Start_date', align: 'start', key: 'start_date' },
				{ title: 'Status', align: 'start', key: 'status.name' },
				{ title: 'Marketer', align: 'start', key: 'marketer.full_name' },
				{ title: 'Genre', align: 'start', key: 'genre.name' },
				{ title: 'Page type', align: 'start', key: 'page_type.name' },
				{ title: 'Actions', align: 'end', key: 'actions', sortable: false },
			],
			searchItem: [],
			editedItem: {
				username_of: '',
				team_lead: '',
				start_date: '',
				status: '',
				marketer: '',
				genre: '',
				page_type: '',
			},
			defaultItem: {
				username_of: '',
				team_lead: '',
				start_date: '',
				status: '',
				marketer: '',
				genre: '',
				page_type: '',
			},
			editedIndex: -1,
		}
	},


	computed: {
		...mapState({
			models: state => state.user.commonListModels,
			modelsLoading: state => state.user.commonListModelsLoading,
			modelsLoaded: state => state.user.commonListModelsLoaded,
		}),
		pageCount () {
			return Math.ceil(this.filteredItems.length / this.itemsPerPage)
		},
		filteredItems() {
			return this.searchItem.filter((item) => {
				for(let key in item) {
					if (item[key]) {
						if (key !== 'id') {
							if (String(item[key]).toLowerCase().match(this.search.toLowerCase())) return true
						}
					}
				}
			})
		},
	},


	created() {
		this.searchItem = this.models
	},


	watch: {
		modelsLoaded(value) {
			if (value) {
				this.searchItem = this.models
			}
		}
	},


	methods: {
		editItem(item) {
			this.editedIndex = this.models.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1
			})
		},
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">
			<h1 class="text-h4 mb-8">Models</h1>

			<v-data-table
					class="py-2 px-4 elevation-1"
					:loading="modelsLoading"
					v-model:page.sync="page"
					v-model:items-per-page="itemsPerPage"
					:headers="headers"
					:items="filteredItems"
					item-value="name"
			>
				<template v-slot:top>
					<v-row class="mb-4" no-gutters align="end" justify="space-between">
						<v-col>
							<v-responsive max-width="400">
								<v-text-field
										v-model="search"
										append-inner-icon="mdi-magnify"
										label="Search"
										variant="underlined"
										hide-details
								></v-text-field>
							</v-responsive>
						</v-col>
					</v-row>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon="mdi-pencil" variant="text" @click="editItem(item.raw)"></v-btn>
				</template>
				<template v-slot:bottom></template>
			</v-data-table>

			<v-pagination variant="elevated" active-color="primary" size="default" density="comfortable" class="mt-4" v-model="page" :length="pageCount"></v-pagination>

			<v-dialog v-model="dialog" fullscreen persistent transition="slide-x-transition" max-width="700px" class="right-position">
				<ModelEditDialog
						@close="close"
						:edited="editedIndex"
						:info="editedItem"
				/>
			</v-dialog>

		</div>
	</div>
</template>

<style scoped>

</style>