// import axios from '@/services/axios-instance'
import api from '@/api/user'
import {
  SET_FROM_STORAGE,
  SET_AUTH,
  SET_USER_DATA,
  USER_LOADING,
  USER_LOADED,
  REMOVE_USER,
  SET_MY_GROUP_DATA,
  MY_GROUP_LOADING,
  MY_GROUP_LOADED,
  SET_TEAM_LEADS,
  TEAM_LEADS_LOADING,
  TEAM_LEADS_LOADED,
  SET_ACTIVE_LEAD,
  SET_COMMON_LIST_MODELS,
  COMMON_LIST_MODELS_LOADING,
  COMMON_LIST_MODELS_LOADED,
  EDIT_MODEL,

} from './constants'
import { RESET_STATE } from '@/store/constants'
import resetState from '@/store/helpers/resetState'

/*--------------------------Initial State------------------------------*/

export const initialState = {
  info: {},
  token: '',
  authenticated: false,
  userLoading: false,
  userLoaded: false,
  myGroup: [],
  myGroupLoading: false,
  myGroupLoaded: false,
  teamLeads: [],
  teamLeadsLoading: false,
  teamLeadsLoaded: false,
  activeLead: '0',
  commonListModels: [],
  commonListModelsLoading: false,
  commonListModelsLoaded: false,
};

/*----------------------------Mutations----------------------------*/

const mutations = {
  [SET_FROM_STORAGE](state, data) {
    state.token = data.token;
    if (data.authenticated === 'true') {
      state.authenticated = true
    } else {
      state.authenticated = false
    }
  },
  [SET_AUTH](state, data) {
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
    localStorage.setItem('authenticated', true);
    localStorage.setItem('user', JSON.stringify(data.user));
    state.token = data.access;
    state.authenticated = true
  },
  [SET_USER_DATA](state, data) {
    state.info = data
  },
  [USER_LOADING](state, value) {
    state.userLoading = value
  },
  [USER_LOADED](state, value) {
    state.userLoaded = value
  },
  [SET_MY_GROUP_DATA](state, data) {
    state.myGroup = data
  },
  [MY_GROUP_LOADING](state, value) {
    state.myGroupLoading = value
  },
  [MY_GROUP_LOADED](state, value) {
    state.myGroupLoaded = value
  },
  [SET_TEAM_LEADS](state, data) {
    state.teamLeads = data
  },
  [TEAM_LEADS_LOADING](state, value) {
    state.teamLeadsLoading = value
  },
  [TEAM_LEADS_LOADED](state, value) {
    state.teamLeadsLoaded = value
  },
  [SET_ACTIVE_LEAD](state, value) {
    state.activeLead = value
  },
  [SET_COMMON_LIST_MODELS](state, data) {
    state.commonListModels = data
  },
  [COMMON_LIST_MODELS_LOADING](state, value) {
    state.commonListModelsLoading = value
  },
  [COMMON_LIST_MODELS_LOADED](state, value) {
    state.commonListModelsLoaded = value
  },

  [EDIT_MODEL](state, model) {
    const index = state.commonListModels.findIndex(item => item.id === model.id);
    if (index !== -1) {
      state.commonListModels[index] = model
    }
  },
  [REMOVE_USER]() {
    localStorage.setItem('access_token', '');
    localStorage.setItem('refresh_token', '');
    localStorage.setItem('authenticated', false);
    localStorage.setItem('user', '');
    // state.token = '';
    // state.authenticated = false
    // delete axios.headers.authorization
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  },
};

/*----------------------------Actions----------------------------*/

const actions = {
  setStorageData({commit}, data) {
    commit(SET_FROM_STORAGE, data)
  },
  async authorize({commit}, {email, password}) {
    const data = await api.authorize({email, password});
    commit(SET_AUTH, data);
    return data
  },
  async getUserData({commit}) {
    commit(USER_LOADING, true);
    try {
      const data = await api.getUserData();
      commit(SET_USER_DATA, data)
      commit(USER_LOADED, true)
    } catch (err) {
      console.log('get user error: ', err)
    } finally {
      commit(USER_LOADING, false);
    }
  },
  async getMyGroupData({commit}) {
    commit(MY_GROUP_LOADING, true);
    try {
      const data = await api.getMyGroupData();
      commit(SET_MY_GROUP_DATA, data);
      commit(MY_GROUP_LOADING, false);
      commit(MY_GROUP_LOADED, true);
    } catch(err) {
      console.log('get my group error: ', err)
    }
  },
  async getCommonListModels({commit}) {
    commit(COMMON_LIST_MODELS_LOADING, true);
    try {
      const data = await api.getCommonListModels();
      commit(SET_COMMON_LIST_MODELS, data);
      commit(COMMON_LIST_MODELS_LOADING, false);
      commit(COMMON_LIST_MODELS_LOADED, true);
    } catch (err) {
      console.log('get common list models: ', err)
    }
  },
  async editModel({commit}, data) {
    const info = await api.editModel(data.fields, data.id);
    commit(EDIT_MODEL, info)
  },
  async getTeamLeads({commit}) {
    commit(TEAM_LEADS_LOADING, true);
    try {
      const data = await api.getTeamLeads();
      commit(SET_TEAM_LEADS, data);
      commit(TEAM_LEADS_LOADING, false);
      commit(TEAM_LEADS_LOADED, true);
    } catch (err) {
      console.log('get team leads error: ', err)
    }
  },
  resetState({commit}) {
    commit(REMOVE_USER);
    commit(RESET_STATE)
  },
};

/*----------------------------Getters----------------------------*/

const getters = {

  getOperators(state) {
    let leadId = null;
    if (state.info.role === 'senior_operator') {
      if (state.info.parent) {
        leadId = state.info.parent.id;
      }
    }
    if (state.info.role === 'team_lead') {
      leadId = state.info.id;
    }
    if (state.info.role === 'superuser' || state.info.role === 'spectator') {
      const operatorsList = [];
      state.myGroup.forEach((item) => {
        if (item.operators.length > 0) {
          operatorsList.push({ props: { divider: true } });
          operatorsList.push({ props: { header: item.full_name} });
          operatorsList.push.apply(operatorsList, item.operators);
        }
      })
      return operatorsList;
    }
    if (leadId) {
      const thisLead = state.myGroup.find(el => el.id === leadId);
      const thisLeadIndex = state.myGroup.findIndex(el => el.id === leadId);
      const operatorsList = Object.assign([], thisLead.operators);
      const otherLeads = Object.assign([], state.myGroup);
      otherLeads.splice(thisLeadIndex, 1);
      otherLeads.forEach((item) => {
        if (item.operators.length > 0) {
          operatorsList.push({ props: { divider: true } });
          operatorsList.push({ props: { header: item.full_name} });
          operatorsList.push.apply(operatorsList, item.operators);
        }
      });
      return operatorsList;
    } else {
      return []
    }
  },

  getOwnOperators(state) {
    let leadId = null;
    if (state.info.role === 'senior_operator') {
      if (state.info.parent) {
        leadId = state.info.parent.id;
      }
    }
    if (state.info.role === 'team_lead') {
      leadId = state.info.id;
    }
    const thisLead = state.myGroup.find(el => el.id === leadId);
    const operatorsList = Object.assign([], thisLead.operators);
    return operatorsList;
  },

  getModels(state) {
    let leadId = null;
    if (state.info.role === 'senior_operator') {
      if (state.info.parent) {
        leadId = state.info.parent.id;
      }
    }
    if (state.info.role === 'team_lead') {
      leadId = state.info.id;
    }
    if (state.info.role === 'superuser' || state.info.role === 'spectator') {
      const modelsList = [];
      state.myGroup.forEach((item) => {
        if (item.models.length > 0) {
          modelsList.push({ props: { divider: true } });
          modelsList.push({ props: { header: item.full_name} });
          modelsList.push.apply(modelsList, item.models);
        }
      })
      return modelsList;
    }
    if (leadId) {
      const thisLead = state.myGroup.find(el => el.id === leadId);
      const thisLeadIndex = state.myGroup.findIndex(el => el.id === leadId);
      const modelsList = Object.assign([], thisLead.models);
      const otherLeads = Object.assign([], state.myGroup);
      otherLeads.splice(thisLeadIndex, 1);
      otherLeads.forEach((item) => {
        if (item.models.length > 0) {
          modelsList.push({ props: { divider: true } });
          modelsList.push({ props: { header: item.full_name} });
          modelsList.push.apply(modelsList, item.models);
        }
      });
      console.log(state.myGroup)
      return modelsList;
    } else {
      return []
    }
  },

  getUngroupedModels(state) {
    let modelsList = [];
    if (state.info.role === 'senior_operator') {
      const leadId = state.info.parent.id;
      const thisLead = state.myGroup.find(el => el.id === leadId);
      modelsList = Object.assign([], thisLead.models);
      modelsList.forEach((model) => {
        model.teamlead = { id: state.info.id, full_name: state.info.full_name }
      })
    }
    if (state.info.role === 'team_lead') {
      const leadId = state.info.id;
      const thisLead = state.myGroup.find(el => el.id === leadId);
      modelsList = Object.assign([], thisLead.models);
      modelsList.forEach((model) => {
        model.teamlead = { id: state.info.id, full_name: state.info.full_name }
      })
    }
    if (state.info.role === 'superuser') {
      state.myGroup.forEach((item) => {
        if (item.models.length > 0) {
          let updatedList = [];
          item.models.forEach((model) => {
            let newItem = Object.assign({}, model);
            newItem.teamlead = { id: item.id, full_name: item.full_name }
            updatedList.push(newItem)
          })
          modelsList.push.apply(modelsList, updatedList);
        }
      })
    }
    return modelsList;
  },

  getModelObjectById: (state) => (id) => {
    return state.commonListModels.find(el => el.id === id)
  },

  getShiftNumbers(state) {
    let leadId = null;
    if (state.info.role === 'senior_operator') {
      if (state.info.parent) {
        leadId = state.info.parent.id;
      }
    }
    if (state.info.role === 'team_lead') {
      leadId = state.info.id;
    }
    if (state.info.role === 'superuser' || state.info.role === 'spectator') {
      let shiftNumbers = [];
      state.myGroup.forEach((item) => {
        if (item.shift_numbers.length > 0) {
          shiftNumbers.push.apply(shiftNumbers, item.shift_numbers);
        }
      })
      const table = {};
      shiftNumbers = shiftNumbers.filter(({id}) => (!table[id] && (table[id] = 1)));
      return shiftNumbers
    }
    if (leadId) {
      const thisLead = state.myGroup.find(el => el.id === leadId);
      const unsortedShiftNumbers = thisLead.shift_numbers;
      return unsortedShiftNumbers.sort((item1, item2) => item1['number'] > item2['number'] ? 1 : -1);
    } else {
      return []
    }
  },

  getOwnShiftNumbers(state) {
    const leadId = state.info.id;
    const thisLead = state.myGroup.find(el => el.id === leadId);
    return Object.assign([], thisLead.shift_numbers);
  },

  getLeads(state) {
    let leads = [];
    state.myGroup.forEach((item) => {
      leads.push({full_name: item.full_name, id: item.id})
    });
    return leads;
  },

};

/*----------------------------Export----------------------------*/

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
  getters,
}