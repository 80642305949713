<template>

		<v-container fill-height>
			<v-layout align-center>
				<div>
					<h3 class="display-3">Error {{status}}!</h3>

					<span class="subheading">{{message}}</span>

					<v-divider class="my-3"></v-divider>

					<v-btn color="primary" :to="({ name: 'Dashboard' })">Main page</v-btn>

					<template v-if="status == 401">
						<div class="title mb-3">Для продолжения работы нужно войти на сайт</div>

						<v-btn
								class="mx-0"
								color="primary"
								large
								@click="openLoginForm"
						>
							Войти
						</v-btn>
					</template>
				</div>
			</v-layout>
		</v-container>

</template>

<script>
  export default {
    name: 'ErrorPage',


    computed: {
      status() {
        return this.$route.query.status || 404
      },
      message() {
        return this.$route.query.message || 'Information not found'
      }
    },


    methods: {
      openLoginForm() {
        console.log('click');
      }
    }
  }
</script>

<style scoped>

</style>