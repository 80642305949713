<template>
	<v-form v-model="form" class="form" @submit.prevent>
		<div class="form__input">
			<v-text-field
					class="mb-2"
					v-model="email"
					:rules="[rules.required]"
					:error-messages="formError"
					label="Email"
					required
					@input="formError = ''"
			></v-text-field>
		</div>
		<div class="form__input">
			<v-text-field
					class="mb-2"
					v-model="password"
					:rules="[rules.required]"
					:error-messages="formError"
					label="Password"
					type="password"
					autocomplete="on"
					required
					@input="formError = ''"
			></v-text-field>
		</div>
		<div class="form__submit">
			<v-btn
					:disabled="!form"
					block
					type="submit"
					size="x-large"
					color="primary"
					@click="submit"
			>
				Log in
			</v-btn>
		</div>
		<template v-slot:errors>
			sdfgsdfg
		</template>
	</v-form>
</template>

<script>

  export default {
    name: 'AuthForm',


    components: {

    },


    data() {
      return {
        form: false,
        formError: '',
        // email: 'jane@doe.ff',
        // password: 'qwertyqwerty',
        email: '',
        password: '',
        rules: {
          required: value => {
            if (value) return true;
            return 'Required field'
          },
        },
      }
    },


    methods: {
      async submit () {
        try {
          await this.$store.dispatch('user/authorize', {
            email: this.email,
            password: this.password
          })
        } catch(error) {
          console.log('auth error!!!!!!!!!!!! ', error.response.data.detail);
          this.formError = error.response.data.detail
        } finally {
          this.$router.push('/')
        }
      },
    }
  }
</script>

<style scoped>

</style>