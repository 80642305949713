<template>
	<v-combobox
			v-if="creation"
			:disabled="!itemsLoaded"
			:loading="!itemsLoaded"
			variant="outlined"
			item-title="name"
			item-value="name"
			:items="items"
			placeholder="Platform Type"
	></v-combobox>
	<v-autocomplete
			v-else
			:disabled="!itemsLoaded"
			:loading="!itemsLoaded"
			variant="outlined"
			item-title="name"
			item-value="id"
			:items="items"
			placeholder="Platform Type"
	></v-autocomplete>
</template>

<script>
import apiAds from "@/api/ads";

export default {
	name: 'SelectPlatformType',


	props: {
		creation: {
			type: Boolean,
			default: false,
		}
	},


	data() {
		return {
			items: [],
			itemsLoaded: false,
			itemsLoading: false,
		}
	},


	computed: {

	},

	created() {
		this.getPlatformTypes()
	},


	watch: {},


	methods: {
		async getPlatformTypes() {
			try {
				this.itemsLoaded = false;
				this.itemsLoading = true;
				this.items = await apiAds.getPlatformTypes();
				this.itemsLoaded = true;
				this.itemsLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},
	}
}
</script>

<style scoped>

</style>