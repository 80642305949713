import api from '@/api/operators'
import {
  SET_OPERATORS,
  OPERATORS_LOADING,
  OPERATORS_LOADED,
  ADD_OPERATOR,
  EDIT_OPERATOR,
  DELETE_OPERATOR,
} from './constants'
import { RESET_STATE } from '@/store/constants'
import resetState from '@/store/helpers/resetState'

/*--------------------------Initial State------------------------------*/

export const initialState = {
  operators: [],
  operatorsLoading: false,
  operatorsLoaded: false,
};

/*----------------------------Mutations----------------------------*/

const mutations = {
  [SET_OPERATORS](state, data) {
    state.operators = data
  },
  [OPERATORS_LOADING](state, value) {
    state.operatorsLoading = value
  },
  [OPERATORS_LOADED](state, value) {
    state.operatorsLoaded = value
  },
  [ADD_OPERATOR](state, operator) {
    const target = state.operators.find(item => item.id === operator.id);
    if (target === undefined) {
      state.operators.push(operator)
    }
  },
  [EDIT_OPERATOR](state, operator) {
    const index = state.operators.findIndex(item => item.id === operator.id);
    if (index !== -1) {
      state.operators[index] = operator
    }
  },
  [DELETE_OPERATOR](state, id) {
    const index = state.operators.findIndex(operator =>operator.id === id);
    if (index !== -1) {
      state.operators.splice(index, 1)
    }
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  },
};

/*----------------------------Actions----------------------------*/

const actions = {
  async getOperators({commit}) {
    commit(OPERATORS_LOADING, true);
    try {
      const data = await api.getOperators();
      await commit(SET_OPERATORS, data);
      commit(OPERATORS_LOADED, true)
    } catch (err) {
      console.log('get operators error: ', err)
    } finally {
      commit(OPERATORS_LOADING, false);
    }
  },
  async addOperator({commit}, data) {
    const info = await api.addOperator(data);
    commit(ADD_OPERATOR, info)
  },
  async editOperator({commit}, data) {
    const info = await api.editOperator(data.fields, data.id);
    commit(EDIT_OPERATOR, info)
  },
  async deleteOperator({commit}, id) {
    await api.deleteOperator(id);
    commit(DELETE_OPERATOR, id)
  },
  resetState({commit}) {
    commit(RESET_STATE)
  },
};

/*----------------------------Getters----------------------------*/

const getters = {};

/*----------------------------Export----------------------------*/

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
  getters,
}