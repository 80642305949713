<script>
import FeedCard from "@/components/feed/FeedCard.vue";

export default {
	name: "FeedList",


	components: {
		FeedCard
	}
}
</script>

<template>
	<div>
		<template v-for="(item, index) in 3" :key="index">
			<div :class="index < 3 - 1 ? 'mb-3' : ''">
				<FeedCard />
			</div>
		</template>
	</div>
</template>

<style scoped>

</style>