<script>
import {useDisplay} from "vuetify";
import apiManagers from "@/api/managers";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},

	name: "DatabookFiles",


	props: {
		id: {}
	},


	data() {
		return {
			file: null,
			dialogDelete: false,
			editedIndex: -1,
			editedFile: null,
			rules: {
				required: value => {
					if (value) return true;
					return 'Required field'
				},
			},
			tableHeadCellClass: 'pa-3 text-subtitle-1 font-weight-bold text-grey-darken-1',
			tableCellClass: 'pa-3',
			sizes: {
				type: '10',
				name: '40',
				date: '20',
				by: '30',
				action: '10',
			},
			sizesMobile: {
				type: '10',
				name: '20',
				date: '15',
				by: '20',
				action: '100',
			},
			items: [],
			itemsLoading: false,
			itemsLoaded: false,
			deleteLoading: false,
			uploadLoading: false,
			errors: {}
		}
	},


	created() {
		this.getFiles()
	},


	methods: {
		setColor(type) {
			if (type === 'jpg' || type === 'jpeg') { return 'bg-blue-lighten-5' }
			else if (type === 'doc' || type === 'docx') { return 'bg-blue-lighten-5' }
			else if (type === 'pdf') { return 'bg-red-lighten-5' }
			else { return 'bg-grey-lighten-4' }
		},
		setIcon(type) {
			if (type === 'jpg' || type === 'jpeg') { return 'jpg' }
			else if (type === 'pdf') { return 'pdf' }
			else if (type === 'doc' || type === 'docx') { return 'doc' }
			else { return 'jpg' }
		},
		async getFiles() {
			try {
				this.itemsLoading = true;
				this.itemsLoaded = false;
				const data = {
					model_info: this.id
				}
				this.items = await apiManagers.getFiles(data);
				this.itemsLoading = false;
				this.itemsLoaded = true;
			} catch (error) {
				console.log(error)
			}
		},
		async upload() {
			try {
				this.errors = {};
				this.uploadLoading = true;
				const data = {
					file: this.file[0],
					file_name: this.file[0].name.split('.')[0],
					model_info: this.id
				}
				const response = await apiManagers.addFile(data);
				this.items.push(response)
				this.file = null;
			} catch (error) {
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.uploadLoading = false;
			}
		},
		openDialogDelete(id) {
			this.editedFile = id;
			this.dialogDelete = true
		},
		closeDialogDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedFile = null
			})
		},
		async deleteFileConfirm() {
			try {
				this.deleteLoading = true;
				await apiManagers.deleteFile(this.editedFile);
				const index = this.items.findIndex(item => item.id === this.editedFile);
				if (index !== -1) {
					this.items.splice(index, 1)
				}
				this.closeDialogDelete();
			} catch (error) {
				console.log('delete file error: ', error)
			} finally {
				this.deleteLoading = false;
			}
		},
		decode(base64) {
			return atob(base64)
		}
	},
}
</script>

<template>
	<div>

		<v-form :disabled="uploadLoading" @submit.prevent>
			<v-row>
				<v-col>
					<v-file-input
							:rules="[rules.required]"
							v-model="file"
							variant="outlined"
							label="File input"
							:error-messages="errors.file || errors.file_name"
					></v-file-input>
				</v-col>
				<v-col>
					<v-btn color="primary" size="large" height="56" prepend-icon="mdi-cloud-upload-outline" type="submit" @click="upload" :loading="uploadLoading">Upload</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<v-sheet class="rounded-lg overflow-hidden">
			<div class="d-flex bg-grey-lighten-2 align-center px-8">
				<div
						:class="tableHeadCellClass"
						:style="mdAndDown ? { width: sizesMobile.type + 'px' } : { width: sizes.type + '%' }"
				>
					Files
				</div>
				<div
						:class="tableHeadCellClass"
						:style="mdAndDown ? { width: sizesMobile.name + 'px' } : { width: sizes.name + '%' }"
				>
					File name
				</div>
				<div
						:class="tableHeadCellClass"
						:style="mdAndDown ? { width: sizesMobile.date + 'px' } : { width: sizes.date + '%' }"
				>
					Date of uploaded
				</div>
				<div
						:class="tableHeadCellClass"
						:style="mdAndDown ? { width: sizesMobile.by + 'px' } : { width: sizes.by + '%' }"
				>
					Uploaded by
				</div>
				<div
						:class="tableHeadCellClass"
						:style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }"
				></div>
			</div>

			<div v-if="itemsLoaded" class="px-8">
				<template v-if="items.length > 0">
					<template
							v-for="(item, index) in items"
							:key="index"
					>
						<div class="d-flex align-center">
							<div
									class="text-subtitle-2 d-flex justify-start"
									:class="tableCellClass"
									:style="mdAndDown ? { width: sizesMobile.type + 'px' } : { width: sizes.type + '%' }"
							>
								<div class="bg-red-lighten-5 pa-4 rounded-lg" :class="setColor(item.file_extension)"><img class="d-block" :src="require(`@/assets/icon/${setIcon(item.file_extension)}.svg`)" alt=""></div>
							</div>
							<div
									class="text-subtitle-2"
									:class="tableCellClass"
									:style="mdAndDown ? { width: sizesMobile.name + 'px' } : { width: sizes.name + '%' }"
							>{{ item.file_name }}.{{ item.file_extension }}</div>
							<div
									class="text-subtitle-2"
									:class="tableCellClass"
									:style="mdAndDown ? { width: sizesMobile.date + 'px' } : { width: sizes.date + '%' }"
							>{{ item.upload_date }}</div>
							<div
									class="text-subtitle-2"
									:class="tableCellClass"
									:style="mdAndDown ? { width: sizesMobile.by + 'px' } : { width: sizes.by + '%' }"
							>{{ item.user.full_name }}</div>
							<div
									class="d-flex justify-end align-center"
									:class="tableCellClass"
									:style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }"
							>
								<v-btn icon="mdi-download" variant="text" color="grey" :href="decode(item.file)" download></v-btn>
								<v-btn icon="mdi-delete" variant="text" color="grey" @click="openDialogDelete(item.id)"></v-btn>
							</div>
						</div>
						<v-divider v-if="index + 1 < items.length"></v-divider>
					</template>
				</template>
				<div v-else class="py-10 text-body-1 text-center">
					<span>...</span><br>
					<span>Empty list. Please, upload files</span>
				</div>
			</div>

			<v-skeleton-loader v-else type="table-tbody" class="mb-6"></v-skeleton-loader>

		</v-sheet>

		<v-dialog v-model="dialogDelete" max-width="400px">
			<v-card :disabled="deleteLoading" class="pa-4">
				<div class="text-body-1 mb-2">Delete file</div>
				<div class="text-subtitle-2">Are you sure?</div>
				<v-row justify="end" no-gutters class="mt-6 pa-0">
					<v-btn class="mr-2" variant="text" @click="closeDialogDelete">Cancel</v-btn>
					<v-btn :loading="deleteLoading" color="primary" @click="deleteFileConfirm">Confirm</v-btn>
				</v-row>
			</v-card>
		</v-dialog>

	</div>
</template>

<style scoped>

</style>