<template>
	<div class="fill-height d-flex flex-column">

		<div class="flex-1-1 d-flex flex-column position-relative">

			<div class="fill-height w-100 position-absolute" style="overflow-y: scroll">
				<div ref="wrapper" class="page-container pt-4 pb-15">
					<div class="page-wrap">

						<h1 class="text-h4 mb-8">Бюджет маркетинга</h1>

						<v-row>
							<v-col>
								<v-btn v-if="user.role === 'hom'" :disabled="!periodLoaded" prepend-icon="mdi-plus" variant="outlined" color="primary" @click="dialogEditModel = true">Добавить модель</v-btn>
							</v-col>
							<v-col cols="auto" style="width: 220px">
								<v-select
										label="Promo type"
										density="compact"
										v-model="type"
										variant="outlined"
										:items="['all', 'friends for model']"
										hide-details
										:disabled="!periodLoaded&& !commonListModelsLoaded"
										:loading="!periodLoaded && !commonListModelsLoaded"
								></v-select>
							</v-col>
							<v-col cols="auto" style="width: 350px">
								<div>
									<v-text-field
											:disabled="!periodLoaded"
											v-model="search"
											variant="underlined"
											label="Поиск по модели"
											append-inner-icon="mdi-magnify"
											density="compact"
									></v-text-field>
								</div>
							</v-col>
							<v-col cols="auto">
								<v-btn :loading="!copyLoaded" :disabled="!copyLoaded" color="primary" @click="copyTable">Copy table</v-btn>
							</v-col>
						</v-row>

						<div>

							<div class="d-flex" :class="{'w-fit': mdAndDown}">
								<v-btn
										variant="text"
										v-for="(item, index) in heads"
										:key="index"
										class="px-2 text-body-1 font-weight-medium cursor-pointer justify-start"
										:class="(ordering === item || ordering === '-' + item) ? 'text-primary' : ''"
										:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
										@click="setOrdering(item)"
								>
									<template v-slot:append>
										<v-icon v-if="ordering === item">mdi-chevron-up</v-icon>
										<v-icon v-if="ordering === '-' + item">mdi-chevron-down</v-icon>
									</template>
									{{headName(item)}}
								</v-btn>
							</div>

							<template v-if="periodsLoaded && commonListModelsLoaded">

								<template v-if="periods.length > 0">
									<template v-if="periodLoaded">
										<template v-if="items.length > 0">
											<template v-if="filteredItems.length > 0">
												<template v-for="(item, index) in filteredItems" :key="index">
													<v-hover>
														<template v-slot:default="{ isHovering, props }">
															<div
																	v-bind="props"
																	class="d-flex elevation-1 rounded-lg mb-2 transition"
																	:class="[{'w-fit': mdAndDown}, setItemBgColor(index, isHovering), {'cursor-pointer': user.role === 'hom'}]"
																	@click="user.role === 'hom' ? openEditModel(item) : {}"
															>
																<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.marketer + '%' }">{{ item.marketer }}</div>
																<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.model + '%' }">{{ item.only_fans_model.nickname }}</div>
																<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.budget + 'px' } : { width: sizes.budget + '%' }">{{ item.cost_plan }}</div>
																<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.expenses + 'px' } : { width: sizes.expenses + '%' }">{{ item.cost_fact }}</div>
																<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.linebar + 'px' } : { width: sizes.linebar + '%' }">
																	<div class="d-flex align-center">
																		<div style="width: 80px">
																			<v-progress-linear
																					:model-value="calcValue(item.cost_plan, item.cost_fact)"
																					:color="calcColor(item.cost_plan, item.cost_fact)"
																					rounded
																					height="12"
																			></v-progress-linear>
																		</div>
																		<span class="ml-2 text-no-wrap font-weight-bold" :class="'text-' + calcColor(item.cost_plan, item.cost_fact)">{{calcValue(item.cost_plan, item.cost_fact)}}%</span>
																	</div>
																</div>
																<div class="px-4 d-flex justify-end align-center" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }">
																	<v-btn v-if="user.role === 'hom'" color="grey" density="comfortable" icon="mdi-delete" variant="text" @click.stop="deleteModel(item)" ></v-btn>
																</div>
															</div>
														</template>
													</v-hover>
												</template>
											</template>
											<template v-else>
												<div class="mt-10 text-body-1 text-center">
													<span>...</span><br>
													<span>Моделей по заданному запросу не обнаружено</span>
												</div>
											</template>
										</template>
										<template v-else>
											<div class="mt-10 text-body-1 text-center">
												<span>...</span><br>
												<span>Добавьте модель, чтобы начать заполнять план</span>
											</div>
										</template>
									</template>
									<v-skeleton-loader v-else type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>
								</template>

								<template v-else>
									<div class="mt-10 text-body-1 text-center">
										<span>...</span><br>
										<span>Создайте первый план на месяц, чтобы начать работу</span>
									</div>
								</template>

							</template>

						</div>

						<v-dialog v-model="dialogDeleteModel" max-width="340px">
							<v-card class="pa-6">
								<div class="text-body-1 mb-2">Удалить запись из плана</div>
								<div class="text-subtitle-2">Вы уверены?</div>
								<v-row justify="end" no-gutters class="mt-10 pa-0">
									<v-btn color="primary" class="mr-2" variant="text" @click="closeDeleteModel">Отмена</v-btn>
									<v-btn color="primary" @click="deleteModelConfirm">Подтвердить</v-btn>
								</v-row>
							</v-card>
						</v-dialog>

						<v-dialog v-model="dialogEditModel" max-width="450px">
							<BudgetModelEditDialog
									:edited="editedModelIndex"
									:info="editedModel"
									:type="type"
									:period="period.id"
									:selected="selectedModels"
									@close="closeEditModel"
									@add="addModel"
									@edit="editModel"
							/>
						</v-dialog>

						<v-dialog v-model="dialogEditPlan" fullscreen persistent transition="slide-x-transition" max-width="1200px" class="right-position">
							<BudgetEditDialog
									:edited="editedPlanIndex"
									:info="editedPlan"
									:type="type"
									:periods="periods"
									@close="closeEditPlan"
									@add="addPlan"
									@edit="editPlan"
							/>
						</v-dialog>

					</div>
				</div>
			</div>

			<div class="page-container w-100 position-absolute" style="bottom: 0; z-index: 1" :style="`max-width: ${wrapperWidth}px`">
				<div class="d-flex mb-2 transition">
					<div class="pr-2" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.marketer + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Маркетологов:</span> {{ budgetTotalValues.marketersCount }}</div>
					</div>
					<div class="pr-2" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.model + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Моделей:</span> {{ budgetTotalValues.modelsCount }}</div>
					</div>
					<div class="pr-2" :style="mdAndDown ? { width: sizesMobile.budget + 'px' } : { width: sizes.budget + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Сумма:</span> {{ budgetTotalValues.budget }}</div>
					</div>
					<div class="pr-2" :style="mdAndDown ? { width: sizesMobile.expenses + 'px' } : { width: sizes.expenses + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Сумма:</span> {{ budgetTotalValues.expenses }}</div>
					</div>
				</div>
			</div>

		</div>

		<div class="flex-0-0">
			<div class="d-flex align-center bg-white">
				<v-skeleton-loader v-if="!periodsLoaded" type="avatar@2, chip@4" class="bg-transparent w-100"></v-skeleton-loader>
				<template v-else>
					<div v-if="user.role === 'hom'" class="d-flex pa-2 pr-6">
						<v-btn :disabled="!periods.length > 0" class="mr-2" icon="mdi-pencil" variant="text" color="primary" @click="openEditPlan"></v-btn>
						<v-btn icon="mdi-plus" variant="text" color="primary" @click="dialogEditPlan = true"></v-btn>
					</div>
					<template v-if="periods.length > 0">
						<v-tabs
								center-active
								color="primary"
								v-model="tab"
						>
							<v-tab v-for="(item, index) in periods" :key="index" :value="item.id">{{ item.name }}</v-tab>
						</v-tabs>
					</template>
					<template v-else>
						Список планов пуст. Добавьте новый план на месяц.
					</template>
				</template>
			</div>
		</div>

	</div>
</template>

<script>
import apiBudget from '@/api/budget';
import BudgetModelEditDialog from '@/components/BudgetModelEditDialog.vue';
import BudgetEditDialog from '@/components/BudgetEditDialog.vue';
import {useDisplay} from "vuetify";
import {mapState} from "vuex";

export default ({
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: 'ModelPlanPage',


	components: {
		BudgetModelEditDialog,
		BudgetEditDialog,
	},


	data() {
		return {
			type: 'friends for model',
			heads: ['marketer', 'model', 'budget', 'expenses', 'linebar', 'action'],
			sizes: {
				marketer: '20',
				model: '20',
				budget: '15',
				expenses: '15',
				linebar: '15',
				action: '15',
			},
			sizesMobile: {
				marketer: '200',
				model: '200',
				budget: '150',
				expenses: '150',
				linebar: '150',
				action: '100',
			},
			tableCellClass: 'px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2',
			dialogDeleteModel: false,
			dialogEditModel: false,
			dialogEditPlan: false,
			search: '',
			searchItem: [],
			ordering: 'model',
			tab: '',
			editedModelIndex: -1,
			editedModel: '',
			defaultModel: '',
			periods: [],
			periodsLoading: false,
			periodsLoaded: false,
			editedPlanIndex: -1,
			editedPlan: '',
			defaultPlan: '',
			period: null,
			periodLoaded: false,
			items: [],
			wrapperWidth: 0,
			budgetTotalValues: {},
			copyLoading: false,
			copyLoaded: false,
			copy: null,
		}
	},


	computed: {
		...mapState({
			user: state => state.user.info,
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
		filteredItems() {
			return this.searchItem.filter((item) => {
				if (item.only_fans_model.nickname.toLowerCase().match(this.search)) return true
			});
		},
		selectedModels() {
			let result = [];
			this.items.forEach(item => {
				result.push(item.only_fans_model.nickname);
			})
			return result
		}
	},


	created() {
		this.getPeriods();
	},

	mounted() {
		this.wrapperWidth = this.$refs.wrapper.getBoundingClientRect().width
	},


	watch: {
		tab() {
			if (this.commonListModelsLoaded) {
				this.getPeriod();
				this.getPeriodCopy()
			}
		},
		type() {
			if (this.commonListModelsLoaded) {
				this.getPeriod();
				this.getPeriodCopy()
			}
		},
		ordering() {
			this.sortingTable();
		},
		periodLoaded() {
			this.sortingTable();
		},
		commonListModelsLoaded() {
			this.getPeriod();
			this.getPeriodCopy()
		},
		items: {
			handler() {
				this.getPeriodCopy()
			},
			deep: true,
		},
		filteredItems() {
			this.calcTotal();
		}
	},


	methods: {
		async getPeriods() {
			try {
				this.periodsLoaded = false;
				this.periods = await apiBudget.getPeriods();
				this.periodsLoaded = true
			} catch (error) {
				console.log('periods loading error: ', error)
			}
		},

		async getPeriod() {
			try {
				this.periodLoaded = false;
				this.period = await apiBudget.getPeriod(this.tab, this.type);
				this.items = this.period.marketing_budget_expected_results;
				this.items.forEach((item) => {
					item.marketer = this.getMarketerByModelId(item.only_fans_model.id)
				})
				this.searchItem = this.items;
				this.calcTotal();
				this.periodLoaded = true;
			} catch (error) {
				console.log('selected period loading error: ', error)
			}
		},

		calcTotal() {
			const marketersList = Array.from(new Set(this.filteredItems.map(item => item.marketer !== '-' )));
			const modelsList = Array.from(new Set(this.filteredItems.map(item => item.only_fans_model.id )));
			const bidgetList = this.filteredItems.map(item => item.cost_plan);
			const expensesList = this.filteredItems.map(item => item.cost_fact);
			this.budgetTotalValues.marketersCount = marketersList.length;
			this.budgetTotalValues.modelsCount = modelsList.length;
			this.budgetTotalValues.budget = bidgetList.reduce((acc, num) => acc + num, 0);
			this.budgetTotalValues.expenses = expensesList.reduce((acc, num) => acc + num, 0);
		},

		headName(value) {
			if (value === 'marketer') return 'Маркетолог';
			if (value === 'model') return 'Модель';
			if (value === 'budget') return 'Бюджет';
			if (value === 'expenses') return 'Потрачено';
			if (value === 'linebar') return 'Процент расхода';

		},

		setItemBgColor(index, hover) {
			if (hover) {
				return 'bg-cyan-lighten-5'
			} else if (index % 2) {
				return 'bg-grey-lighten-4'
			} else {
				return 'bg-white'
			}
		},

		calcValue(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			return Math.round(value2 / value1 * 100);
		},

		calcColor(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			const result = Math.round(value2 / value1 * 100);
			if (result > 100) {
				return 'deep-orange-darken-1'
			} else {
				return 'cyan-darken-3'
			}
		},

		getMarketerByModelId(id) {
			let marketer = this.$store.getters['user/getModelObjectById'](id).marketer;
			if (marketer) {
				return marketer.full_name
			} else {
				return '-'
			}
		},

		openEditModel(item) {
			this.editedModelIndex = this.items.indexOf(item);
			this.editedModel = Object.assign({}, item);
			this.dialogEditModel = true
		},

		closeEditModel() {
			this.dialogEditModel = false;
			this.$nextTick(() => {
				this.editedModel = Object.assign({}, this.deleteModel);
				this.editedModelIndex = -1
			})
		},

		deleteModel(item) {
			this.editedModelIndex = this.items.indexOf(item);
			this.editedModel = Object.assign({}, item);
			this.dialogDeleteModel = true
		},

		closeDeleteModel() {
			this.dialogDeleteModel = false;
			this.$nextTick(() => {
				this.editedModel = Object.assign({}, this.defaultModel);
				this.editedModelIndex = -1
			})
		},

		async deleteModelConfirm() {
			try {
				this.deleteLoading = true;
				await apiBudget.deletePlanModel(this.editedModel.id);
				this.closeDeleteModel();
				const index = this.items.findIndex(item => item.id === this.editedModel.id);
				if (index !== -1) {
					this.items.splice(index, 1)
				}
			} catch (error) {
				console.log('delete model error: ', error)
			} finally {
				this.deleteLoading = false;
			}
		},

		addModel(data) {
			this.items.push(data)
		},

		editModel(data) {
			const index = this.items.findIndex(item => item.id === data.id);
			this.items[index] = data
		},

		openEditPlan() {
			this.editedPlanIndex = 1;
			this.editedPlan = Object.assign({}, this.period);
			this.dialogEditPlan = true
		},

		closeEditPlan() {
			this.dialogEditPlan = false;
			this.$nextTick(() => {
				this.editedPlan = Object.assign({}, this.defaultPlan);
				this.editedPlanIndex = -1
			})
		},

		addPlan(data) {
			const item = {
				end_date: data.end_date,
				id: data.id,
				name: data.name,
				start_date: data.start_date,
			}
			this.periods.push(item);
			this.periods.sort((item1, item2) => item1['start_date'] > item2['start_date'] ? 1 : -1).reverse();
		},

		editPlan(data) {
			const index = this.periods.findIndex(item => item.id === data.id);
			this.periods[index].name = data.name;
			this.periods[index].start_date = data.start_date;
			this.periods[index].end_date = data.end_date;
			this.period = data;
			this.items = data.marketing_budget_expected_results;
			this.searchItem = this.items
		},

		setOrdering(value) {
			if (this.ordering === '-' + value || this.ordering === value) {
				if (this.ordering === value) {
					this.ordering = '-' + value;
				} else {
					this.ordering = value;
				}
			} else {
				this.ordering = '-' + value;
			}
		},

		sortingTable() {
			if (this.ordering === 'marketer') {
				this.items.sort((a, b) => (this.getMarketerByModelId(a.only_fans_model.id)).toLowerCase() > (this.getMarketerByModelId(b.only_fans_model.id)).toLowerCase() ? 1 : -1)
			}
			if (this.ordering === '-marketer') {
				this.items.sort((a, b) => (this.getMarketerByModelId(a.only_fans_model.id)).toLowerCase() > (this.getMarketerByModelId(b.only_fans_model.id)).toLowerCase() ? 1 : -1).reverse()
			}
			if (this.ordering === 'model') {
				this.items.sort((a, b) => (a.only_fans_model.nickname).toLowerCase() > (b.only_fans_model.nickname).toLowerCase() ? 1 : -1)
			}
			if (this.ordering === '-model') {
				this.items.sort((a, b) => (a.only_fans_model.nickname).toLowerCase() > (b.only_fans_model.nickname).toLowerCase() ? 1 : -1).reverse()
			}
			if (this.ordering === 'budget') {
				this.items.sort((a, b) => a.cost_plan - b.cost_plan)
			}
			if (this.ordering === '-budget') {
				this.items.sort((a, b) => a.cost_plan - b.cost_plan).reverse()
			}
			if (this.ordering === 'expenses') {
				this.items.sort((a, b) => a.cost_fact - b.cost_fact)
			}
			if (this.ordering === '-expenses') {
				this.items.sort((a, b) => a.cost_fact - b.cost_fact).reverse()
			}
			if (this.ordering === 'linebar') {
				this.items.sort((a, b) => this.calcValue(a.cost_plan, a.cost_fact) - this.calcValue(b.cost_plan, b.cost_fact))
			}
			if (this.ordering === '-linebar') {
				this.items.sort((a, b) => this.calcValue(a.cost_plan, a.cost_fact) - this.calcValue(b.cost_plan, b.cost_fact)).reverse()
			}
		},

		async getPeriodCopy() {
			try {
				this.copyLoading = true;
				this.copyLoaded = false;
				this.copy = await apiBudget.getPeriodCopy(this.tab, this.type);
				this.copyLoading = false;
				this.copyLoaded = true
			} catch (error) {
				console.log(error)
			}
		},

		copyTable() {
			window.navigator.clipboard.writeText(this.copy.copy_data)
		}
	}
})
</script>

<style scoped>

</style>