<script>
import {mapState} from "vuex";
import apiFinance from "@/api/finance";

export default {
	name: "PaymentConfirmDialog",


	props: {
		info: {}
	},


	data() {
		return {
			formLoading: false,
			fields: {},
			tools: [],
			toolsLoading: false,
			toolsLoaded: false,
			transactionsSummary: null,
			paymentsAmountError: '',
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
		})
	},


	created() {
		this.setFields();
		this.getPaymentTools();
	},


	watch: {
		transactionsSummary(value) {
			if (value > this.info.payment_amount) {
				this.paymentsAmountError = 'payments amount exceeded'
			} else {
				this.paymentsAmountError = ''
			}
		}
	},


	methods: {

		getSummary() {
			let summ = 0;
			this.fields.payment_transactions.forEach((item) => {
				summ = summ + Number(item.amount);
			})
			this.transactionsSummary = summ;
		},

		setFields() {
			this.fields.financier = structuredClone(this.userInfo.full_name);
			this.fields.payment_transactions = structuredClone(this.info.payment_transactions)
			if (this.fields.payment_transactions.length === 0) {
				this.fields.payment_transactions.push({ payment_tool: null, amount: this.info.payment_amount })
			}
		},

		async getPaymentTools() {
			this.toolsLoading = true;
			this.toolsLoaded = false;
			this.tools = await apiFinance.getPaymentTools()
			this.toolsLoading = false;
			this.toolsLoaded = true;
		},

		addPaymentTool() {
			this.fields.payment_transactions.forEach((item) => {
				item.amount = 0
			})
			this.fields.payment_transactions.push({ payment_tool: null, amount: 0 })
			this.paymentsAmountError = '';
			this.transactionsSummary = null;
		},

		async handlePaymentProvide() {
			this.formLoading = true;
			try {
				const tools = [];
				this.fields.payment_transactions.forEach((item) => {
					if (item.payment_tool && item.amount > 0) {
						tools.push({amount: item.amount, payment_tool: item.payment_tool})
					}
				})
				const data = {
					finance_review_result: 'accept',
					payment_transactions: tools,
				};
				const response = await apiFinance.patchPaymentRequest(this.info.id, data);
				this.$emit('accept', response);
				this.$emit('close');
				this.formLoading = false;
			} catch (error) {
				console.log(error)
			}
		}

	}
}
</script>

<template>
	<v-card class="pa-6" :disabled="formLoading">
		<v-form>
			<div class="mb-4 text-body-1">Provide info</div>

			<v-row class="mb-6 text-body-2">
				<v-col>
					<div class="mb-2"><span class="py-2 text-grey-darken-1">Amount:</span> <span class="py-2 text-right font-weight-medium">{{ info.payment_amount }}</span></div>
					<div><span class="py-2 text-grey-darken-1">Payment details:</span> <span class="py-2 text-right font-weight-medium">{{ info.payment_details }}</span></div>
				</v-col>
				<v-col>
					<div class="mb-2"><span class="py-2 text-grey-darken-1">Currency:</span> <span class="py-2 text-right font-weight-medium">{{ info.payment_currency }}</span></div>
					<div><span class="py-2 text-grey-darken-1">Payment system:</span> <span class="py-2 text-right font-weight-medium">{{ info.payment_system }}</span></div>
				</v-col>
			</v-row>

			<div class="mb-6">
				<div class="text-body-1 mb-2">Financier</div>
				<v-text-field disabled readonly placeholder="Financier" v-model="fields.financier" variant="outlined"></v-text-field>
			</div>

			<div class="mb-6">
				<div class="text-body-1 mb-2">Payment info</div>
				<v-row v-for="(item, index) in fields.payment_transactions" :key="index">
					<v-col cols="8">
						<v-combobox
								variant="outlined"
								item-title="name"
								item-value="name"
								placeholder="Payment tool"
								:loading="!toolsLoaded"
								:disabled="!toolsLoaded"
								:items="tools"
								v-model="item.payment_tool"
								:return-object="false"
						></v-combobox>
					</v-col>
					<v-col cols="4">
						<v-text-field
								type="number"
								placeholder="Financier"
								v-model="item.amount"
								variant="outlined"
								@update:model-value="getSummary"
								:error-messages="paymentsAmountError"
						></v-text-field>
					</v-col>
				</v-row>
			</div>

			<div class="mb-4" v-if="fields.payment_transactions.length < 3">
				<label>
					<v-btn icon="mdi-plus" size="small" color="primary" @click="addPaymentTool"></v-btn>
					<span class="text-body-2 ml-2">Add payment info</span>
				</label>
			</div>

			<v-row justify="end" no-gutters>
				<v-btn variant="text" @click="$emit('close')">Cancel</v-btn>
				<v-btn :disabled="formLoading || paymentsAmountError.length > 0" :loading="formLoading" class="ml-2" color="primary" @click="handlePaymentProvide">Confirm</v-btn>
			</v-row>

		</v-form>
	</v-card>
</template>

<style scoped>

</style>