<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Shifts history</h1>

			<ShiftFilter
					@loadHistory="getItems"
					:loading="!itemsLoaded"
			/>
			<div class="mt-10" v-if="itemsLoaded">
				<template v-if="Object.keys(items).length === 0">
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>There is no data matching the selected filters</span>
					</div>
				</template>

				<template v-else>

					<div
							v-for="(date, dateIndex) in items"
							:key="dateIndex"
							class="group-date mb-10"
					>
						<h3 class="text-h5 mb-6">{{ date.date }}</h3>
						<div
								v-for="(shiftGroup, shiftGroupIndex) in date.shift_numbers"
								:key="shiftGroupIndex"
								class="group-shift mb-6"
						>
							<h4 class="text-h6 mb-6">{{ shiftGroup.shift_number_info.name }}</h4>
							<ShiftCard
									v-for="shift in shiftGroup.shifts"
									:key="shift.id"
									:shift-info="shift"
									class="mb-4"
									complited
							/>
						</div>
					</div>

					<div v-if="nextPage" class="d-flex justify-center">
						<v-btn :loading="moreLoading" :disabled="moreLoading" color="primary" size="large" @click="showMore">Show more</v-btn>
					</div>

				</template>
			</div>

			<div v-else>
				<v-skeleton-loader type="heading" max-width="300" class="bg-transparent mb-6"></v-skeleton-loader>
				<v-skeleton-loader
						v-for="(item, index) in ['','','']"
						:key="index"
						class="rounded-lg elevation-2 mb-6"
						type="table-heading, list-item-two-line"
				></v-skeleton-loader>
			</div>

		</div>
	</div>
</template>

<script>
  import ShiftFilter from '@/components/ShiftFilter.vue'
	import apiShifts from '@/api/shifts'
	import ShiftCard from "@/components/ShiftCard.vue";

  export default {
    name: 'ShiftHistoryPage',


    components: {
			ShiftCard,
      ShiftFilter,
    },


    data: () => ({
			items: [],
			itemsLoading: false,
			itemsLoaded: false,
			nextPage: null,
			params: {},
			moreLoading: false,
			moreLoaded: false,
		}),


		methods: {
			async getItems(params) {
				try {
					this.itemsLoading = true;
					this.itemsLoaded = false;
					this.params = params;
					const result = await apiShifts.getShiftsHistoryTotal(params);
					this.items = result.results;
					this.nextPage = result.next;
					this.itemsLoading = false;
					this.itemsLoaded = true;
				} catch (error) {
					console.log(error);
				}
			},

			async showMore() {
				this.params.page = this.nextPage;
				this.moreLoading = true;
				this.moreLoaded = false;
				const result = await apiShifts.getShiftsHistoryTotal(this.params);

				const oldItems = this.items;
				const newItems = result.results;
				const currentDates = oldItems.map(elem => elem.date );
				const newDates = newItems.map(elem => elem.date );
				const crossDates = currentDates.filter(e => newDates.includes(e));

				crossDates.forEach((date) => {
					const currentDateObject = oldItems.find(item => item.date === date);
					const currentShiftNumbers = currentDateObject.shift_numbers.map(elem => elem.shift_number_info.name );
					const newCurrentDateObject = newItems.find(item => item.date === date);
					const newCurrentShiftNumbers = newCurrentDateObject.shift_numbers.map(elem => elem.shift_number_info.name );
					const crossShiftNumbers = currentShiftNumbers.filter(e => newCurrentShiftNumbers.includes(e));
					crossShiftNumbers.forEach((shiftNumber) => {
						let shiftObject =  currentDateObject.shift_numbers.find(item => item.shift_number_info.name === shiftNumber);
						let shiftsAdditionObject =  newCurrentDateObject.shift_numbers.find(item => item.shift_number_info.name === shiftNumber);
						shiftObject.shifts = shiftObject.shifts.concat(shiftsAdditionObject.shifts)
					})
					const shiftsToAdd = newCurrentShiftNumbers.filter(e => !crossShiftNumbers.includes(e));
					shiftsToAdd.forEach((shiftNumber) => {
						const shiftsAdditionObject =  newCurrentDateObject.shift_numbers.find(item => item.shift_number_info.name === shiftNumber);
						currentDateObject.shift_numbers.push(shiftsAdditionObject)
					})
				})

				const datesToAdd = newDates.filter(e => !crossDates.includes(e));
				datesToAdd.forEach((date) => {
					const dateAdditionObject =  newItems.find(item => item.date === date);
					console.log(dateAdditionObject)
					oldItems.push(dateAdditionObject)
				})

				this.nextPage = result.next;
				this.moreLoading = false;
				this.moreLoaded = true;
			}
		}
  }
</script>

<style scoped>

</style>