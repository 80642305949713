<script>
export default {
	name: "DatabookCard",


	props: {
		info: {}
	}
}
</script>

<template>
	<v-card class="pa-4">
		<v-row>
			<v-col cols="auto">
				<v-avatar v-if="info.avatar" size="74">
					<v-img cover :src="info.avatar"></v-img>
				</v-avatar>
                <v-avatar v-else size="74" color="primary">
                    <v-icon size="32">mdi-account</v-icon>
                </v-avatar>
			</v-col>
			<v-col>
				<div class="text-h6">{{ info.only_fans_model.nickname }}</div>
				<div class="text-subtitle-1 text-grey-darken-1">{{ info.only_fans_model.category?.name }} {{ info.nationality?.nationality_name }}</div>
			</v-col>
			<v-col cols="auto">
				<v-btn density="comfortable" variant="flat" icon="mdi-pencil" @click.stop="$router.push({ name: 'DatabookDetail', params: { id: info.id }, query: { edit: true } })"></v-btn>
			</v-col>
		</v-row>
<!--		<v-row align="center" justify="space-between">-->
<!--			<v-col cols="auto">-->
<!--				<div class="text-subtitle-2 text-grey-darken-1 font-weight-regular">Nearest important date</div>-->
<!--			</v-col>-->
<!--			<v-col cols="auto">-->
<!--				<v-sheet rounded color="green-accent-4" class="px-3 py-1 d-flex align-center justify-center">-->
<!--					<v-icon size="18" color="white">mdi-cake-variant-outline</v-icon>-->
<!--					<span class="pl-1 text-white text-body-2">18.04.2024</span>-->
<!--				</v-sheet>-->
<!--			</v-col>-->
<!--		</v-row>-->
		<v-sheet color="grey-lighten-5" class="mt-2 pa-4 rounded-lg">
			<v-row justify="space-between">
				<v-col cols="auto">
					<div class="text-subtitle-1 font-weight-bold">Client manager:</div>
				</v-col>
				<v-col cols="auto">
					<div class="text-subtitle-1 text-grey-darken-1">{{ info.only_fans_model.client_manager?.full_name }}</div>
				</v-col>
			</v-row>
		</v-sheet>
		<div class="mt-2 text-grey-darken-1 text-subtitle-2 font-weight-regular">
			<span class="mr-2">Last edit:</span>
			<span>{{ new Date(this.info.updated_at).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'}) }}</span>
		</div>
	</v-card>
</template>

<style scoped>

</style>