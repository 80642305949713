import api from '@/api/marketers'
import {
  SET_MARKETERS,
  MARKETERS_LOADING,
  MARKETERS_LOADED,
  ADD_MARKETER,
  EDIT_MARKETER,
  DELETE_MARKETER,
} from './constants'
import { RESET_STATE } from '@/store/constants'
import resetState from '@/store/helpers/resetState'

/*--------------------------Initial State------------------------------*/

export const initialState = {
  marketers: [],
  marketersLoading: false,
  marketersLoaded: false,
};

/*----------------------------Mutations----------------------------*/

const mutations = {
  [SET_MARKETERS](state, data) {
    state.marketers = data
  },
  [MARKETERS_LOADING](state, value) {
    state.marketersLoading = value
  },
  [MARKETERS_LOADED](state, value) {
    state.marketersLoaded = value
  },
  [ADD_MARKETER](state, marketer) {
    const target = state.marketers.find(item => item.id === marketer.id);
    if (target === undefined) {
      state.marketers.push(marketer)
    }
  },
  [EDIT_MARKETER](state, marketer) {
    const index = state.marketers.findIndex(item => item.id === marketer.id);
    if (index !== -1) {
      state.marketers[index] = marketer
    }
  },
  [DELETE_MARKETER](state, id) {
    const index = state.marketers.findIndex(marketer => marketer.id === id);
    if (index !== -1) {
      state.marketers.splice(index, 1)
    }
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  },
};

/*----------------------------Actions----------------------------*/

const actions = {
  async getMarketers({commit}) {
    commit(MARKETERS_LOADING, true);
    try {
      const data = await api.getMarketers();
      await commit(SET_MARKETERS, data);
      commit(MARKETERS_LOADED, true)
    } catch (err) {
      console.log('get marketers error: ', err)
    } finally {
      commit(MARKETERS_LOADING, false);
    }
  },
  async addMarketer({commit}, data) {
    const info = await api.addMarketer(data);
    commit(ADD_MARKETER, info)
  },
  async editMarketer({commit}, data) {
    const info = await api.editMarketer(data.fields, data.id);
    commit(EDIT_MARKETER, info)
  },
  async deleteMarketer({commit}, id) {
    await api.deleteMarketer(id);
    commit(DELETE_MARKETER, id)
  },
  resetState({commit}) {
    commit(RESET_STATE)
  },
};

/*----------------------------Getters----------------------------*/

const getters = {};

/*----------------------------Export----------------------------*/

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
  getters,
}