<script>
import {mapState} from "vuex";
import {useDisplay} from "vuetify";
import apiFinance from "@/api/finance";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "ForecastTableDialog",


	data() {
		return {
			itemsLoading: false,
			itemsLoaded: false,
			items: [],
			copyLoading: false,
			copyLoaded: false,
			copy: null,
			headCellClass: 'text-body-2 font-weight-medium pa-2 text-center',
			cellClass: 'text-body-2 pa-2 text-center',
			modelsWithIndex: []
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},


	created() {
		this.getForecastTable();
		this.getForecastTableCopy();
		if (this.commonListModelsLoaded) {
			this.modelsWithIndex = [];
			this.commonListModels.forEach((item) => {
				if (item.index_number) {
					this.modelsWithIndex.push(item)
				}
			});
			this.modelsWithIndex.sort((a, b) => a.index_number > b.index_number ? 1 : -1)
		}
	},


	watch: {
		commonListModelsLoaded(value) {
			if (value) {
				this.modelsWithIndex = [];
				this.commonListModels.forEach((item) => {
					if (item.index_number) {
						this.modelsWithIndex.push(item)
					}
				});
				this.modelsWithIndex.sort((a, b) => a.index_number > b.index_number ? 1 : -1)
			}
		}
	},


	methods: {
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), 0, 1);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		checkMatch(model, forecast) {
			const target = forecast.find(debt => debt.model === model.nickname)
			if (target) {
				return target.value
			} else {
				return  '-'
			}
		},
		async getForecastTable() {
			try {
				this.itemsLoaded = false;
				this.itemsLoading = true;
				this.items = await apiFinance.getForecastTable();
				this.itemsLoaded = true;
				this.itemsLoading = false;
			} catch (error) {
				console.log(error)
			}
		},
		async getForecastTableCopy() {
			try {
				this.copyLoaded = false;
				this.copyLoading = true;
				this.copy = await apiFinance.getForecastTableCopy();
				this.copyLoaded = true;
				this.copyLoading = false;
			} catch (error) {
				console.log(error)
			}
		},
		copyTable() {
			window.navigator.clipboard.writeText(this.copy.copy_data)
		}
	}
}
</script>

<template>
	<v-card :class="mdAndDown ? 'pa-4' : 'pa-10'">

		<div class="text-h4 text-center mb-6">Table view</div>

		<v-row class="mb-4 flex-0-0" align="center" justify="end">
			<v-col cols="auto">
				<v-btn :loading="!copyLoaded" :disabled="!copyLoaded" color="primary" @click="copyTable">Copy table</v-btn>
			</v-col>
		</v-row>

		<div class="overflow-x-auto pb-2 flex-1-1">

			<div v-if="itemsLoaded" class="d-flex w-fit" style="min-width: 100%">

				<div class="flex-1-1" style="min-width: 160px">
					<div class="pa-2 text-body-1 font-weight-medium bg-white position-sticky" style="top: 0; z-index: 1">Model</div>
					<div class="table-column d-flex flex-column">
						<div
								v-for="(item, index) in modelsWithIndex"
								:key="index"
								class="pa-2 text-body-2"
						>
							{{ item.nickname }}
						</div>
					</div>
				</div>

				<div v-for="(item, index) in items" :key="index" style="min-width: 90px" class="flex-1-1">
					<div class="pa-2 text-body-1 font-weight-medium text-center bg-white position-sticky" style="top: 0; z-index: 1">{{ item.month }}</div>
					<div class="table-column d-flex flex-column">
						<div
								v-for="(model, modelIndex) in modelsWithIndex"
								:key="modelIndex"
								class="pa-2 text-body-2 text-center"
						>
							{{ checkMatch(model, item.forecast) }}
						</div>
					</div>
				</div>

			</div>

			<v-skeleton-loader v-else type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

		</div>

		<div class="d-flex justify-center pt-4">
			<v-btn color="primary" @click="$emit('close')" size="large">Close</v-btn>
		</div>

	</v-card>
</template>

<style scoped lang="less">
.table-column {

	& > div {

		&:nth-child(2n + 2) {
			background: #f5f5f5;
		}
	}
}
</style>