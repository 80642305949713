<template>
	<div class="auth-page">
		<div class="auth-page__left">
			<AuthScreenHello />
		</div>
		<div class="auth-page__right">
			<div class="auth-wrap">
<!--				<div class="auth-wrap__logo">-->
<!--					<img :src="require('@/assets/img/sparks-logo.svg')" alt="sparks-logo">-->
<!--				</div>-->
				<div class="auth-wrap__body">
					<h1 class="text-h4 text-center mb-10">Authorization</h1>
					<div class="auth-wrap__form">
						<AuthForm />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import AuthScreenHello from '@/components/AuthScreen/AuthScreenHello.vue';
import AuthForm from '@/components/AuthScreen/AuthForm.vue';

export default {
	name: 'AuthPage',


	components: {
		AuthForm,
		AuthScreenHello,
	},


	computed: {
		...mapState({

		})
	}
}
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.auth-page {
		height: 100vh;

		@media @desktop {
			display: flex;
		}

		&__left {
			display: none;

			@media @desktop {
				display: block;
				flex: 1 1 57%;
			}
		}

		&__right {
			min-height: 100%;
			display: flex;
			align-items: center;

			@media @desktop {
				flex: 1 1 43%;
			}
		}
	}

	.auth-wrap {
		width: 100%;
		max-width: 520px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		margin: 0 auto;

		@media @desktop {
			padding: 80px;
		}

		&__logo {
			flex: 0 1 auto;
			margin-bottom: 80px;

			& img {
				margin: 0 auto;
				display: block;
				width: 100%;
				max-width: 344px;
			}
		}

		&__body {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;

		}

		&__form {

		}

		&__title {
			text-align: center;
			margin-bottom: 12px;
		}

		&__subtitle {
			font-size: 16px;
			text-align: center;
			margin-bottom: 32px;
		}
	}
</style>