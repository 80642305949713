import { createStore } from 'vuex'
import router from '@/router'
// import VuexPersistence from 'vuex-persist'

// import mainView from './modules/mainView'
import user from './modules/user'
import shifts from './modules/shifts'
import operators from './modules/operators'
import marketers from './modules/marketers'
import managers from './modules/managers'

const actions = {
  resetState({ dispatch, state }) {
    // вызываем в каждом модуле экшн resetState
    Object.keys(state).forEach(key => {
      dispatch(`${key}/resetState`)
    });
    router.push({ name: 'Auth' })
  },
};

// const vuexLocal = new VuexPersistence ({
//   storage: window.localStorage,
//   // модули, данные которых будут сохраняться в local storage
//   modules: ['user']
// })

const store = createStore({
  actions,
  modules: {
    // mainView,
    user,
    shifts,
    operators,
    marketers,
    managers,
  },
  // plugins: [vuexLocal.plugin]
});

export default store