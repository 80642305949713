<script>
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js'
import {Doughnut, Line} from 'vue-chartjs'
import {useDisplay} from "vuetify";
import apiSmm from '@/api/smm'
import BaseBack from "@/components/BaseBack.vue";

ChartJS.register(
		CategoryScale,
		LinearScale,
		ArcElement,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
)

export default {
	name: "TrackingDetailPage",


	props: {
		id: {
			type: [Number, String],
			required: true
		}
	},


	components: {
		BaseBack,
		Line,
		Doughnut
	},


	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	data() {
		return {
			format: 'dd.MM.yyyy',
			date: this.setDefaultDateRange(),
			chartOptionsLine: {},
			chartDataLine: {},
			chartOptionsCircle: {},
			chartDataCircle: {},
			heads: ['date', 'revenue_start', 'revenue_end', 'day_revenue', 'claims_start', 'claims_end', 'day_claims'],
			sizes: {
				date: '15',
				revenue_start: '14',
				revenue_end: '14',
				day_revenue: '14',
				claims_start: '14',
				claims_end: '14',
				day_claims: '15',
			},
			sizesMobile: {
				date: '150',
				revenue_start: '140',
				revenue_end: '140',
				day_revenue: '140',
				claims_start: '140',
				claims_end: '140',
				day_claims: '140',
			},
			detail: {},
			detailLoaded: false,
			detailLoading: false,
		}
	},


	created() {
		this.getDetail();
		if (this.detailLoaded) this.setChartData()
	},


	watch: {
		detailLoaded() {
			this.setChartData()
		},
		date() {
			this.getDetail()
		}
	},


	methods: {
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), -14);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		headName(value) {
			if (value === 'date') return 'Date';
			if (value === 'revenue_start') return 'Revenue start';
			if (value === 'revenue_end') return 'Revenue end';
			if (value === 'day_revenue') return 'Day revenue';
			if (value === 'claims_start') return 'Claims count start';
			if (value === 'claims_end') return 'Claims count end';
			if (value === 'day_claims') return 'Day claims count';
		},
		setItemBgColor(index) {
			if (index % 2) {
				return 'bg-grey-lighten-4'
			} else {
				return 'bg-white'
			}
		},
		setIcon(platform) {
			if (platform === 'Twitter') { return 'twitter' }
			else if (platform === 'Telegram') { return 'telegram' }
			else if (platform === 'Instagram') { return 'instagram' }
			else if (platform === 'Tik Tok') { return 'tiktok' }
			else { return 'planet' }
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		async getDetail() {
			try {
				this.detailLoaded = false;
				this.detailLoading = true;
				const params = {
					date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
					date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
				}
				this.detail = await apiSmm.getDetail(this.id, params);
				this.detailLoaded = true
			} catch (error) {
				console.log(error)
			} finally {
				this.detailLoading = false
			}
		},
		setChartData() {
			const labels = Array.from(new Set(this.detail.daily_data.map(elem => elem.date )));
			const revenueData = [];
			const claimsCountData = [];
			labels.forEach((dateLabel, index) => {
				const target = this.detail.daily_data.find(el => el.date === dateLabel);
				revenueData[index] = target.total_day_revenue
				claimsCountData[index] = target.total_day_claims_count
			})

			this.chartDataCircle = {
				labels: ['Result revenue', 'Result claims count'],
				datasets: [{
					// label: 'My First Dataset',
					data: [this.detail.revenue, this.detail.claims_count],
					backgroundColor: ['#1976D2', '#9C27B0'],
					hoverOffset: 4
				}]
			};
			this.chartOptionsCircle = {
				rotation: 45,
				plugins: { legend: { display: false } }
			};
			this.chartOptionsLine = {
				plugins: { legend: { display: false } },
				scales: {
					x: {
						grid: { display: false },
						border: { display: false },
					},
					y: {
						grid: { display: false },
						border: { display: false },
					},
				},
				interaction: {
					intersect: false,
					axis: 'x'
				},
				responsive: true,
				maintainAspectRatio: false,
			};
			this.chartDataLine = {
				labels: labels,
				datasets: [
					{
						data: revenueData,
						pointStyle: 'circle',
						backgroundColor: '#1976D2',
						borderColor: '#1976D2',
						pointRadius: 3,
						pointHoverRadius: 8
					},
					{
						data: claimsCountData,
						pointStyle: 'circle',
						backgroundColor: '#9C27B0',
						borderColor: '#9C27B0',
						pointRadius: 3,
						pointHoverRadius: 8
					}
				]
			}
		}
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<BaseBack class="mb-4" />

			<h1 class="text-h4 mb-8">Promo detail</h1>

			<v-row v-if="detailLoaded">
				<v-col :cols="mdAndDown ? 12 : 6">
					<v-sheet rounded class="px-5 py-4 elevation-1">
						<div class="d-flex mt-4 mb-6"><img width="24" class="mr-2" :src="require(`@/assets/icon/${setIcon(detail.platform_type_name)}.svg`)" alt="twitter"><span class="text-subtitle-1 font-weight-medium">{{ detail.platform_type_name }}</span></div>
						<v-row>
							<v-col cols="auto" class="pr-10">
								<div class="text-body-1 mb-2">Model</div>
								<div class="text-body-2 font-weight-light">{{ detail.only_fans_model_nickname }}</div>
							</v-col>
							<v-col cols="12">
								<v-text-field
										v-model="detail.link"
										variant="outlined"
										placeholder="Enter url"
										hide-details
										disabled
								></v-text-field>
							</v-col>
							<v-col cols="auto" class="pr-10">
								<div class="text-body-1 mb-2">Track date</div>
								<div class="text-body-2 font-weight-light">{{ detail.track_start }}</div>
							</v-col>
							<v-col v-if="detail.track_end" cols="auto" class="pr-10">
								<div class="text-body-1 mb-2">End date</div>
								<div class="text-body-2 font-weight-light">{{ detail.track_end }}</div>
							</v-col>
						</v-row>
					</v-sheet>
				</v-col>
				<v-col :cols="mdAndDown ? 12 : 6">
					<v-sheet rounded class="px-5 py-4 elevation-1 fill-height d-flex align-center">
						<v-row>
							<v-col>
								<div style="width: 100%; max-height: 200px; height: 100%">
									<Doughnut :options="chartOptionsCircle" :data="chartDataCircle" :height="200"/>
								</div>
							</v-col>
							<v-col>
								<div class="mt-3 mb-6">
									<div class="d-flex align-center mb-1">
										<span class="bg-primary mr-2" style="width: 21px; height: 8px"></span>
										<div class="text-body-1">Result revenue</div>
									</div>
									<div class="font-weight-light text-body-2">{{ detail.revenue }}$</div>
								</div>
								<div class="mb-6">
									<div class="d-flex align-center mb-1">
										<span class="bg-purple mr-2" style="width: 21px; height: 8px"></span>
										<div class="text-body-1">Result claims count</div>
									</div>
									<div class="font-weight-light text-body-2">{{ detail.claims_count }}</div>
								</div>
								<div>
									<div class="text-body-1">Fans cost</div>
									<div class="font-weight-bold text-body-1">{{ (detail.revenue / detail.claims_count).toFixed(2) }}$</div>
								</div>
							</v-col>
						</v-row>
					</v-sheet>
				</v-col>
				<v-col cols="12">
					<v-sheet rounded class="px-5 py-4 elevation-1">
						<div class="text-h6 mb-6">Detail</div>
						<v-row justify="space-between" class="mb-12">
							<v-col cols="auto">
								<VueDatePicker
										class="density-compact clearable-false"
										range
										v-model="date"
										auto-apply
										:format="format"
										:clearable="false"
								/>
							</v-col>
							<v-col cols="auto">
								<v-row>
									<v-col cols="auto">
										<div class="d-flex align-center mb-1">
											<span class="bg-primary mr-2" style="width: 21px; height: 8px"></span>
											<div class="text-body-1">Revenue</div>
										</div>
									</v-col>
									<v-col cols="auto">
										<div class="d-flex align-center mb-1">
											<span class="bg-purple mr-2" style="width: 21px; height: 8px"></span>
											<div class="text-body-1">Claims count</div>
										</div>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<div style="width: 100%; height: 200px;">
							<Line :options="chartOptionsLine" :data="chartDataLine"/>
						</div>
						<div class="mt-16 overflow-x-auto">
							<div class="d-flex" :class="{'w-fit': mdAndDown}">
								<div
										v-for="(item, index) in heads"
										:key="index"
										class="px-4 pt-2 pb-3 text-subtitle-2 font-weight-medium"
										:class="index > 0 ? 'text-center' : ''"
										style="width: unset"
										:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
								>{{headName(item)}}</div>
							</div>
							<div
									v-for="(item, index) in detail.daily_data" :key="index"
									class="d-flex rounded-lg align-center"
									:class="[setItemBgColor(index), {'w-fit': mdAndDown}]"
									@click="$router.push({ name: 'PromoDetailed', params: { id: item.id }})"
							>
								<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.date + 'px' } : { width: sizes.date + '%' }">{{ item.date }}</div>
								<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2 text-center font-weight-light" :style="mdAndDown ? { width: sizesMobile.revenue_start + 'px' } : { width: sizes.revenue_start + '%' }">{{ item.revenue_start }}</div>
								<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2 text-center font-weight-light" :style="mdAndDown ? { width: sizesMobile.revenue_end + 'px' } : { width: sizes.revenue_end + '%' }">{{ item.revenue_end }}</div>
								<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-1 text-center font-weight-bold" :style="mdAndDown ? { width: sizesMobile.day_revenue + 'px' } : { width: sizes.day_revenue + '%' }">{{ item.total_day_revenue }}</div>
								<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2 text-center font-weight-light" :style="mdAndDown ? { width: sizesMobile.claims_start + 'px' } : { width: sizes.claims_start + '%' }">{{ item.claims_count_start }}</div>
								<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2 text-center font-weight-light" :style="mdAndDown ? { width: sizesMobile.claims_end + 'px' } : { width: sizes.claims_end + '%' }">{{ item.claims_count_end }}</div>
								<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-1 text-center font-weight-bold" :style="mdAndDown ? { width: sizesMobile.day_claims + 'px' } : { width: sizes.day_claims + '%' }">{{ item.total_day_claims_count }}</div>
							</div>
						</div>
					</v-sheet>
				</v-col>
			</v-row>

			<v-row v-else>
				<v-col cols="6">
					<v-skeleton-loader class="fill-height" type="table-heading, list-item-two-line"></v-skeleton-loader>
				</v-col>
				<v-col cols="6">
					<v-skeleton-loader class="fill-height" type="avatar, sentences"></v-skeleton-loader>
				</v-col>
				<v-col cols="12">
					<v-skeleton-loader type="table"></v-skeleton-loader>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<style scoped>

</style>