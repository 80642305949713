<template>
	<v-combobox
			v-if="creation"
			:disabled="!itemsLoaded"
			:loading="!itemsLoaded"
			variant="outlined"
			item-title="name"
			item-value="name"
			:items="items"
			placeholder="Model donor"
	>
		<template v-slot:item="data">
			<v-list-item class="overflow-hidden" style="text-overflow: ellipsis; width: 260px" v-bind="data.props" :title="data.props.title">
				<template v-if="data.item.raw.is_blacklist" v-slot:append>
					<v-icon color="primary">mdi-cancel</v-icon>
				</template>
			</v-list-item>
		</template>
	</v-combobox>
	<v-autocomplete
			v-else
			:disabled="!itemsLoaded"
			:loading="!itemsLoaded"
			variant="outlined"
			item-title="name"
			item-value="id"
			:items="items"
			placeholder="Model donor"
	>
		<template v-slot:item="data">
			<v-list-item class="overflow-hidden" style="text-overflow: ellipsis; width: 260px" v-bind="data.props" :title="data.props.title">
				<template v-if="data.item.raw.is_blacklist" v-slot:append>
					<v-icon color="primary">mdi-cancel</v-icon>
				</template>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>

<script>
import apiAds from "@/api/ads";

export default {
	name: 'SelectDonor',


	props: {
		creation: {
			type: Boolean,
			default: false,
		}
	},


	data() {
		return {
			items: [],
			itemsLoaded: false,
			itemsLoading: false,
		}
	},


	computed: {

	},

	created() {
		this.getDonors()
	},


	watch: {},


	methods: {
		async getDonors() {
			try {
				this.itemsLoaded = false;
				this.itemsLoading = true;
				this.items = await apiAds.getDonors();
				this.itemsLoaded = true;
				this.itemsLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},
	}
}
</script>

<style scoped>

</style>