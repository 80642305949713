<script>
import PaymentConfirmDialog from "@/components/PaymentConfirmDialog.vue";
import PaymentEditDialog from "@/components/PaymentEditDialog.vue";
import apiFinance from "@/api/finance"
import {toast} from "vuetify-sonner";
import {useDisplay} from "vuetify";
import {mapState} from "vuex";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "PaymentCard",


	components: {
		PaymentEditDialog,
		PaymentConfirmDialog
	},


	props: {
		info: {}
	},


	data() {
		return {
			paymentCancelLoading: false,
			paymentCancelOpen: false,
			paymentConfirmOpen: false,
			paymentEditOpen: false,
			paymentTakeOffOpen: false,
			takeLoading: false,
			paymentTakeOffLoading: false,
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
		})
	},


	methods: {

		async handlePaymentCancel() {
			try {
				this.paymentCancelLoading = true;
				const response = await apiFinance.patchPaymentRequest(this.info.id, {
					finance_review_result: 'reject'
				});
				this.$emit('reject', response);
				this.paymentCancelLoading = false;
				this.paymentCancelOpen = false
			} catch (error) {
				console.log(error)
			}
		},

		handleAccept(response) {
			this.$emit('accept', response);
		},

		handleUpdate(response) {
			this.$emit('update', response);
		},

		copyField(value) {
			window.navigator.clipboard.writeText(value);
			toast('Field copied:', {
				duration: 5000,
				description: 'Field contents copied to clipboard',
				cardProps: {
					color: 'success',
				},
			});
		},

		async toggleReviewer() {
			this.takeLoading = true;
			const response = await apiFinance.toggleReviewerPaymentRequest(this.info.id);
			this.$emit('update', response);
			this.takeLoading = false;
			this.paymentTakeOffOpen = false;
		},

	},
}
</script>

<template>
	<v-card class="px-5 py-4 rounded-lg">

		<v-row justify="space-between" no-gutters>
			<v-col cols="auto">
				<div v-if="info.finance_review_result === 'review' && info.head_review_result === 'accept'" class="mb-2">
					<v-icon v-if="info.finance_reviewer && info.finance_reviewer.id !== userInfo.id" color="primary">mdi-flag</v-icon>
					<v-btn v-else-if="info.finance_reviewer" :loading="takeLoading" density="compact" variant="plain" icon="mdi-flag" color="primary" class="cursor-pointer" @click="paymentTakeOffOpen = true"></v-btn>
					<v-btn v-else :loading="takeLoading" density="compact" variant="plain" icon="mdi-flag" color="grey" class="cursor-pointer" @click="toggleReviewer"></v-btn>
					<span v-if="info.finance_reviewer" class="text-body-2 ml-1">{{ info.finance_reviewer.full_name }}</span>
				</div>
				<div class="text-grey">ID: {{ info.id }}</div>
			</v-col>
			<v-col cols="auto" class="mt-n3 mr-n4">
				<v-btn :disabled="info.finance_reviewer === null || (info.finance_reviewer.id !== userInfo.id && info.finance_review_result === 'review')" icon="mdi-pencil" variant="text" @click="paymentEditOpen = true"></v-btn>
			</v-col>
		</v-row>

		<v-row justify="space-between">
			<v-col :cols="mdAndDown ? 12 : 4" style="max-width: 350px">
				<table class="text-body-2 w-100 table">
					<tbody>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Request date:</td>
						<td class="py-2 text-right d-flex justify-end" :style="mdAndDown ? '' : 'width: 180px'" style="word-break: break-word;" v-if="info.date">
							<div class="cursor-pointer" @click.stop="copyField(new Date(info.date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}))">
								{{ new Date(info.date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'}) }}<br/>
								{{ new Date(info.date).getHours() }}:{{new Date(info.date).getMinutes()}}:{{new Date(info.date).getSeconds()}}
								<v-tooltip activator="parent" location="top">Copy field</v-tooltip>
							</div>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Approved by:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.head)">{{ info.head }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Approved date:</td>
						<td class="py-2 text-right d-flex justify-end" :style="mdAndDown ? '' : 'width: 180px'" style="word-break: break-word;" v-if="info.head_review_date">
							<span class="cursor-pointer" @click.stop="copyField(new Date(info.head_review_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}))">
								{{ new Date(info.head_review_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'}) }}<br/>
								{{ new Date(info.head_review_date).getHours() }}:{{new Date(info.head_review_date).getMinutes()}}:{{new Date(info.head_review_date).getSeconds()}}
								<v-tooltip activator="parent" location="top">Copy field</v-tooltip>
							</span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Department:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.department)">{{ info.department }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					</tbody>
				</table>
			</v-col>
			<v-col :cols="mdAndDown ? 12 : 4" style="max-width: 350px">
				<table class="text-body-2 w-100 table">
					<tbody>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Request author:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.created_by)">{{ info.created_by }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Expense item:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.payment_expense_item)">{{ info.payment_expense_item }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Model username:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.model_nickname)">{{ info.model_nickname }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					</tbody>
				</table>
			</v-col>
			<v-col :cols="mdAndDown ? 12 : 4" style="max-width: 350px">
				<table class="text-body-2 w-100 table">
					<tbody>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Amount:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.payment_amount)">{{ info.payment_amount }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Currency:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.payment_currency)">{{ info.payment_currency }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Payment details:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.payment_details)">{{ info.payment_details }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Payment system:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.payment_system)">{{ info.payment_system }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					<tr>
						<td class="py-2 text-grey-darken-1 text-no-wrap">Payment Note:</td>
						<td class="py-2 text-right" style="width: 180px; word-break: break-word;">
							<span class="cursor-pointer" @click.stop="copyField(info.payment_comment)">{{ info.payment_comment }}<v-tooltip activator="parent" location="top">Copy field</v-tooltip></span>
						</td>
					</tr>
					</tbody>
				</table>
			</v-col>
		</v-row>

		<v-row class="mt-8" align="end" justify="space-between" no-gutters>
			<v-col style="max-width: 500px">
				<div class="d-flex">
					<div class="text-body-2 text-grey-darken-1 pr-6 text-no-wrap">Payment description:</div>
					<div class="text-body-2">{{ info.payment_description }}</div>
				</div>
			</v-col>
			<v-col cols="auto" class="d-flex flex-column align-end" :class="mdAndDown ? 'mt-4' : ''">
				<div v-if="info.finance_review_result === 'review'" class="d-flex">
					<v-btn
							:disabled="info.finance_reviewer === null || (info.finance_reviewer && info.finance_reviewer.id !== userInfo.id)"
							variant="outlined"
							color="primary"
							@click="paymentCancelOpen = true"
					>
						Cancel
					</v-btn>
					<v-btn
							:disabled="info.finance_reviewer === null || (info.finance_reviewer && info.finance_reviewer.id !== userInfo.id)"
							class="ml-6"
							color="primary"
							@click="paymentConfirmOpen = true"
					>
						Provide
					</v-btn>
				</div>
				<div v-if="info.finance_review_result === 'accept'" class="d-flex flex-column align-end">
					<div class="d-flex align-center text-body-2 text-grey"><v-icon size="16" color="success">mdi-check-circle</v-icon><span class="pl-1">Provided by {{ info.financier }}</span></div>
					<div v-for="(item, index) in info.payment_transactions" class="text-body-2 text-grey" :key="index">{{ item.payment_tool}} {{ item.amount }}</div>
					<div class="text-body-2 text-grey">{{ new Date(info.finance_review_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) }}</div>
				</div>
				<div v-if="info.finance_review_result === 'reject'" class="d-flex flex-column align-end">
					<div class="d-flex align-center text-body-2 text-grey"><v-icon size="16" color="error">mdi-alert-circle</v-icon><span class="pl-1">Canceled by {{ info.financier }}</span></div>
					<div class="text-body-2 text-grey">{{ new Date(info.finance_review_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) }}</div>
				</div>
			</v-col>
		</v-row>

		<v-dialog v-model="paymentTakeOffOpen" width="300">
			<v-card :disabled="takeLoading" class="pa-4">
				<div class="text-body-1 mb-2">Cancel processing</div>
				<div class="text-subtitle-2">Are you sure?</div>
				<v-row justify="end" no-gutters class="mt-6 pa-0">
					<v-btn class="mr-2" variant="text" @click="paymentTakeOffOpen = false">No</v-btn>
					<v-btn color="primary" :loading="takeLoading" :disabled="takeLoading" @click="toggleReviewer">Agree</v-btn>
				</v-row>
			</v-card>
		</v-dialog>

		<v-dialog v-model="paymentCancelOpen" width="300">
			<v-card :disabled="paymentCancelLoading" class="pa-4">
				<div class="text-body-1 mb-2">Cancel payment</div>
				<div class="text-subtitle-2">Are you sure?</div>
				<v-row justify="end" no-gutters class="mt-6 pa-0">
					<v-btn class="mr-2" variant="text" @click="paymentCancelOpen = false">No</v-btn>
					<v-btn color="primary" :loading="paymentCancelLoading" :disabled="paymentCancelLoading" @click="handlePaymentCancel">Agree</v-btn>
				</v-row>
			</v-card>
		</v-dialog>

		<v-dialog v-model="paymentConfirmOpen" persistent width="600">
			<PaymentConfirmDialog
					@close="paymentConfirmOpen = false"
					:info="info"
					@accept="handleAccept"
			></PaymentConfirmDialog>
		</v-dialog>

		<v-dialog v-model="paymentEditOpen" fullscreen persistent transition="slide-x-transition" max-width="1100px" class="right-position">
			<PaymentEditDialog
					@close="paymentEditOpen = false"
					:info="info"
					@update="handleUpdate"
			></PaymentEditDialog>
		</v-dialog>

	</v-card>
</template>

<style scoped>
.table td {
	vertical-align: top;
}
</style>