<script>
import {useDisplay} from "vuetify";
import {mapState} from "vuex";
import SelectPlatformType from "@/components/SelectPlatformType.vue";
import SelectModel from "@/components/SelectModel.vue";
import apiSmm from "@/api/smm";

export default {
	name: "TrackingHistoryPage",


	components: {
		SelectModel,
		SelectPlatformType,
	},


	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	data() {
		return {
			search: '',
			dialogEdit: false,
			dialogDelete: false,
			editedIndex: -1,
			format: 'dd.MM.yyyy',
			date: this.setDefaultDateRange(),
			model: null,
			platformType: null,
			editedItem: {platform: '', date: '', end: '', model: '', link: '', revenue: '', claims: '', comment: ''},
			defaultItem: {platform: '', date: '', end: '', model: '', link: '', revenue: '', claims: '', comment: ''},
			heads: ['date', 'end', 'model', 'link', 'revenue', 'claims', 'comment', 'action'],
			sizes: {
				date: '11',
				end: '10',
				model: '12',
				link: '15',
				revenue: '10',
				claims: '14',
				comment: '18',
				action: '10',
			},
			sizesMobile: {
				date: '150',
				end: '150',
				model: '200',
				link: '150',
				revenue: '100',
				claims: '100',
				comment: '100',
				action: '100',
			},
			itemsLoaded: false,
			itemsLoading: false,
			items: [],
			searchItem: [],
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
			userInfo: state => state.user.info,
			userLoading: state => state.user.userLoading,
			userLoaded: state => state.user.userLoaded,
		}),
		filteredItems() {
			return this.searchItem.filter((item) => {
				for(let key in item) {
					if (item[key]) {
						if (key !== 'id') {
							if (String(item[key]).toLowerCase().match(this.search)) return true
						}
					}
				}
			})
		},
	},


	created() {
		if (this.userLoaded) {
			this.getHistoryTrackingList();
		}
	},


	watch: {
		model() {
			this.getHistoryTrackingList();
		},
		platformType() {
			this.getHistoryTrackingList();
		},
		userLoaded(value) {
			if (value) {
				this.getHistoryTrackingList();
			}
		}
	},


	methods: {
		headName(value) {
			if (value === 'date') return 'Track date';
			if (value === 'end') return 'End date';
			if (value === 'model') return 'Model';
			if (value === 'link') return 'Link used'
			if (value === 'revenue') return 'Revenue'
			if (value === 'claims') return 'Claims count'
			if (value === 'comment') return 'Comment'
		},
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), -14);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		setIcon(platform) {
			if (platform === 'Twitter') { return 'twitter' }
			else if (platform === 'Telegram') { return 'telegram' }
			else if (platform === 'Instagram') { return 'instagram' }
			else if (platform === 'Tik Tok') { return 'tiktok' }
			else { return 'planet' }
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		async getHistoryTrackingList() {
			try {
				this.itemsLoaded = false;
				this.itemsLoading = true;
				let departmentScope = false;
				if (this.userInfo.role === 'smm' || this.userInfo.role === 'smm_of') {
					departmentScope = true
				}
				const params = {
					date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
					date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
					department_scope: departmentScope
				}
				if (this.model) params.only_fans_model = this.model
				if (this.platform_type) params.only_fans_model = this.platformType
				this.items = await apiSmm.getHistoryTrackingList(params);
				this.searchItem = this.items;
				this.itemsLoaded = true;
				this.itemsLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},
		deleteItemDialogOpen(item) {
			this.editedIndex = this.items.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true
		},
		deleteItemDialogClose() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1
			})
		},
		async deleteItemConfirm() {
			try {
				this.deleteLoading = true;
				await apiSmm.deleteTrackingItem(this.editedItem.id);
				this.deleteItemDialogClose();
				const index = this.items.findIndex(item => item.id === this.editedItem.id);
				if (index !== -1) {
					this.items.splice(index, 1)
				}
			} catch (error) {
				console.log('delete smm tracking item error: ', error)
			} finally {
				this.deleteLoading = false;
			}
		},
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Tracking history</h1>

			<v-row justify="space-between">
				<v-col cols="auto">
					<v-row class="flex-wrap" style="max-width: 100%; width: 500px">
						<v-col cols="12">
							<VueDatePicker
									class="density-compact clearable-false"
									v-model="date"
									range
									auto-apply
									:format="format"
									:clearable="false"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectModel
									v-model="model"
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									density="compact"
									clearable
									auto-select
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectPlatformType
									v-model="platformType"
									density="compact"
									clearable
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="auto">
					<div style="max-width: 100%; width: 400px">
						<v-text-field
								v-model="search"
								variant="underlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								density="compact"
								hide-details
						></v-text-field>
					</div>
				</v-col>
			</v-row>

			<v-skeleton-loader v-if="itemsLoading" type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

			<template v-else>

				<template v-if="items.length > 0">
				</template>
				<template v-if="items.length === 0 && itemsLoaded">
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>Нет данных, соответствующих выбранным фильтрам</span>
					</div>
				</template>

				<template v-for="(item, index) in Array.from(new Set(filteredItems.map(elem => elem.platform_type_name )))" :key="index">
					<div v-if="item === 'Twitter' || item === 'Instagram' || item === 'Telegram' || item === 'Tik Tok'" class="mt-6">
						<div class="d-flex">
							<img width="32" class="mr-2" :src="require(`@/assets/icon/${setIcon(item)}.svg`)" alt="twitter">
							<span class="text-h5 font-weight-medium">{{ item }}</span>
						</div>
						<div class="mt-2 overflow-x-auto">
							<div class="d-flex" :class="{'w-fit': mdAndDown}">
								<div
										v-for="(title, titleIndex) in heads"
										:key="titleIndex"
										class="px-4 pt-2 pb-3 text-body-1 font-weight-medium"
										style="width: unset"
										:style="mdAndDown ? { width: sizesMobile[title] + 'px' } : { width: sizes[title] + '%' }"
								>{{headName(title)}}</div>
							</div>
							<template v-for="(row, rowIndex) in filteredItems.filter(elem => elem.platform_type_name === item)" :key="rowIndex">
								<v-hover>
									<template v-slot:default="{ isHovering, props }">
										<div
												v-bind="props"
												class="d-flex bg-white elevation-1 rounded-lg mb-2 cursor-pointer transition"
												:class="[{'bg-cyan-lighten-5': isHovering}, {'w-fit': mdAndDown}]"
												@click="$router.push({ name: 'PromoHistoryDetailed', params: { id: row.id }})"
										>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.date + 'px' } : { width: sizes.date + '%' }">{{ row.track_start }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.end + 'px' } : { width: sizes.end + '%' }">{{ row.track_end }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.model + '%' }">{{ row.only_fans_model_nickname }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.link + 'px' } : { width: sizes.link + '%' }">{{ row.link }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.revenue + 'px' } : { width: sizes.revenue + '%' }">{{ row.revenue }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.claims + 'px' } : { width: sizes.claims + '%' }">{{ row.claims_count }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.comment + 'px' } : { width: sizes.comment + '%' }">{{ row.comment }}</div>
											<div class="px-4 d-flex justify-end align-center" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }">
												<v-btn color="grey" density="comfortable" icon="mdi-pencil" variant="text" @click.stop="editItemDialogOpen(row)"></v-btn>
												<v-btn color="grey" density="comfortable" icon="mdi-delete" variant="text" @click.stop="deleteItemDialogOpen(row)"></v-btn>
											</div>
										</div>
									</template>
								</v-hover>
							</template>
						</div>
					</div>
				</template>
				<template v-if="filteredItems.filter(elem => elem.platform_type_name !== 'Twitter' && elem.platform_type_name !== 'Telegram' && elem.platform_type_name !== 'Instagram' && elem.platform_type_name !== 'Tik Tok').length > 0">
					<div class="mt-6">
						<div class="d-flex"><img width="32" class="mr-2" :src="require('@/assets/icon/planet.svg')" alt="twitter"><span class="text-h5 font-weight-medium">Other</span></div>
						<div class="mt-2 overflow-x-auto">
							<div class="d-flex" :class="{'w-fit': mdAndDown}">
								<div
										v-for="(title, titleIndex) in heads"
										:key="titleIndex"
										class="px-4 pt-2 pb-3 text-body-1 font-weight-medium"
										style="width: unset"
										:style="mdAndDown ? { width: sizesMobile[title] + 'px' } : { width: sizes[title] + '%' }"
								>{{headName(title)}}</div>
							</div>
							<template v-for="(row, rowIndex) in filteredItems.filter(elem => elem.platform_type_name !== 'Twitter' && elem.platform_type_name !== 'Telegram' && elem.platform_type_name !== 'Instagram' && elem.platform_type_name !== 'Tik Tok')" :key="rowIndex">
								<v-hover>
									<template v-slot:default="{ isHovering, props }">
										<div
												v-bind="props"
												class="d-flex bg-white elevation-1 rounded-lg mb-2 cursor-pointer transition"
												:class="[{'bg-cyan-lighten-5': isHovering}, {'w-fit': mdAndDown}]"
												@click="$router.push({ name: 'TrackingHistoryDetail', params: { id: row.id }})"
										>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.date + 'px' } : { width: sizes.date + '%' }">{{ row.track_start }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.end + 'px' } : { width: sizes.end + '%' }">{{ row.track_end }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.model + '%' }">{{ row.only_fans_model_nickname }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.link + 'px' } : { width: sizes.link + '%' }">{{ row.link }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.revenue + 'px' } : { width: sizes.revenue + '%' }">{{ row.revenue }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.claims + 'px' } : { width: sizes.claims + '%' }">{{ row.claims_count }}</div>
											<div class="px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2" :style="mdAndDown ? { width: sizesMobile.comment + 'px' } : { width: sizes.comment + '%' }">{{ row.comment }}</div>
											<div class="px-4 d-flex justify-end align-center" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }">
												<v-btn color="grey" density="comfortable" icon="mdi-delete" variant="text" @click.stop="deleteItemDialogOpen(row)"></v-btn>
											</div>
										</div>
									</template>
								</v-hover>
							</template>
						</div>
					</div>
				</template>
			</template>

			<v-dialog v-model="dialogDelete" max-width="400px">
				<v-card class="pa-4">
					<div class="text-body-1 mb-2">Delete url</div>
					<div class="text-subtitle-2">Are you sure?</div>
					<v-row justify="end" no-gutters class="mt-6 pa-0">
						<v-btn class="mr-2" variant="text" @click="deleteItemDialogClose">Cancel</v-btn>
						<v-btn color="primary" @click="deleteItemConfirm">Agree</v-btn>
					</v-row>
				</v-card>
			</v-dialog>

		</div>
	</div>
</template>

<style scoped>

</style>