<template>
	<div class="page-container pt-4 pb-10 fill-height">
		<MarketingView title="Ads"/>
	</div>
</template>

<script>
	import MarketingView from "@/components/MarketingView.vue";

	export default {
		name: 'AdsPage',


		components: {
			MarketingView,
		},


		data() {
			return {}
		},


		computed: {},


		created() {},


		watch: {},


		methods: {}
	}
</script>

<style scoped>

</style>