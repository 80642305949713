<template>
	<div @scroll.passive="onScroll">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'ScrollingPagination',


	data: () => ({}),


	methods: {
		onScroll(event) {
			const clientHeight = event.target.clientHeight
			const scrollHeight = event.target.scrollHeight
			const scrollTop = event.target.scrollTop
			if (scrollTop + clientHeight + 30 >= scrollHeight) {
				this.$emit('loadMore')
			}
		}
	}
}
</script>

<style scoped>

</style>