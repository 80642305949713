<template>
	<v-card class="elevation-2 rounded-lg">

		<v-row class="pa-4 shift-head" justify="space-between" no-gutters @click="show = !show">
			<v-col class="v-col-12 v-col-md-auto">
				<v-row no-gutters class="flex-wrap flex-md-nowrap">
					<v-col class="v-col-12 v-col-md-auto card-status">
						<div v-if="complited" class="d-flex align-center mb-2"><v-icon class="mr-1" icon="mdi-timer-outline"></v-icon><span class="text-body-2">{{ shiftInfo.duration }}</span></div>
						<template v-else>
							<div v-if="shiftInfo.status === 'progress'" class="text-body-1 font-weight-medium mr-4 mb-2 text-success">Progress</div>
							<div v-if="shiftInfo.status === 'hold'" class="text-body-1 font-weight-medium mr-4 mb-2 text-primary">Hold</div>
						</template>
						<div class="text-body-2 font-weight-medium mr-4 word-spacing">
							<template v-if="userInfo.role === 'superuser' || userInfo.role === 'team_lead' || userInfo.role === 'senior_operator'">{{ shiftInfo.operator?.full_name }}</template>
							<template v-if="userInfo.role === 'operator'">{{ shiftInfo.team_lead.full_name }}</template>
						</div>
					</v-col>
					<v-col v-if="shiftInfo.shift_info" class="v-col-12 v-col-md-auto overflow-x-auto">
						<div v-for="(item, index) in shiftInfo.shift_info.models" :key="item.id" :class="(index + 1) < shiftInfo.shift_info.models.length ? 'mb-3' : ''">
							<table>
								<tbody>
								<tr  v-if="!item.ended || complited" >
									<td width="175" class="pb-2 pr-2 text-left font-weight-bold">{{ item.name }}</td>
									<td width="88" class="pb-2 px-2"><div class="d-flex align-center" title="Sales amount"><v-icon class="mr-1" icon="mdi-currency-usd"></v-icon><span class="text-no-wrap">{{ item.total_sales_amount }}$</span></div></td>
									<td width="88" class="pb-2 px-2"><div class="d-flex align-center" title="Chats with answer"><v-icon class="mr-1" icon="mdi-chat-processing-outline"></v-icon><span class="text-no-wrap">{{ item.count_unique_chats }}</span></div></td>
									<td width="88" class="pb-2 px-2"><div class="d-flex align-center" title="Unique mailing"><v-icon class="mr-1" icon="mdi-email-arrow-right-outline"></v-icon><span class="text-no-wrap">{{ item.count_mass_messages }}</span></div></td>
									<td width="88" class="pb-2 px-2"><div class="d-flex align-center" title="Outgoing messages"><v-icon class="mr-1" icon="mdi-message-arrow-right-outline"></v-icon><span class="text-no-wrap">{{ item.count_outgoing_messages }}</span></div></td>
									<td class="pb-2 px-2"><div class="d-flex align-center" title="Average speed answer (by chat)"><v-icon class="mr-1" icon="mdi-speedometer"></v-icon><span class="text-no-wrap">{{ item.average_speed_answer }} ({{ item.average_speed_answer_by_chat }})</span></div></td>
								</tr>
								</tbody>
							</table>
							<template v-if="shiftInfo.stats.length > 0">
								<template v-for="(itemStat, index) in shiftInfo.stats" :key="index">
									<div v-if="itemStat.only_fans_model?.id === item.id" no-gutters class="d-flex text-grey-darken-1 text-body-2 text-no-wrap">
										<div class="w-25 pr-2">
											<div class="mb-1">Active chats: {{ itemStat.active_chats }}</div>
											<div>Replied to active: {{ itemStat.chats_active_to_replied }}%</div>
										</div>
										<div class="w-25 pr-2">
											<div class="mb-1">With paid content: {{ itemStat.chats_with_paid_content }}</div>
											<div>With payments: {{ itemStat.chats_with_payments }}</div>
										</div>
										<div class="w-33 pr-2">
											<div class="mb-1">Active to payments: {{ itemStat.chats_active_to_with_payments }}%</div>
											<div>Revenue per fan: {{ itemStat.revenue_per_fan }}$</div>
										</div>
										<div class="w-25 pr-2">
											<div class="mb-1">Avr. bill: {{ itemStat.average_bill }}<template v-if="itemStat.average_bill">$</template></div>
											<div>Avr. payments: {{ itemStat.average_payments }}</div>
<!--											<div>replied_chats</div>-->
										</div>
									</div>
								</template>
							</template>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col class="v-col-12 v-col-md-auto">
				<v-row class="flex-column flex-md-row" align="center" justify="center" :justify-md="undefined" no-gutters>
					<template v-if="!complited">
						<template v-if="userInfo.role === 'team_lead' || userInfo.role === 'senior_operator'">
							<v-dialog v-model="confirmEndDialog" max-width="400px">
								<template v-slot:activator="{ props }">
									<v-btn v-bind="props" v-if="shiftInfo.status === 'progress'" :class="smAndDown ? 'my-3' : 'mr-4'" variant="outlined" size="small">End</v-btn>
								</template>
								<v-card class="pa-4">
									<div class="text-body-1 mb-2">End shift</div>
									<div class="text-subtitle-2">Are you sure?</div>
									<v-row justify="end" no-gutters class="mt-6 pa-0">
										<v-btn class="mr-2" variant="text" @click="confirmEndDialog = false">Cancel</v-btn>
										<v-btn color="primary" @click="handleShiftEnd">Agree</v-btn>
									</v-row>
								</v-card>
							</v-dialog>
							<v-dialog v-model="confirmDeleteDialog" max-width="400px">
								<template v-slot:activator="{ props }">
									<v-btn v-bind="props" v-if="shiftInfo.status === 'hold'" :class="smAndDown ? 'my-3' : 'mr-4'" variant="outlined" size="small">Delete</v-btn>
								</template>
								<v-card class="pa-4">
									<div class="text-body-1 mb-2">Delete shift</div>
									<div class="text-subtitle-2">Are you sure?</div>
									<v-row justify="end" no-gutters class="mt-6 pa-0">
										<v-btn class="mr-2" variant="text" @click="confirmDeleteDialog = false">Cancel</v-btn>
										<v-btn color="primary" @click="handleShiftRemove">Agree</v-btn>
									</v-row>
								</v-card>
							</v-dialog>
							<v-btn v-if="shiftInfo.status === 'hold'" :class="smAndDown ? 'my-3' : 'mr-4'" :disabled="buttonDisableStatus" variant="outlined" size="small" @click.stop="handleShiftStart">Start</v-btn>
						</template>
						<template v-if="userInfo.role === 'operator'">
							<v-dialog v-model="confirmEndDialog" max-width="400px">
								<template v-slot:activator="{ props }">
									<v-btn v-bind="props" v-if="shiftInfo.status === 'progress'" :class="smAndDown ? 'my-3' : 'mr-4'" variant="outlined" size="small">End</v-btn>
								</template>
								<v-card class="pa-4">
									<div class="text-body-1 mb-2">End shift</div>
									<div class="text-subtitle-2">Are you sure?</div>
									<v-row justify="end" no-gutters class="mt-6 pa-0">
										<v-btn class="mr-2" variant="text" @click="confirmEndDialog = false">Cancel</v-btn>
										<v-btn color="primary" @click="handleShiftEnd">Agree</v-btn>
									</v-row>
								</v-card>
							</v-dialog>
							<v-btn v-if="shiftInfo.status === 'hold'" :class="smAndDown ? 'my-3' : 'mr-4'" :disabled="buttonDisableStatus" variant="outlined" size="small" @click.stop="handleShiftStart">Start</v-btn>
						</template>
					</template>
					<v-btn
							:icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
							variant="text"
							density="comfortable"
							size="small"
					></v-btn>
				</v-row>
			</v-col>
		</v-row>

		<v-expand-transition>
			<div v-show="show">
				<v-card-text v-if="shiftInfo.shift_info" class="pa-4">
					<v-row :class="smAndDown ? 'flex-wrap' : ''" class="my-0">
						<v-col class="v-col-12 v-col-md-4 py-0">
							<div class="text-subtitle-2 mb-2">Summary information:</div>
							<table class="text-body-2 w-100">
								<tbody>
								<tr>
									<td class="pb-1 text-grey-darken-2">Operator's last message:</td>
									<td class="pb-1 text-grey-darken-3 text-right">{{ shiftInfo.shift_info.total_statistic.last_operator_message_time }}</td>
								</tr>
								<tr>
									<td class="pb-1 text-grey-darken-2">Unique mailing:</td>
									<td class="pb-1 text-grey-darken-3 text-right">{{ shiftInfo.shift_info.total_statistic.total_mass_messages }}</td>
								</tr>
								<tr>
									<td class="pb-1 text-grey-darken-2">Chats with answer:</td>
									<td class="pb-1 text-grey-darken-3 text-right">{{ shiftInfo.shift_info.total_statistic.total_unique_chats }}</td>
								</tr>
								<tr>
									<td class="pb-1 text-grey-darken-2">Outgoing messages:</td>
									<td class="pb-1 text-grey-darken-3 text-right">{{ shiftInfo.shift_info.total_statistic.total_outgoing_messages }}</td>
								</tr>
<!--								<tr>-->
<!--									<td class="pb-1 text-grey-darken-2">Непросмотренных чатов:</td>-->
<!--									<td class="pb-1 text-grey-darken-3 text-right">0</td>-->
<!--								</tr>-->
<!--								<tr>-->
<!--									<td class="pb-1 text-grey-darken-2">Чатов без ответа:</td>-->
<!--									<td class="pb-1 text-grey-darken-3 text-right">0</td>-->
<!--								</tr>-->
								<tr>
									<td class="pb-1 text-grey-darken-2">Average speed answer by chats:</td>
									<td class="pb-1 text-grey-darken-3 text-right">{{ shiftInfo.shift_info.total_statistic.average_speed_answer_by_chat }}</td>
								</tr>
								<tr>
									<td class="pb-1 text-grey-darken-2">Average speed answer:</td>
									<td class="pb-1 text-grey-darken-3 text-right">{{ shiftInfo.shift_info.total_statistic.average_speed_answer }}</td>
								</tr>
								</tbody>
							</table>
						</v-col>
						<v-col class="v-col-12 v-col-md-8 py-0">
							<div class="text-subtitle-2 mb-2">Models:</div>
							<v-row :class="smAndDown ? 'flex-wrap' : ''" class="my-0">
								<template v-for="item in shiftInfo.shift_info.models" :key="item.id">
									<v-col v-if="!item.ended || complited" class="mb-3 v-col-12 v-col-md-4 py-0">
										<div class="elevation-6 rounded-lg pa-2">
											<div class="text-body-1 mb-1">{{ item.name }}</div>
											<div class="d-flex mb-2">
												<div class="spammer-key">{{ item.spammer_key }}</div>
											</div>
											<table class="w-100">
												<thead>
												<tr>
													<th class="pb-1"></th>
													<th class="pb-1" title="Amount"><v-icon icon="mdi-currency-usd"></v-icon></th>
													<th class="pb-1" title="Chats with answer"><v-icon icon="mdi-chat-processing-outline"></v-icon></th>
													<th class="pb-1" title="Unique mailing"><v-icon icon="mdi-email-arrow-right-outline"></v-icon></th>
												</tr>
												</thead>
												<tbody>
												<template v-if="(shiftInfo.status === 'progress') || (shiftInfo.status === 'end')">
													<tr v-for="(detail, index) in item.detail" :key="index">
														<td class="pb-1 text-grey-lighten-1">{{ detail.time }}</td>
														<td class="pb-1 text-center">{{ detail.total_sales_amount }}$</td>
														<td class="pb-1 text-center">{{ detail.count_unique_chats }}</td>
														<td class="pb-1 text-center">{{ detail.count_mass_messages }}</td>
													</tr>
												</template>
												<template v-if="shiftInfo.status === 'hold'">
													<tr v-for="(detail, index) in item.detail" :key="index">
														<td class="pb-1 text-grey-lighten-1">{{ detail.time }}</td>
														<td class="pb-1 text-center">-</td>
														<td class="pb-1 text-center">-</td>
														<td class="pb-1 text-center">-</td>
													</tr>
												</template>
												</tbody>
											</table>
										</div>
									</v-col>
								</template>
							</v-row>
						</v-col>
					</v-row>
					<v-row no-gutters class="mt-6">
<!--						<v-btn class="mr-4" color="primary">Подробнее</v-btn>-->
                        <v-dialog
                                v-model="shiftEditOpen"
                                persistent
                                width="600"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                        v-if="!complited && (userInfo.role === 'team_lead' || userInfo.role === 'senior_operator')"
                                        variant="tonal"
                                        prepend-icon="mdi-pencil"
                                        v-bind="props"
                                >
                                    Edit
                                </v-btn>
                            </template>
                            <ShiftEdit
                                    @close="shiftEditOpen = false"
                                    :info="shiftInfo"
                            />
                        </v-dialog>
					</v-row>
				</v-card-text>
			</div>
		</v-expand-transition>

	</v-card>
</template>

<script>
  import { mapState } from 'vuex';
  import { useDisplay } from 'vuetify';
  import ShiftEdit from '@/components/ShiftEdit.vue';

  export default {
    setup () {
      // Destructure only the keys we want to use
      const { smAndDown } = useDisplay();

      return { smAndDown }
    },


    name: 'ShiftCard',


    components: {
      ShiftEdit,
    },


    props: {
      shiftInfo: {
        type: Object,
        default: null,
        required: true,
      },
      complited: {
        type: Boolean,
        default: false,
      }
    },


    data() {
      return {
        show: false,
        shiftEditOpen: false,
        checkReadyInterval: null,
        buttonDisableStatus: true,
        confirmDeleteDialog: false,
        confirmEndDialog: false,
      }
    },


    computed: {
      ...mapState({
        userInfo: state => state.user.info,
      })
    },


    created() {
      if (!this.complited) {
        if (this.userInfo.role === 'operator' || this.userInfo.role === 'senior_operator' || this.userInfo.role === 'team_lead') {
          this.startChecking()
        }
      }
    },


    unmounted() {
      clearInterval(this.checkReadyInterval)
    },


    methods: {
      async handleShiftRemove() {
        await this.$store.dispatch('shifts/removeShift', this.shiftInfo.id);
      },
      async handleShiftStart() {
        try {
          await this.$store.dispatch('shifts/startShift', this.shiftInfo);
        } catch(error) {
          console.log(error);
        }
      },
      async handleShiftEnd() {
        try {
          await this.$store.dispatch('shifts/endShift', this.shiftInfo.id);
        } catch (error) {
          console.log(error);
        }
      },
      startChecking() {
        this.checkReady();
        this.startInterval()
      },
      startInterval() {
        this.checkReadyInterval = setInterval(() => this.checkReady(), 1000);
      },
      checkReady() {
        const date = new Date();
        date.setHours(date.getHours() + 1);
        const start = new Date(this.shiftInfo.shift_start_utc + '.000Z');
        if (date > start) {
          this.buttonDisableStatus = false
        }
      }
    }
  }
</script>

<style scoped xml:lang="less">
	.shift-head {
		cursor: pointer;
	}

	.word-spacing {
		word-spacing: 9999px;
	}

    .spammer-key {
        color: #BDBDBD;
        font-size: 11px;
        border: 1px solid #757575;
        border-radius: 4px;
        padding: 2px 4px;
    }

	.card-status {
		width: 150px;
	}
</style>