<template>
	<div>404</div>
</template>

<script>
  export default {
    name: 'NotFoundPage'
  }
</script>

<style scoped>

</style>