<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">
			<h1 class="text-h4 mb-8">Отрицательный ROMI</h1>
			<v-row class="justify-space-between">
				<v-col cols="auto">
					<v-row class="flex-wrap" style="max-width: 100%; width: 500px">
						<v-col cols="12">
							<VueDatePicker
									class="density-compact clearable-false"
									v-model="date"
									range
									auto-apply
									:format="format"
									:clearable="false"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectModel
									v-model="model"
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									density="compact"
									clearable
									auto-select
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<SelectMarketer
									v-model="marketer"
									density="compact"
									clearable
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="auto">
					<div class="mb-3" style="max-width: 100%; width: 400px">
						<v-text-field
								v-model="search"
								variant="underlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								density="compact"
								clearable
						></v-text-field>
					</div>
					<v-range-slider
							:disabled="rangeDisabled"
							v-model="range"
							color="primary"
							step="1"
							:min="rangeMin"
							:max="rangeMax"
							thumb-label="always"
							label="Claims count"
					></v-range-slider>
				</v-col>
			</v-row>

			<div class="overflow-x-auto px-1">

				<div class="d-flex" :class="{'w-fit': mdAndDown}">
					<v-btn
							variant="text"
							v-for="(item, index) in heads"
							:key="index"
							class="px-2 text-body-1 font-weight-medium cursor-pointer justify-start"
							:class="(ordering === item || ordering === '-' + item) ? 'text-primary' : ''"
							:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
							@click="setOrdering(item)"
					>
						<template v-slot:append>
							<v-icon v-if="ordering === item">mdi-chevron-up</v-icon>
							<v-icon v-if="ordering === '-' + item">mdi-chevron-down</v-icon>
						</template>
						{{headName(item)}}
					</v-btn>
				</div>

				<template v-if="filteredItems.length > 0">
					<div class="bg-white rounded-lg elevation-1 mb-2" :class="{'w-fit': mdAndDown}">
						<template v-for="(item, index) in filteredItems" :key="index">
							<v-divider v-if="index > 0"></v-divider>
							<div
									class="d-flex"
							>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.marketer + 'px' } : { width: sizes.marketer + '%' }">{{ item.marketer }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.only_fans_model + 'px' } : { width: sizes.only_fans_model + '%' }">{{ item.only_fans_model }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.donor + 'px' } : { width: sizes.donor + '%' }">{{ item.donor }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.buy_date + 'px' } : { width: sizes.buy_date + '%' }">{{ item.buy_date }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.date + 'px' } : { width: sizes.date + '%' }">{{ item.date }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.claims_count + 'px' } : { width: sizes.claims_count + '%' }">{{ item.claims_count }}</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.cost_result + 'px' } : { width: sizes.cost_result + '%' }">{{ item.cost_result }}$</div>
								<div :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.romi + 'px' } : { width: sizes.romi + '%' }">{{ item.romi }}%</div>
							</div>
						</template>
					</div>
				</template>
				<template v-if="items.length === 0 && itemsLoaded">
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>Нет данных, соответствующих выбранным фильтрам</span>
					</div>
				</template>

				<v-skeleton-loader v-if="itemsLoading" type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

			</div>

		</div>
	</div>
</template>

<script>
import SelectModel from "@/components/SelectModel.vue";
import SelectMarketer from "@/components/SelectMarketer.vue";
import apiAds from "@/api/ads";
import {mapState} from "vuex";
import {useDisplay} from "vuetify";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: 'RomiPage',


	components: {
		SelectModel,
		SelectMarketer
	},


	data() {
		return {
			tableCellClass: 'px-2 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2',
			date: this.setDefaultDateRange(),
			format: 'dd.MM.yyyy',
			model: null,
			marketer: null,
			ordering: '-marketer',
			search: '',
			page: 1,
			searchItem: [],
			rangeDisabled: true,
			range: [0, 0],
			rangeMin: 0,
			rangeMax: 0,
			heads: ['marketer', 'only_fans_model', 'donor', 'buy_date', 'date', 'claims_count', 'cost_result', 'romi'],
			sizes: {
				marketer: '15',
				only_fans_model: '15',
				donor: '15',
				buy_date: '10',
				date: '11',
				claims_count: '12',
				cost_result: '12',
				romi: '10',
			},
			sizesMobile: {
				marketer: '150',
				only_fans_model: '150',
				donor: '150',
				buy_date: '100',
				date: '110',
				claims_count: '120',
				cost_result: '120',
				romi: '100',
			},
			items: [],
			itemsLoaded: false,
			itemsLoading: false,
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
		filteredItems() {
			return this.searchItem.filter((item) => {
				for(let key in item) {
					if (item[key] >= this.range[0] && item[key] <= this.range[1]) return true
				}
			});
		}
	},


	created() {
		this.getRomi();
		document.addEventListener('scroll', this.handleScroll);
	},


	unmounted () {
		document.removeEventListener('scroll', this.handleScroll);
	},


	watch: {
		ordering() {
			this.paginationReset();
			this.getRomi();
		},
		date() {
			this.paginationReset();
			this.getRomi();
		},
		model() {
			this.paginationReset();
			this.getRomi();
		},
		marketer() {
			this.paginationReset();
			this.getRomi();
		},
		search() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.paginationReset();
				this.getRomi()
			}, 400);
		}
	},


	methods: {
		paginationReset() {
			this.items= [];
			this.itemsLoaded = false;
			this.page = 1;
		},
		handleScroll (event) {
			const clientHeight = event.target.body.clientHeight
			const scrollHeight = event.target.body.scrollHeight
			const scrollTop = window.scrollY
			if (scrollTop + clientHeight + 30 >= scrollHeight) {
				if (this.page && !this.itemsLoading) {
					this.getRomi()
				}
			}
		},
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), -14);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		headName(value) {
			if (value === 'marketer') return 'Marketer';
			if (value === 'only_fans_model') return 'Our model';
			if (value === 'donor') return 'Donor';
			if (value === 'buy_date') return 'Buy date';
			if (value === 'date') return 'Date';
			if (value === 'claims_count') return 'Claims count';
			if (value === 'cost_result') return 'Cost result';
			if (value === 'romi') return 'ROMI';
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		async getRomi() {
			const params = {
				date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
				date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
				only_fans_model: this.model,
				marketer: this.marketer,
				ordering: this.ordering,
				search: this.search,
				page: this.page,
			}
			try {
				this.itemsLoaded = false;
				this.itemsLoading = true;
				const response = await apiAds.getRomi(params);
				this.items = this.items.concat(response.results)
				this.page = response.next;
				this.itemsLoaded = true;
				this.itemsLoading = false;
				this.setRangeData();
				this.searchItem = this.items
			} catch (error) {
				console.log('error', error)
			}
		},
		setRangeData() {
			if (this.items.length > 0) {
				this.rangeDisabled = false;
				const min = this.items.reduce((acc, curr) => acc.claims_count < curr.claims_count ? acc : curr).claims_count;
				const max = this.items.reduce((acc, curr) => acc.claims_count > curr.claims_count ? acc : curr).claims_count;
				this.range = [min, max];
				if (min === max) {
					this.rangeMin = 0
				} else {
					this.rangeMin = min;
				}
				this.rangeMax = max
			} else {
				this.rangeDisabled = true;
				this.range = [0, 10];
				this.rangeMin = 0;
				this.rangeMax = 10;
			}
		},
		setOrdering(value) {
			if (this.ordering === '-' + value || this.ordering === value) {
				console.log('value: ', value)
				if (this.ordering === value) {
					this.ordering = '-' + value;
				} else {
					this.ordering = value;
				}
			} else {
				this.ordering = '-' + value;
			}
		},
	},
}
</script>

<style scoped>

</style>