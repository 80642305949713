<template>
	<v-card class="pa-6">
		<div class="text-h5 mb-8">{{ formTitle }}</div>
		<v-form @submit.prevent>
			<v-row class="flex-wrap">
				<v-col :cols="mdAndDown ? 12 : 6">
					<div class="text-body-1 mb-2">Маркетолог</div>
					<SelectMarketer
							v-model="fields.marketer"
							:rules="[rules.required]"
							@click="marketerError = ''"
							:error-messages="marketerError"
					/>
				</v-col>
				<v-col :cols="mdAndDown ? 12 : 6">
					<div class="text-body-1 mb-2">Донор</div>
					<SelectDonor
							v-model="fields.donor"
							:rules="[rules.required]"
							@click="donorError = ''"
							:error-messages="donorError"
							creation
							:return-object="false"
					/>
				</v-col>
				<v-col cols="12">
					<div class="text-body-1 mb-2">Комментарий</div>
					<v-textarea
							v-model="fields.comment"
							variant="outlined"
							placeholder="Some comment here"
					></v-textarea>
				</v-col>
			</v-row>
			<v-row justify="end" no-gutters class="mt-4 pa-0">
				<v-btn variant="text" @click="$emit('close')" class="mr-2">Отмена</v-btn>
				<v-btn v-if="(edited !== -1)" :loading="formLoading" type="submit" color="primary" @click="editBlackListItem">Сохранить</v-btn>
				<v-btn v-else :loading="formLoading" type="submit" color="primary" @click="addBlackListItem">Создать</v-btn>
			</v-row>
		</v-form>
	</v-card>
</template>

<script>
import apiAds from '@/api/ads';
import SelectMarketer from "@/components/SelectMarketer.vue";
import SelectDonor from "@/components/SelectDonor.vue";
import {useDisplay} from "vuetify";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: 'BlacklistItemEditDialog',


	components: {
		SelectMarketer,
		SelectDonor,
	},


	props: {
		edited: {},
		info: {},
	},


	data() {
		return {
			rules: {
				required: value => {
					if (value === []) return 'Обязательное поле';
					if (value) return true;
					return 'Обязательное поле'
				},
			},
			formLoading: false,
			fields: {
				id: '',
				donor: null,
				comment: '',
				marketer: null,
			},
			donorError: '',
			marketerError: '',
		}
	},


	computed: {
		formTitle () {
			return this.edited === -1 ? 'Новый элемент чёрного списка' : 'Редактировать элемент чёрного списка'
		},
	},


	created() {
		if (this.edited !== -1) {
			this.fields.id = this.info.id;
			this.fields.donor = this.info.donor.name;
			this.fields.comment = this.info.comment;
			this.fields.marketer = this.info.marketer.id;
		}
	},


	methods: {
		async addBlackListItem() {
			try {
				this.formLoading = true;
				const response = await apiAds.addBlackListItem({
					comment: this.fields.comment,
					donor: this.fields.donor,
					marketer: this.fields.marketer
				});
				this.$emit('add', response)
				this.$emit('close')
			} catch (error) {
				console.log('error', error)
				this.donorError = error.response.data?.donor[0]
				this.marketerError = error.response.data?.marketer[0]
			} finally {
				this.formLoading = false;
			}
		},

		async editBlackListItem() {
			try {
				this.formLoading = true;
				const response = await apiAds.editBlackListItem(this.fields.id, {
					comment: this.fields.comment,
					donor: this.fields.donor,
					marketer: this.fields.marketer
				});
				this.$emit('edit', response)
				this.$emit('close')
			} catch (error) {
				console.log('error', error);
				if (error.response.data.donor) {
					this.donorError = error.response.data.donor[0];
				}
				if (error.response.data.marketer) {
					this.marketerError = error.response.data.marketer[0]
				}
			} finally {
				this.formLoading = false;
			}
		},
	}
}
</script>

<style scoped>

</style>