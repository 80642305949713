<script>
  import BaseBack from "@/components/BaseBack.vue";
  import apiManagers from "@/api/managers";
  import DatabookInfo from "@/components/DatabookInfo.vue";
  import DatabookInfoEditMode from "@/components/DatabookInfoEditMode.vue";

  export default {
    name: "DatabookDetailPage",


    components: {
      DatabookInfoEditMode,
      DatabookInfo,
      BaseBack
    },


    props: {
      id: {
        type: [Number, String],
        required: true
      },
    },


    data() {
      return {
        pageTitle: 'Databook',
        editMode: false,
        info: {},
        infoLoading: false,
        infoLoaded: false,
      }
    },


    created() {
      this.getDatabookDetail();
			if (this.$route.query.edit) {
				this.editMode = true
			}
    },


    methods: {
      async getDatabookDetail() {
        try {
          this.infoLoading = true;
          this.infoLoaded = false;
          this.info = await apiManagers.getDatabookDetail(this.id);
          if (this.info.yes_list === null) { this.info.yes_list = '' }
          if (this.info.stop_list === null) { this.info.stop_list = '' }
          if (this.info.like === null) { this.info.like = '' }
          if (this.info.dont_like === null) { this.info.dont_like = '' }
          this.infoLoading = false;
          this.infoLoaded = true;
        } catch (error) {
          console.log(error)
        }
      },

      updateInfo(data) {
        this.info = data
      }
    }
  }
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<BaseBack class="mb-4" />

			<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>

			<template v-if="infoLoaded">
				<DatabookInfoEditMode
						v-if="editMode"
						:model-info="info"
						@disable-edit-mode="editMode = false; $router.replace({'query': null})"
						@update-info="updateInfo"
				/>
				<DatabookInfo
						v-else
						:info="info"
						@enable-edit-mode="editMode = true"
				/>
			</template>

			<template v-else>
				<v-card>
					<v-row>
						<v-col>
							<v-skeleton-loader height="100" max-width="500" type="list-item-avatar-two-line"></v-skeleton-loader>
						</v-col>
						<v-col cols="auto">
							<v-skeleton-loader height="100" width="100" type="button"></v-skeleton-loader>
						</v-col>
					</v-row>
				</v-card>
				<v-skeleton-loader class="my-3 bg-transparent" type="button@3"></v-skeleton-loader>
				<v-row>
					<v-col cols="4">
						<v-row>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="heading, list-item@7"></v-skeleton-loader>
							</v-col>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="heading, list-item@3"></v-skeleton-loader>
							</v-col>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="heading, list-item@3"></v-skeleton-loader>
							</v-col>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="heading, list-item@3"></v-skeleton-loader>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="8">
						<v-row>
							<v-col cols="6">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
							<v-col cols="6">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
							<v-col cols="6">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
							<v-col cols="6">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
							<v-col cols="12">
								<v-skeleton-loader elevation="1" type="article"></v-skeleton-loader>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>

		</div>
	</div>
</template>

<style scoped>

</style>