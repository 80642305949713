import '@vuepic/vue-datepicker/dist/main.css'
import "@/assets/style/main.less";

import { createApp } from 'vue'
import router from '@/router.js'
import store from '@/store/index'
import vuetify from './plugins/vuetify'
import axios from '@/services/axios-instance'
import VueAxios from 'vue-axios';

import { loadFonts } from './plugins/webfontloader'

import App from './App.vue'
import VueDatePicker from '@vuepic/vue-datepicker'

loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueAxios, axios)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app');