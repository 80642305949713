<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Shifts</h1>

			<LeadsChipsGroup />
			<TotalStatPanel class="mb-10"/>
			<ShiftList />

		</div>
	</div>
</template>

<script>

  import LeadsChipsGroup from "@/components/LeadsChipsGroup.vue";
	import ShiftList from "@/components/ShiftList.vue";
	import TotalStatPanel from "@/components/TotalStatPanel.vue";

	export default {
    name: 'ShiftsPage',


    components: {
			TotalStatPanel, ShiftList, LeadsChipsGroup

		},


    data: () => ({

		}),


		methods: {

		}
  }
</script>

<style scoped>

</style>