<script>
import apiFinance from "@/api/finance"

export default {
	name: "WidgetDeptBy",


	data() {
		return {
			selectItems: [
					{ value: 'future_purchases', title: 'Future purchases' },
					{ value: 'debts', title: 'Debt by' },
					{ value: 'total', title: 'Total' },
			],
			select: 'future_purchases',
			tabs: 'by_donor',
			search: '',
			searchItem: [],
			itemsLoading: false,
			itemsLoaded: false,
			items: {},
			copy: [],
			searchCopy: null,
			copyLoaded: false,
			copyLoading: false,
		}
	},


	computed: {
		filteredItems() {
			// console.log(this.items[this.select][this.tabs])
			return this.searchItem.filter((item) => {
				if (item.name.toLowerCase().match(this.search)) return true
			});
		},
	},


	created() {
		this.getItems();
		this.getItemsCopy();
	},


	watch: {
		select() {
			this.searchItem = this.items[this.select][this.tabs];
			this.searchCopy = this.copy[this.select][this.tabs]
		},
		tabs() {
			this.searchItem = this.items[this.select][this.tabs];
			this.searchCopy = this.copy[this.select][this.tabs]
		}
	},


	methods: {
		async getItems() {
			try {
				this.itemsLoaded = false;
				this.itemsLoading = true;
				this.items = await apiFinance.getCategoriesSummary();
				this.searchItem = this.items[this.select][this.tabs];
				this.itemsLoaded = true;
				this.itemsLoading = false
			} catch (error) {
				console.log(error)
			}
		},
		async getItemsCopy() {
			try {
				this.copyLoaded = false;
				this.copyLoading = true;
				this.copy = await apiFinance.getCategoriesSummaryCopy();
				this.searchCopy = this.copy[this.select][this.tabs];
				this.copyLoaded = true;
				this.copyLoading = false
			} catch (error) {
				console.log(error)
			}
		},
		copyTable() {
			window.navigator.clipboard.writeText(this.searchCopy)
		}
	}
}
</script>

<template>
	<v-sheet v-if="itemsLoaded" class="rounded-lg px-5 py-4 elevation-1 flex-column d-flex">
		<div class="flex-0-0">
			<v-row justify="space-between" align="center" class="flex-nowrap mb-2">
				<v-col cols="6" class="flex-1-1">
					<v-select
							density="compact"
							variant="underlined"
							:items="selectItems"
							v-model="select"
							item-title="title"
							item-value="value"
							hide-details
					></v-select>
				</v-col>
				<v-col cols="6" class="flex-1-1">
					<v-text-field
							v-model="search"
							variant="underlined"
							label="Search"
							append-inner-icon="mdi-magnify"
							density="compact"
							hide-details
					></v-text-field>
				</v-col>
				<v-col cols="auto">
					<v-btn variant="flat" size="small" icon="mdi-content-copy" @click="copyTable"></v-btn>
				</v-col>
			</v-row>
			<v-tabs
					class="mb-8"
					v-model="tabs"
					color="primary"
					align-tabs="center"
					fixed-tabs
			>
				<v-tab value="by_donor">Donor</v-tab>
				<v-tab value="by_marketer">Marketer</v-tab>
			</v-tabs>
		</div>
		<div v-if="filteredItems.length > 0" class="flex-1-1 overflow-y-auto">
			<div
					class="d-flex pa-2 rounded text-center justify-space-between"
					:class="[index % 2 ? 'bg-grey-lighten-4' : 'bg-white', {'bg-deep-orange-lighten-2': item.active}]"
					v-for="(item, index) in filteredItems"
					:key="index"
			>
				<div class="text-body-2">{{ item.name }}</div>
				<div class="text-body-2">{{ item.value }}</div>
			</div>
		</div>
		<div v-else class="mt-10 text-body-1 text-center">
			<span>...</span><br>
			<span>Empty list</span>
		</div>
	</v-sheet>
	<v-card v-else class="pa-4 fill-height rounded-lg d-flex align-center justify-center">
		<v-progress-circular indeterminate color="primary"></v-progress-circular>
	</v-card>
</template>

<style scoped>

</style>