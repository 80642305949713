<script>
export default {
	name: "BaseBack",


	methods: {
		goBack() {
			if (window.history.state.back !== null && window.history.length > 1) {
				this.$router.go(-1)
			} else {
				this.$router.push('/')
			}
		},
	}
}
</script>

<template>
	<v-btn
			class="d-flex align-center cursor-pointer"
			@click="goBack"
			prepend-icon="mdi-keyboard-backspace"
			variant="text"
			text="Back"
			size="large"
	></v-btn>
</template>

<style scoped>

</style>