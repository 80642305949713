<script>
import apiSmm from "@/api/smm";

export default {
	name: "TrackingEditItemDialog",


	props: {
		edited: {},
		info: {},
	},


	data() {
		return {
			rules: {
				required: value => {
					if (value === []) return 'Required field';
					if (value) return true;
					return 'Required field'
				},
			},
			formLoading: false,
			link: null,
			comment: null,
			linkInfo: [],
			infoLoading: false,
			infoLoaded: false,
			errors: {}
		}
	},


	computed: {
		formTitle () {
			return this.edited === -1 ? 'Add url' : 'Url'
		},
	},


	watch: {
		link() {
			if (this.edited === -1) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.getAdsByUrl()
				}, 400);
			}
		}
	},


	created() {
		if (this.edited !== -1) {
			this.link = this.info.link;
			this.comment = this.info.comment;
			this.infoLoaded = true
			this.linkInfo[0] = this.info
		}
	},


	methods: {
		setIcon(platform) {
			if (platform === 'Twitter') { return 'twitter' }
			else if (platform === 'Telegram') { return 'telegram' }
			else if (platform === 'Instagram') { return 'instagram' }
			else if (platform === 'TikTok') { return 'tiktok' }
			else if (platform === 'Reddit') { return 'reddit' }
			else { return 'planet' }
		},
		async getAdsByUrl() {
			try {
				this.infoLoading = true;
				this.infoLoaded = false;
				this.linkInfo = await apiSmm.getAdsByUrl(this.link);
			} catch (error) {
				this.linkInfo = [];
				console.log('error', error)
			} finally {
				this.infoLoading = false;
				this.infoLoaded = true;
			}
		},
		async addItem() {
			try {
				this.formLoading = true;
				const response = await apiSmm.addTrackingItem({
					comment: this.comment,
					ads: this.linkInfo[0].id,
				});
				this.$emit('add', response);
				this.$emit('close')
			} catch (error) {
				console.log('error', error)
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		},

		async editItem() {
			try {
				this.formLoading = true;
				const response = await apiSmm.editTrackingItem(this.info.id, {
					comment: this.comment,
				});
				this.$emit('edit', response)
				this.$emit('close')
			} catch (error) {
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		},
	}
}
</script>

<template>
	<v-card class="pa-6">
		<div class="text-h5 mb-8">{{ formTitle }}</div>
		<v-form :disabled="formLoading" @submit.prevent>

			<v-text-field
					variant="outlined"
					v-model="link"
					placeholder="Enter url"
					:rules="[rules.required]"
					:error-messages="errors.ads"
					:disabled="edited !== -1"
			></v-text-field>

			<template v-if="link">
				<div v-if="!infoLoaded" class="d-flex justify-center flex-grow-1">
					<v-progress-circular color="primary" indeterminate></v-progress-circular>
				</div>
				<template v-else>
					<template v-if="linkInfo.length > 0">
						<template v-if="linkInfo.length === 1">
							<div class="d-flex mt-4 mb-6"><img width="32" class="mr-2" :src="require(`@/assets/icon/${setIcon(linkInfo[0].platform_type_name)}.svg`)" :alt="linkInfo[0].platform_type_name"><span class="text-h5 font-weight-medium">{{ linkInfo[0].platform_type_name }}</span></div>
							<v-row>
								<v-col cols="auto" class="pr-10">
									<div class="text-body-1 mb-2">Model</div>
									<div class="text-body-2 font-weight-light">{{ linkInfo[0].only_fans_model_nickname }}</div>
								</v-col>
								<v-col cols="auto" class="pr-10">
									<div class="text-body-1 mb-2">Revenue</div>
									<div class="text-body-2 font-weight-light">{{ linkInfo[0].revenue }}$</div>
								</v-col>
								<v-col cols="auto" class="pr-10">
									<div class="text-body-1 mb-2">Claims count</div>
									<div class="text-body-2 font-weight-light">{{ linkInfo[0].claims_count }}</div>
								</v-col>
							</v-row>
							<div class="mt-8">
								<div class="text-body-1 mb-2">Comment</div>
								<v-textarea
										v-model="comment"
										variant="outlined"
										placeholder="Some comment here"
								></v-textarea>
							</div>
						</template>
						<template v-else>
							<div class="d-flex align-center"><v-icon color="error">mdi-alert-circle</v-icon><span class="ml-2">More then 1 trial links</span></div>
						</template>
					</template>
					<template v-else>
						<div class="d-flex align-center"><v-icon color="error">mdi-alert-circle</v-icon><span class="ml-2">Not found</span></div>
					</template>
				</template>
			</template>

			<v-row justify="end" no-gutters class="mt-4 pa-0">
				<v-btn variant="text" @click="$emit('close')" class="mr-2">Cancel</v-btn>
				<v-btn v-if="(edited !== -1)" :loading="formLoading" type="submit" color="primary" @click="editItem">Save</v-btn>
				<v-btn v-else :loading="formLoading" type="submit" color="primary" @click="addItem">Add</v-btn>
			</v-row>

		</v-form>
	</v-card>
</template>

<style scoped>

</style>