<template>

		<v-card class="pa-6">

			<v-form class="fill-height d-flex flex-column" :disabled="formLoading || userLoading" @submit.prevent>

				<div class="flex-1-1 overflow-y-auto px-3 mx-n3">
					<div class="text-h5 mb-8">{{ info.username_of }}</div>
					<v-list-subheader class="mb-4">Personal information</v-list-subheader>
					<v-row>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Genre</div>
							<v-autocomplete
									placeholder="Select genre"
									variant="outlined"
									item-title="name"
									item-value="id"
									:loading="!genresLoaded"
									:disabled="!genresLoaded"
									v-model="fields.genre"
									:items="genres"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Status</div>
							<v-autocomplete
									placeholder="Select status"
									variant="outlined"
									item-title="name"
									item-value="id"
									:loading="!statusesLoaded"
									:disabled="!statusesLoaded"
									v-model="fields.status"
									:items="statuses"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Start Date</div>
							<VueDatePicker
									position="right"
									class="clearable-false"
									v-model="fields.start_date"
									auto-apply
									:format="format"
									clearable
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Page type</div>
							<v-autocomplete
									placeholder="Select page type"
									variant="outlined"
									item-title="name"
									item-value="id"
									:loading="!pageTypesLoaded"
									:disabled="!pageTypesLoaded"
									v-model="fields.page_type"
									:items="pageTypes"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Team lead</div>
							<v-autocomplete
									placeholder="Select teamlead"
									variant="outlined"
									item-title="full_name"
									item-value="id"
									v-model="fields.team_lead"
									:items="leads"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Tingz username</div>
							<v-text-field
									variant="outlined"
									v-model="fields.tingz_username"
									placeholder="Enter Tingz username"
							></v-text-field>
						</v-col>
						<v-col v-if="userInfo.role === 'superuser'" :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Marketer</div>
							<v-autocomplete
									placeholder="Select marketer"
									variant="outlined"
									item-title="full_name"
									item-value="id"
									:loading="!marketersLoaded"
									:disabled="!marketersLoaded"
									v-model="fields.marketer"
									:items="marketers"
							></v-autocomplete>
						</v-col>
					</v-row>
				</div>

				<div class="flex-0-0">
					<v-divider></v-divider>
					<v-row justify="start" no-gutters class="mt-6 pa-0">
						<v-btn v-if="(edited !== -1)" :loading="formLoading || userLoading" type="submit" color="primary" @click="handleEditModel">Save</v-btn>
						<v-btn variant="text" @click="$emit('close')" class="ml-2">Cancel</v-btn>
					</v-row>
				</div>

			</v-form>
		</v-card>

</template>

<script>
  import {mapState} from 'vuex'
	import apiModels from "@/api/models";
	import apiMarketers from "@/api/marketers"
	import {useDisplay} from "vuetify";

  export default {
		setup () {
			// Destructure only the keys we want to use
			const { mdAndDown } = useDisplay();
			return { mdAndDown }
		},


    name: 'ModelEditDialog',


    components: {

    },


    props: {
      edited: {},
      info: {},
    },


    data() {
      return {
        rules: {
          required: value => {
            if (value) return true;
            return 'Required field'
          },
        },
				format: 'dd.MM.yyyy',
        leads: [],
				genres: [],
				genresLoaded: false,
				genresLoading: false,
				pageTypes: [],
				pageTypesLoaded: false,
				pageTypesLoading: false,
				statuses: [],
				statusesLoaded: false,
				statusesLoading: false,
				marketers: [],
				marketersLoaded: false,
				marketersLoading: false,
        fields: {
					genre: null,
					tingz_username: '',
          page_type: null,
          status: null,
          start_date: null,
					marketer: null,
					team_lead: null,
        },
        formLoading: false,
        formLoaded: false,
      }
    },


    computed: {
      ...mapState({
        userInfo: state => state.user.info,
				userLoading: state => state.user.userLoading,
				userLoaded: state => state.user.userLoaded,
        teamLeads: state => state.user.teamLeads,
      }),
      formTitle () {
        return this.edited === -1 ? 'New model' : 'Edit model'
      },
    },


    created() {
      if (this.edited !== -1) {
				if (this.info.genre) this.fields.genre = this.info.genre.id;
				if (this.info.status) this.fields.status = this.info.status.id;
				this.fields.start_date = this.info.start_date;
				if (this.info.page_type) this.fields.page_type = this.info.page_type.id;
				if (this.info.team_lead) this.fields.team_lead = this.info.team_lead.id;
				this.fields.tingz_username = this.info.tingz_username;
				if (this.info.marketer) this.fields.marketer = this.info.marketer.id;
			}
      this.getLeads();
      this.getModelGenres();
      this.getModelPageTypes();
      this.getModelStatuses();
			if (this.userLoaded) {
				if (this.userInfo.role === 'superuser') {
					this.getMarketers();
				}
			}
    },


		watch: {
			userLoaded(value) {
				if (value) {
					if (this.userInfo.role === 'superuser') {
						this.getMarketers();
					}
				}
			}
		},


    methods: {
			formatingDate(date, format) {
				const resetDate = new Date(date);
				const year = resetDate.getFullYear();
				const month = (resetDate.getMonth() + 1).toString().padStart(2, '0');
				const day = (resetDate.getDate().toString().padStart(2, '0'));
				if (format === 'dd.MM.yyyy') {
					return `${day}.${month}.${year}`;
				} else if (format === 'yyyy-MM-dd') {
					return `${year}-${month}-${day}`;
				}
			},
      getLeads() {
        if (this.userInfo.role === 'superuser') {
          this.leads = this.teamLeads
        }
        if ((this.userInfo.role === 'team_lead') || (this.userInfo.role === 'senior_operator')) {
          this.leads = this.$store.getters['user/getLeads']
        }
      },
			async getModelGenres() {
				try {
					this.genresLoaded = false;
					this.genresLoading = true;
					this.genres = await apiModels.getModelGenres();
					this.genresLoaded = true;
					this.genresLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			},
			async getModelPageTypes() {
				try {
					this.pageTypesLoaded = false;
					this.pageTypesLoading = true;
					this.pageTypes = await apiModels.getModelPageTypes();
					this.pageTypesLoaded = true;
					this.pageTypesLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			},
			async getModelStatuses() {
				try {
					this.statusesLoaded = false;
					this.statusesLoading = true;
					this.statuses = await apiModels.getModelStatuses();
					this.statusesLoaded = true;
					this.statusesLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			},
			async getMarketers() {
				try {
					this.marketersLoaded = false;
					this.marketersLoading = true;
					this.marketers = await apiMarketers.getMarketers();
					this.marketersLoaded = true;
					this.marketersLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			},
      async handleEditModel() {
        this.formLoading = true;
        const fields = Object.assign({}, this.fields);
				if (this.fields.start_date) { fields.start_date = this.formatingDate(fields.start_date, 'yyyy-MM-dd') }
				if (this.fields.tingz_username) { fields.tingz_username = fields.tingz_username.toLowerCase() }
				if (this.userInfo.role !== 'superuser') {
					delete fields.marketer
				}
        try {
          await this.$store.dispatch('user/editModel', {fields, id: this.info.id});
          this.$emit('close')
        } catch (error) {
          console.log(error)
        } finally {
          this.formLoading = false;
          this.formLoaded = true;
        }
      },
    }
  }
</script>

<style scoped>

</style>