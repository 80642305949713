import axios from "@/services/axios-instance";

export default {
  async getMarketers() {
    const response = await axios.get('/v1/marketers/');
    return response.data
  },

  async addMarketer(data) {
    const response = await axios.post('/v1/marketers/', data);
    return response.data
  },

  async editMarketer(data, id) {
    const response = await axios.patch(`/v1/marketers/${id}/`, data);
    return response.data
  },

  async deleteMarketer(id) {
    await axios.delete(`/v1/marketers/${id}/`);
  }
}