import api from '@/api/managers'
import {
  SET_MANAGERS,
  MANAGERS_LOADING,
  MANAGERS_LOADED,
  ADD_MANAGER,
  EDIT_MANAGER,
  DELETE_MANAGER,
} from './constants'
import { RESET_STATE } from '@/store/constants'
import resetState from '@/store/helpers/resetState'

/*--------------------------Initial State------------------------------*/

export const initialState = {
  managers: [],
  managersLoading: false,
  managersLoaded: false,
};

/*----------------------------Mutations----------------------------*/

const mutations = {
  [SET_MANAGERS](state, data) {
    state.managers = data
  },
  [MANAGERS_LOADING](state, value) {
    state.managersLoading = value
  },
  [MANAGERS_LOADED](state, value) {
    state.managersLoaded = value
  },
  [ADD_MANAGER](state, manager) {
    const target = state.managers.find(item => item.id === manager.id);
    if (target === undefined) {
      state.managers.push(manager)
    }
  },
  [EDIT_MANAGER](state, manager) {
    const index = state.managers.findIndex(item => item.id === manager.id);
    if (index !== -1) {
      state.managers[index] = manager
    }
  },
  [DELETE_MANAGER](state, id) {
    const index = state.managers.findIndex(manager => manager.id === id);
    if (index !== -1) {
      state.managers.splice(index, 1)
    }
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  },
};

/*----------------------------Actions----------------------------*/

const actions = {
  async getManagers({commit}) {
    commit(MANAGERS_LOADING, true);
    try {
      const data = await api.getManagers();
      await commit(SET_MANAGERS, data);
      commit(MANAGERS_LOADED, true)
    } catch (err) {
      console.log('get managers error: ', err)
    } finally {
      commit(MANAGERS_LOADING, false);
    }
  },
  async addManager({commit}, data) {
    const info = await api.addManager(data);
    commit(ADD_MANAGER, info)
  },
  async editManager({commit}, data) {
    const info = await api.editManager(data.fields, data.id);
    commit(EDIT_MANAGER, info)
  },
  async deleteManager({commit}, id) {
    await api.deleteManager(id);
    commit(DELETE_MANAGER, id)
  },
  resetState({commit}) {
    commit(RESET_STATE)
  },
};

/*----------------------------Getters----------------------------*/

const getters = {};

/*----------------------------Export----------------------------*/

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
  getters,
}