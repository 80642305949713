<script>
import apiFinance from "@/api/finance";
import {mapState} from "vuex";

export default {
	name: "WidgetDeptByModel",


	props: {
		currentMonth: {}
	},


	data() {
		return {
			itemsLoading: false,
			itemsLoaded: false,
			items: [],
			copyLoading: false,
			copyLoaded: false,
			copy: null,
			headCellClass: 'text-body-2 font-weight-medium pa-2 text-center',
			cellClass: 'text-body-2 pa-2 text-center',
			filteredModels: [],
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},


	created() {
		this.getDebtsByModel();
		this.getDebtsByModelCopy();
		if (this.commonListModelsLoaded) {
			this.filteringModelsWithoutIndex()
		}
	},


	watch: {
		commonListModelsLoaded(value) {
			if (value) {
				this.filteringModelsWithoutIndex()
			}
		}
	},


	methods: {
		filteringModelsWithoutIndex() {
			this.filteredModels = this.commonListModels.filter((item) => item.index_number)
		},
		checkMatch(model, debts) {
			const target = debts.find(debt => debt.model === model.nickname)
			if (target) {
				return target.value
			} else {
				return  '-'
			}
		},
		async getDebtsByModel() {
			try {
				this.itemsLoading = true;
				this.itemsLoaded = false;
				this.items = await apiFinance.getDebtsByModel();
				this.itemsLoading = false;
				this.itemsLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		async getDebtsByModelCopy() {
			try {
				this.copyLoading = true;
				this.copyLoaded = false;
				this.copy = await apiFinance.getDebtsByModelCopy();
				this.copyLoading = false;
				this.copyLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		copyTable() {
			window.navigator.clipboard.writeText(this.copy.copy_data)
		},
	}
}
</script>

<template>
	<v-sheet v-if="itemsLoaded" class="rounded-lg px-5 py-4 elevation-1 flex-column d-flex">
		<div class="mb-4 flex-0-0">
			<v-row>
				<v-col><div class="text-h6">Debt by model</div></v-col>
				<v-col cols="auto"><v-btn variant="flat" size="small" icon="mdi-content-copy" @click="copyTable"></v-btn></v-col>
			</v-row>
		</div>
		<div class="overflow-x-auto pb-2 flex-1-1">
			<div class="d-flex w-fit" style="min-width: 100%">

				<div class="flex-1-1" style="min-width: 160px">
					<div class="pa-2 text-body-1 font-weight-medium bg-white position-sticky" style="top: 0; z-index: 1">Model</div>
					<div class="table-column d-flex flex-column">
						<div
								v-for="(item, index) in filteredModels.sort((a, b) => a.index_number > b.index_number ? 1 : -1)"
								:key="index"
								class="pa-2 text-body-2"
						>
							{{ item.nickname }}
						</div>
					</div>
				</div>

				<div v-for="(item, index) in items" :key="index" style="min-width: 90px" class="flex-1-1">
					<div class="pa-2 text-body-1 font-weight-medium text-center bg-white position-sticky" style="top: 0; z-index: 1">{{ item.month }}</div>
					<div class="table-column d-flex flex-column">
						<div
								v-for="(model, modelIndex) in filteredModels.sort((a, b) => a.index_number > b.index_number ? 1 : -1)"
								:key="modelIndex"
								class="pa-2 text-body-2 text-center"
						>
							{{ checkMatch(model, item.debts) }}
						</div>
					</div>
				</div>

			</div>
		</div>
	</v-sheet>
	<v-card v-else class="pa-4 rounded-lg d-flex align-center justify-center" height="436">
		<v-progress-circular indeterminate color="primary"></v-progress-circular>
	</v-card>
</template>

<style scoped lang="less">
.table-column {

	& > div {

		&:nth-child(2n + 2) {
			background: #f5f5f5;
		}
	}
}
</style>