<template>
	<v-skeleton-loader
			v-if="!teamLeadsLoaded"
			type="chip@7"
			class="bg-transparent mb-6"
	></v-skeleton-loader>
	<v-chip-group
			v-else
			v-model="activeLead"
			item-value="id"
			variant="tonal"
			color="primary"
			class="mb-6 text-uppercase"
			mandatory
	>
		<v-chip value="0" @click="selectLead('0')">Все</v-chip>
		<template v-for="lead in teamLeads" :key="lead.id">
			<v-chip :value="lead.id" @click="selectLead(lead.id)">{{lead.full_name}}</v-chip>
		</template>
	</v-chip-group>
</template>

<script>
  import {mapState} from 'vuex';
  import { SET_ACTIVE_LEAD } from "@/store/modules/user/constants";

  export default {
    name: 'LeadsChipsGroup',


    data() {
      return {};
    },


    computed: {
      ...mapState({
        activeLead: state => state.user.activeLead,
        teamLeads: state => state.user.teamLeads,
        teamLeadsLoaded: state => state.user.teamLeadsLoaded,
      }),
    },


    methods: {
      selectLead(id) {
        this.$store.commit(`user/${SET_ACTIVE_LEAD}`, id);
      }
    },
  }
</script>

<style scoped>

</style>