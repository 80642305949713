<script>
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js'
import {Line} from "vue-chartjs";
import apiFinance from "@/api/finance";
import {useDisplay} from "vuetify";

ChartJS.register(
		CategoryScale,
		LinearScale,
		ArcElement,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
)

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},

	name: "WidgetDept",


	components: {
		Line
	},


	props: {
		currentMonth: {}
	},


	data() {
		return {
			altMode: false,
			format: 'dd.MM.yyyy',
			date: this.setDefaultDateRange(),
			chartOptionsLine: {},
			chartDataLine: {},
			dialogOpen: false,
			itemsLoading: false,
			itemsLoaded: false,
			items: [],
			total: null,
			copyLoading: false,
			copyLoaded: false,
			copy: null,
			history: [],
			historyLoading: false,
			historyLoaded: false,
		}
	},


	created() {
		this.getDebts();
		this.getDebtsCopy();
		this.getDebtsHistory();
		if (this.historyLoaded) this.setChartData()
	},


	watch: {
		historyLoaded() {
			this.setChartData()
		},
		itemsLoaded() {
			this.scrollDown()
		},
		copyLoaded() {
			this.scrollDown()
		},
		date() {
			this.getDebtsHistory();
		},
		altMode() {
			this.getDebts();
			this.getDebtsCopy();
		},
	},


	methods: {
		scrollDown() {
			if (this.itemsLoaded && this.copyLoaded) {
				this.$nextTick(function () {
					this.$refs.list.scrollTo(0, 10000);
				})
			}
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), -14);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		async getDebts() {
			try {
				const data = {};
				if (this.altMode) {
					data.statuses = 'problem'
				}
				this.itemsLoading = true;
				this.itemsLoaded = false;
				const response = await apiFinance.getDebts(data);
				this.items = response.debts;
				this.total = response.total;
				this.itemsLoading = false;
				this.itemsLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		async getDebtsCopy() {
			try {
				const data = {};
				if (this.altMode) {
					data.statuses = 'problem'
				}
				this.copyLoading = true;
				this.copyLoaded = false;
				this.copy = await apiFinance.getDebtsCopy(data);
				this.copyLoading = false;
				this.copyLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		copyTable() {
			window.navigator.clipboard.writeText(this.copy.copy_data)
		},
		async getDebtsHistory() {
			try {
				this.historyLoading = true;
				this.historyLoaded = false;
				const params = {
					date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
					date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
				}
				const response = await apiFinance.getDebtsHistory(params);
				this.history = response.results;
				this.historyLoading = false;
				this.historyLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		setChartData() {
			const labels = Array.from(new Set(this.history.map(elem => elem.date ))).reverse();
			const amountData = [];
			labels.forEach((dateLabel, index) => {
				const target = this.history.find(el => el.date === dateLabel);
				amountData[index] = target.debt_amount
			})

			this.chartOptionsLine = {
				plugins: { legend: { display: false } },
				scales: {
					x: {
						grid: { display: false },
						border: { display: false },
					},
					y: {
						grid: { display: false },
						border: { display: false },
					},
				},
				interaction: {
					intersect: false,
					axis: 'x'
				},
				responsive: true,
				maintainAspectRatio: false,
			};
			this.chartDataLine = {
				labels: labels,
				datasets: [
					{
						data: amountData,
						pointStyle: 'circle',
						backgroundColor: '#1976D2',
						borderColor: '#1976D2',
						pointRadius: 3,
						pointHoverRadius: 8
					},
				]
			}
		},
	}
}
</script>

<template>
	<v-sheet class="rounded-lg px-5 py-4 elevation-1 transition-4" :class="altMode ? 'bg-red-lighten-4' : ''" height="436">
		<div class="fill-height d-flex flex-column">
			<div class="flex-0-0">
				<v-row no-gutters justify="space-between" align="center" class="mr-n2">
					<v-col cols="auto">
						<div v-if="altMode" class="text-h6">Problem</div>
						<div v-else class="text-h6">Debt</div>
					</v-col>
					<v-col cols="auto" class="d-flex align-center">
						<v-switch v-model="altMode" class="mr-3" hide-details color="primary"></v-switch>
						<v-btn :disabled="altMode" variant="text" size="small" icon="mdi-chart-bell-curve-cumulative" @click="dialogOpen = true"></v-btn>
						<v-btn variant="text" size="small" icon="mdi-content-copy" @click="copyTable"></v-btn>
					</v-col>
				</v-row>
			</div>
			<template v-if="itemsLoaded && copyLoaded">
				<div class="flex-1-1 overflow-y-auto mx-n2 mt-4 px-2 pb-1" ref="list">
					<div class="mx-n2 mb-n1">
						<v-row
								class="px-2 py-1 rounded"
								:class="{'bg-deep-orange-lighten-2': currentMonth === item.month}"
								no-gutters v-for="(item, index) in items"
								:key="index"
						>
							<v-col class="text-body-2 text-left" :class="currentMonth === item.month ? 'text-white': 'text-grey-darken-2'">{{item.month}}</v-col>
							<v-col class="text-body-2 text-right" :class="currentMonth === item.month ? 'text-white': 'text-grey-darken-3'">{{item.amount}}$</v-col>
						</v-row>
					</div>
				</div>
				<v-row class="pt-3 flex-0-0">
					<v-col class="text-body-2 text-left font-weight-bold">Total:</v-col>
					<v-col class="text-body-2 text-right">{{ total.toLocaleString('ru') }}$</v-col>
				</v-row>
			</template>
			<v-card v-else class="pa-4 rounded-lg d-flex align-center justify-center bg-transparent elevation-0" height="436">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</v-card>
		</div>
	</v-sheet>
	<v-dialog v-model="dialogOpen" fullscreen transition="dialog-bottom-transition">
		<v-card :class="mdAndDown ? 'pa-3' : 'pa-12'">
			<v-row no-gutters class="fill-height flex-column" align="center" justify="center">
				<v-col class="flex-0-0">
					<div class="text-h4 mb-4 text-center">Debt history</div>
					<v-row justify="center">
						<v-col cols="auto">
							<VueDatePicker
									class="density-compact clearable-false"
									range
									v-model="date"
									auto-apply
									:format="format"
									:clearable="false"
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-col class="flex-1-1">
					<div v-if="historyLoaded" style="width: 100%; max-height: 400px; height: 100%">
						<Line :options="chartOptionsLine" :data="chartDataLine"/>
					</div>
					<v-sheet v-else class="d-flex align-center justify-center" height="400">
						<v-progress-circular indeterminate color="primary"></v-progress-circular>
					</v-sheet>
				</v-col>
				<v-col class="flex-0-0">
					<div class="d-flex justify-center mt-10">
						<v-btn color="primary" @click="dialogOpen = false" size="large">Close</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<style scoped>

</style>